import { doAction, doResponseAction } from ".";

const FORM_LIST = 'FORM_LIST';
const FORM_LIST_SUCCESS = 'FORM_LIST_SUCCESS';
const FORM_LIST_FAILURE = 'FORM_LIST_FAILURE';

const ISDRAFT = 'ISDRAFT';

const FORM_LOAN_DETAILS_LIST = 'FORM_LOAN_DETAILS_LIST';
const FORM_LOAN_DETAILS_LIST_SUCCESS = 'FORM_LOAN_DETAILS_LIST_SUCCESS';
const FORM_LOAN_DETAILS_LIST_FAILURE = 'FORM_LOAN_DETAILS_LIST_FAILURE';

const ORIGINAL_LOAN_LIST = 'ORIGINAL_LOAN_LIST';
const ORIGINAL_LOAN_LIST_SUCCESS = 'ORIGINAL_LOAN_LIST_SUCCESS';
const ORIGINAL_LOAN_LIST_FAILURE = 'ORIGINAL_LOAN_LIST_FAILURE';

const FORM_LENDER_DETAILS_LIST = 'FORM_LENDER_DETAILS_LIST';
const FORM_LENDER_DETAILS_LIST_SUCCESS = 'FORM_LENDER_DETAILS_LIST_SUCCESS';
const FORM_LENDER_DETAILS_LIST_FAILURE = 'FORM_LENDER_DETAILS_LIST_FAILURE';

const AGREEMENT_DETAILS = 'AGREEMENT_DETAILS';
const AGREEMENT_DETAILS_SUCCESS = 'AGREEMENT_DETAILS_SUCCESS';
const AGREEMENT_DETAILS_FAILURE = 'AGREEMENT_DETAILS_FAILURE';

const GET_AGREEMENT_DETAILS = 'GET_AGREEMENT_DETAILS';
const GET_AGREEMENT_DETAILS_SUCCESS = 'GET_AGREEMENT_DETAILS_SUCCESS';
const GET_AGREEMENT_DETAILS_FAILURE = 'GET_AGREEMENT_DETAILS_FAILURE';

const GET_LOAN_DETAILS = 'GET_LOAN_DETAILS';
const GET_LOAN_DETAILS_SUCCESS = 'GET_LOAN_DETAILS_SUCCESS';
const GET_LOAN_DETAILS_FAILURE = 'GET_LOAN_DETAILS_FAILURE';
const FORM_BORROWER_DETAILS_LIST = 'FORM_BORROWER_DETAILS_LIST';
const FORM_BORROWER_DETAILS_LIST_SUCCESS = 'FORM_BORROWER_DETAILS_LIST_SUCCESS';
const FORM_BORROWER_DETAILS_LIST_FAILURE = 'FORM_BORROWER_DETAILS_LIST_FAILURE';

const GET_LENDER_DETAILS_LIST = 'GET_LENDER_DETAILS_LIST';
const GET_LENDER_DETAILS_LIST_SUCCESS = 'GET_LENDER_DETAILS_LIST_SUCCESS';
const GET_LENDER_DETAILS_LIST_FAILURE = 'GET_LENDER_DETAILS_LIST_FAILURE';

const GET_BORROWER_DETAILS_LIST = 'GET_BORROWER_DETAILS_LIST';
const GET_BORROWER_DETAILS_LIST_SUCCESS = 'GET_BORROWER_DETAILS_LIST_SUCCESS';
const GET_BORROWER_DETAILS_LIST_FAILURE = 'GET_BORROWER_DETAILS_LIST_FAILURE';


const ECB_DETAILS_LIST = 'ECB_DETAILS_LIST';
const ECB_DETAILS_LIST_SUCCESS = 'ECB_DETAILS_LIST_SUCCESS';
const ECB_DETAILS_LIST_FAILURE = 'ECB_DETAILS_LIST_FAILURE';



const LOAN_DETAILS_DATA_SUCCESS = 'LOAN_DETAILS_DATA_SUCCESS';
const LENDER_DETAILS_DATA_SUCCESS = 'LENDER_DETAILS_DATA_SUCCESS';
const BORROWER_DETAILS_DATA_SUCCESS = 'BORROWER_DETAILS_DATA_SUCCESS';
const ECB_DETAILS_DATA_SUCCESS = 'ECB_DETAILS_DATA_SUCCESS';

export {
    FORM_LIST,
    FORM_LIST_FAILURE,
    FORM_LIST_SUCCESS,

    ISDRAFT,

    FORM_LOAN_DETAILS_LIST,
    FORM_LOAN_DETAILS_LIST_FAILURE,
    FORM_LOAN_DETAILS_LIST_SUCCESS,

    ORIGINAL_LOAN_LIST,
    ORIGINAL_LOAN_LIST_SUCCESS,
    ORIGINAL_LOAN_LIST_FAILURE,

    FORM_LENDER_DETAILS_LIST,
    FORM_LENDER_DETAILS_LIST_FAILURE,
    FORM_LENDER_DETAILS_LIST_SUCCESS,

    AGREEMENT_DETAILS,
    AGREEMENT_DETAILS_SUCCESS,
    AGREEMENT_DETAILS_FAILURE,

    GET_AGREEMENT_DETAILS,
    GET_AGREEMENT_DETAILS_SUCCESS,
    GET_AGREEMENT_DETAILS_FAILURE,

    GET_LOAN_DETAILS,
    GET_LOAN_DETAILS_SUCCESS,
    GET_LOAN_DETAILS_FAILURE,
    
    FORM_BORROWER_DETAILS_LIST,
    FORM_BORROWER_DETAILS_LIST_FAILURE,
    FORM_BORROWER_DETAILS_LIST_SUCCESS,

    GET_LENDER_DETAILS_LIST,
    GET_LENDER_DETAILS_LIST_FAILURE,
    GET_LENDER_DETAILS_LIST_SUCCESS,

    GET_BORROWER_DETAILS_LIST,
    GET_BORROWER_DETAILS_LIST_FAILURE,
    GET_BORROWER_DETAILS_LIST_SUCCESS,

    ECB_DETAILS_LIST,
    ECB_DETAILS_LIST_FAILURE,
    ECB_DETAILS_LIST_SUCCESS,

    LOAN_DETAILS_DATA_SUCCESS,
    LENDER_DETAILS_DATA_SUCCESS,
    BORROWER_DETAILS_DATA_SUCCESS,
    ECB_DETAILS_DATA_SUCCESS,
}

// LOGIN
export const FormList = (params, callback) => doAction(FORM_LIST, params, callback);
export const FormListSuccess = (data) => doResponseAction(FORM_LIST_SUCCESS, data);
export const FormListFailure = (data) => doResponseAction(FORM_LIST_FAILURE, data);

export const isDraft = (data) => doResponseAction(ISDRAFT, data);

export const FormLoanDetailsList = (params, callback) => doAction(FORM_LOAN_DETAILS_LIST, params, callback);
export const FormLoanDetailsListSuccess = (data) => doResponseAction(FORM_LOAN_DETAILS_LIST_SUCCESS, data);
export const FormLoanDetailsListFailure = (data) => doResponseAction(FORM_LOAN_DETAILS_LIST_FAILURE, data);

export const OriginalFormList = (params, callback) => doAction(ORIGINAL_LOAN_LIST, params, callback);
export const OriginalFormListSuccess = (data) => doResponseAction(ORIGINAL_LOAN_LIST_SUCCESS, data);
export const OriginalFormListFailure = (data) => doResponseAction(ORIGINAL_LOAN_LIST_FAILURE, data);

export const FormLenderDetailsList = (params, callback) => doAction(FORM_LENDER_DETAILS_LIST, params, callback);
export const FormLenderDetailsListSuccess = (data) => doResponseAction(FORM_LENDER_DETAILS_LIST_FAILURE, data);
export const FormLenderDetailsListFailure = (data) => doResponseAction(FORM_LENDER_DETAILS_LIST_SUCCESS, data);

export const AgreementDetails = (params, callback) => doAction(AGREEMENT_DETAILS, params, callback);
export const AgreementDetailsSuccess = (data) => doResponseAction(AGREEMENT_DETAILS_SUCCESS, data);
export const AgreementDetailsFailure = (data) => doResponseAction(AGREEMENT_DETAILS_FAILURE, data);

export const getAgreementDetails = (params, callback) => doAction(GET_AGREEMENT_DETAILS, params, callback);
export const getAgreementDetailsSuccess = (data) => doResponseAction(GET_AGREEMENT_DETAILS_SUCCESS, data);
export const getAgreementDetailsFailure = (data) => doResponseAction(GET_AGREEMENT_DETAILS_FAILURE, data);

export const GetLoanDetails = (params, callback) => doAction(GET_LOAN_DETAILS, params, callback);
export const GetLoanDetailsSuccess = (data) => doResponseAction(GET_LOAN_DETAILS_FAILURE, data);
export const GetLoanDetailsFailure = (data) => doResponseAction(GET_LOAN_DETAILS_SUCCESS, data);
export const FormBorrowerDetailsList = (params, callback) => doAction(FORM_BORROWER_DETAILS_LIST, params, callback);
export const FormBorrowerDetailsListSuccess = (data) => doResponseAction(FORM_BORROWER_DETAILS_LIST_FAILURE, data);
export const FormBorrowerDetailsListFailure = (data) => doResponseAction(FORM_BORROWER_DETAILS_LIST_SUCCESS, data);

export const getLenderDetailsList = (params, callback) => doAction(GET_LENDER_DETAILS_LIST, params, callback);
export const getLenderDetailsListSuccess = (data) => doResponseAction(GET_LENDER_DETAILS_LIST_FAILURE, data);
export const getLenderDetailsListFailure = (data) => doResponseAction(GET_LENDER_DETAILS_LIST_SUCCESS, data);

export const getBorrowerDetailsList = (params, callback) => doAction(GET_BORROWER_DETAILS_LIST, params, callback);
export const getBorrowerDetailsListSuccess = (data) => doResponseAction(GET_BORROWER_DETAILS_LIST_FAILURE, data);
export const getBorrowerDetailsListFailure = (data) => doResponseAction(GET_BORROWER_DETAILS_LIST_SUCCESS, data);

export const ecbDetailsList = (params, callback) => doAction(ECB_DETAILS_LIST, params, callback);
export const ecbDetailsListSuccess = (data) => doResponseAction(ECB_DETAILS_LIST_FAILURE, data);
export const ecbDetailsListFailure = (data) => doResponseAction(ECB_DETAILS_LIST_SUCCESS, data);


export const loanDetailsData = (data) => doResponseAction(LOAN_DETAILS_DATA_SUCCESS, data);
export const lenderDetailsData = (data) => doResponseAction(LENDER_DETAILS_DATA_SUCCESS, data);
export const borrowerDetailsData = (data) => doResponseAction(BORROWER_DETAILS_DATA_SUCCESS, data);
export const ecbDetailsData = (data) => doResponseAction(ECB_DETAILS_DATA_SUCCESS, data);
