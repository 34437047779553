import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_DRAWDOWN, UPDATE_DRAWDOWN,DELETE_DRAWDOWN } from '../action/drawDown';

export function* createDrawdown(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.CREATE_DRAWDOWN, params)
        yield put(Actions.createDrawdownSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.createDrawdownFailure(error))
    }
}
export function* updateDrawdown(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.UPDATE_DRAWDOWN, params)
        yield put(Actions.updateDrawdownSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.updateDrawdownFailure(error))
    }
}
export function* deleteDrawdown(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.DELETE_DRAWDOWN, params)
        yield put(Actions.deleteDrawdownSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.deleteDrawdownFailure(error))
    }
}

export default function* drawdownWatcher() {
    yield all([
        takeLatest(CREATE_DRAWDOWN, createDrawdown),
        takeLatest(UPDATE_DRAWDOWN, updateDrawdown),
        takeLatest(DELETE_DRAWDOWN, deleteDrawdown),
    ]);
}
