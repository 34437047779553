import { doAction, doResponseAction } from ".";


const GET_GUARANTEE_STATUS_CODE = 'GET_GUARANTEE_STATUS_CODE';
const GET_GUARANTEE_STATUS_CODE_SUCCESS = 'GET_GUARANTEE_STATUS_CODE_SUCCESS';
const GET_GUARANTEE_STATUS_CODE_FAILURE = 'GET_GUARANTEE_STATUS_CODE_FAILURE';

const GET_BORROWING_PURPOSE_CODE = 'GET_BORROWING_PURPOSE_CODE';
const GET_BORROWING_PURPOSE_CODE_SUCCESS = 'GET_BORROWING_PURPOSE_CODE_SUCCESS';
const GET_BORROWING_PURPOSE_CODE_FAILURE = 'GET_BORROWING_PURPOSE_CODE_FAILURE';

export {

    GET_GUARANTEE_STATUS_CODE,
    GET_GUARANTEE_STATUS_CODE_SUCCESS,
    GET_GUARANTEE_STATUS_CODE_FAILURE,


    GET_BORROWING_PURPOSE_CODE,
    GET_BORROWING_PURPOSE_CODE_SUCCESS,
    GET_BORROWING_PURPOSE_CODE_FAILURE,
}


export const getGuaranteeStatusCode = (params, callback) => doAction(GET_GUARANTEE_STATUS_CODE, params, callback);
export const getGuaranteeStatusCodeSuccess = (data) => doResponseAction(GET_GUARANTEE_STATUS_CODE_SUCCESS, data);
export const getGuaranteeStatusCodeFailure = (data) => doResponseAction(GET_GUARANTEE_STATUS_CODE_FAILURE, data);

export const getBorrowingPurposeCode = (params, callback) => doAction(GET_BORROWING_PURPOSE_CODE, params, callback);
export const getBorrowingPurposeCodeSuccess = (data) => doResponseAction(GET_BORROWING_PURPOSE_CODE_SUCCESS, data);
export const getBorrowingPurposeCodeFailure = (data) => doResponseAction(GET_BORROWING_PURPOSE_CODE_FAILURE, data);