import { Actions } from '../action';

const initialState = {
    isLoading: false,
    borrowingPurpose: [],
    error: false,
}
export const borrowingPurposeReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CREATE_BORROWING_PURPOSE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_BORROWING_PURPOSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                borrowingPurpose: action?.data,
                error: false
            };
        }
        case Actions.CREATE_BORROWING_PURPOSE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_BORROWING_PURPOSE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_BORROWING_PURPOSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                borrowingPurpose: action?.data,
                error: false
            };
        }
        case Actions.UPDATE_BORROWING_PURPOSE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DELETE_BORROWING_PURPOSE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_BORROWING_PURPOSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                borrowingPurpose: action?.data,
                error: false
            };
        }
        case Actions.DELETE_BORROWING_PURPOSE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};



export const borrowingPurpose = (state) => state?.BORROWINGPURPOSE?.borrowingPurpose;

