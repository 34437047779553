import React, { useEffect, useState } from 'react';
import { OtherchargesAndEcbDetailsValidateField } from '../utils/Validation';
import { getFormLoanDetailslist, getagreementDetails, ecbDetailsDatas, getAgreementDetailsList } from '../redux/reducer/FormListReducers';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from './HOC/Loader';

const OtherChargesAndEcbDetails = ({ isBack, onNext, setBack, onBack }) => {
    const getAgreementDetails = useSelector(getagreementDetails)
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const loanList = useSelector(getFormLoanDetailslist)
    const ecbDetailsData = useSelector(ecbDetailsDatas)
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const [isloading, setLoading] = useState(false)

    const [ecbDetails, setEcbDetails] = useState({
        natureOfCharge: '',
        expectedDateOfPayment: '',
        currency: '',
        amount: '',
        numberOfPaymentsPerYear: '',
        totalNumberOfPayments: '',
        unfrontFee: '',
        managementFee: '',
        commitmentFee: '',
        guaranteeFee: '',
        ecbChargesFee: '',
        otherFee: '',
        totalFee: '',
        penalIntLatePaymentFixed: '',
        penalIntLatePaymentBase: '',
        penalIntLatePaymentMargin: '',
        commitmentChargesPerAnnum: '',
        commitmentChargesPerUndrawnAmount: '',
        // ecbYearDetails: '',
        // ecbLoanRegNo: '',
        // ecbCurrencyDetails: '',
        // ecbPrincipalAmount: '',
        // ecbDisbursedSoFar: '',
        // ecbNetOutstanding: '',
        place: '',
        date: '',
        signature: '',
        name: '',
        designation: '',
        phoneOrFaxNumber: '',
        emailId: '',
    });

    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        natureOfCharge: false,
        expectedDateOfPayment: false,
        currency: false,
        amount: false,
        numberOfPaymentsPerYear: false,
        totalNumberOfPayments: false,
        unfrontFee: false,
        managementFee: false,
        commitmentFee: false,
        guaranteeFee: false,
        ecbChargesFee: false,
        otherFee: false,
        totalFee: false,
        penalIntLatePaymentFixed: false,
        penalIntLatePaymentBase: false,
        penalIntLatePaymentMargin: false,
        commitmentChargesPerAnnum: false,
        commitmentChargesPerUndrawnAmount: false,

        // ecbYearDetails: false,
        // ecbLoanRegNo: false,
        // ecbCurrencyDetails: false,
        // ecbPrincipalAmount: false,
        // ecbDisbursedSoFar: false,
        // ecbNetOutstanding: false,
        place: false,
        date: false,
        signature: false,
        name: false,
        designation: false,
        phoneOrFaxNumber: false,
        emailId: false,

    });
    const handleFieldChange = (field, value) => {
        setEcbDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };

    // useEffect(() => {
    //     if (ecbDetailsData) {
    //         setEcbDetails(ecbDetailsData)
    //     }
    // }, [dispatch, ecbDetailsData])
    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        const callback = (res) => {
            setLoading(false)
            if (res?.response_code === 0) {
                setEcbDetails(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        };
        if ((getAgreementDetails?.ecbApplicationType?.toString() === "2" || agreementDetailsList?.loanId) || (isBack && (Array.isArray(ecbDetailsData) ? false : true))) {
            const params = {
                loanId: isBack ? loanList?.id : getAgreementDetails?.loanId ?? agreementDetailsList?.loanId,
                isDraft: isDraft
            };
            setLoading(true)
            dispatch(Actions.GetOther(params, callback))
        }
    }, [dispatch, getAgreementDetails?.ecbApplicationType, getAgreementDetails?.loanId, isBack])
    const handleOriginalNext = () => {
        onNext("ecbDetails")
    }
    const handleOriginalBack = () => {
        onNext('lenderDetails')
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;

        for (const field in ecbDetails) {
            if (ecbDetails[field] === '' || ecbDetails[field] === null) {
                if (field === 'createdDate' || field === 'id' || field === 'isActive' || field === 'loanId' || field === 'registrationNumber' || field === 'updatedDate') {
                    newErrors[field] = false;
                    continue;
                }
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            setLoading(true)
            const callback = (res) => {
                setLoading(false)
                if (res?.response_code === 0) {
                    onNext('ecbDetails')
                } else if (res?.error) {
                    const { data } = res.error?.response || {}
                    toast.error(`${data?.response_message}`)
                } else {
                    toast.error("Sorry somethiing went wrong")
                }

            };
            let params = {
                ...ecbDetails,
                loanId: loanList?.id,
                isDraft: isDraft
            }
            dispatch(Actions.ecbDetailsData(params));
            dispatch(Actions.ecbDetailsList(params, callback));

            // Perform form submission logic here
        }
    };

    const Back = () => {
        setBack(true)
        onBack("borrowerDetails")
    }
    return (
        <React.Fragment>
            <div className="py-3">
                <div className="row mt-4">
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Nature of charge</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Nature of Charge"
                                    value={ecbDetails.natureOfCharge}
                                    onChange={(e) => handleFieldChange('natureOfCharge', e.target.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus.natureOfCharge && OtherchargesAndEcbDetailsValidateField('natureOfCharge', ecbDetails.natureOfCharge) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('natureOfCharge', ecbDetails.natureOfCharge)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Expected Date of Payment:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Select Date"
                                    value={moment(ecbDetails?.expectedDateOfPayment).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('expectedDateOfPayment', e.target.value)}
                                />
                                {fieldChangedStatus.expectedDateOfPayment && OtherchargesAndEcbDetailsValidateField('expectedDateOfPayment', ecbDetails.expectedDateOfPayment) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('expectedDateOfPayment', ecbDetails.expectedDateOfPayment)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Currency:</label>
                            <div className="col-md-8">
                                <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} value={ecbDetails.currency}
                                    onChange={(e) => handleFieldChange('currency', e.target.value)}
                                >
                                    <option hidden>Select Currency</option>
                                    <option value="AUD">AUD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="INR">INR</option>
                                    <option value="LKR">LKR</option>
                                    <option value="MAD">MAD</option>
                                    <option value="MXN">MXN</option>
                                    <option value="SGD">SGD</option>
                                    <option value="USD">USD</option>
                                </select>
                                {fieldChangedStatus.currency && OtherchargesAndEcbDetailsValidateField('currency', ecbDetails.currency) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('currency', ecbDetails.currency)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Amount:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Amount"
                                    value={ecbDetails.amount}
                                    onChange={(e) => handleFieldChange('amount', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.amount && OtherchargesAndEcbDetailsValidateField('amount', ecbDetails.amount) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('amount', ecbDetails.amount)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="mb-0">In case of multiple equal payments</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">No. of payments in a Year: <span className="note_info">(In case of multiple equal payments) </span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter No.of year"
                                    value={ecbDetails.numberOfPaymentsPerYear}
                                    onChange={(e) => handleFieldChange('numberOfPaymentsPerYear', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.numberOfPaymentsPerYear && OtherchargesAndEcbDetailsValidateField('numberOfPaymentsPerYear', ecbDetails.numberOfPaymentsPerYear) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('numberOfPaymentsPerYear', ecbDetails.numberOfPaymentsPerYear)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Total no. of payments:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter No.of Payment"
                                    value={ecbDetails.totalNumberOfPayments}
                                    onChange={(e) => handleFieldChange('totalNumberOfPayments', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.totalNumberOfPayments && OtherchargesAndEcbDetailsValidateField('totalNumberOfPayments', ecbDetails.totalNumberOfPayments) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('totalNumberOfPayments', ecbDetails.totalNumberOfPayments)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Upfront fee:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Upfront fee"
                                    value={ecbDetails.unfrontFee}
                                    onChange={(e) => handleFieldChange('unfrontFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.unfrontFee && OtherchargesAndEcbDetailsValidateField('unfrontFee', ecbDetails.unfrontFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('unfrontFee', ecbDetails.unfrontFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Management fee:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Management Fee"
                                    value={ecbDetails.managementFee}
                                    onChange={(e) => handleFieldChange('managementFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.managementFee && OtherchargesAndEcbDetailsValidateField('managementFee', ecbDetails.managementFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('managementFee', ecbDetails.managementFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Commitment Fees: </label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Commitment Fee"
                                    value={ecbDetails.commitmentFee}
                                    onChange={(e) => handleFieldChange('commitmentFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.commitmentFee && OtherchargesAndEcbDetailsValidateField('commitmentFee', ecbDetails.commitmentFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('commitmentFee', ecbDetails.commitmentFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Guarantee fees:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Guarantee fee"
                                    value={ecbDetails.guaranteeFee}
                                    onChange={(e) => handleFieldChange('guaranteeFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.guaranteeFee && OtherchargesAndEcbDetailsValidateField('guaranteeFee', ecbDetails.guaranteeFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('guaranteeFee', ecbDetails.guaranteeFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">ECA charges:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter ECA charges"
                                    value={ecbDetails.ecbChargesFee}
                                    onChange={(e) => handleFieldChange('ecbChargesFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.ecbChargesFee && OtherchargesAndEcbDetailsValidateField('ecbChargesFee', ecbDetails.ecbChargesFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbChargesFee', ecbDetails.ecbChargesFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Others:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.otherFee}
                                    onChange={(e) => handleFieldChange('otherFee', e.target.value)}
                                />
                                {/* {fieldChangedStatus.otherFee && OtherchargesAndEcbDetailsValidateField('otherFee', ecbDetails.otherFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('otherFee', ecbDetails.otherFee)}</span>
                                )} */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Total:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.totalFee}
                                    onChange={(e) => handleFieldChange('totalFee', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                    }}
                                />
                                {fieldChangedStatus.totalFee && OtherchargesAndEcbDetailsValidateField('totalFee', ecbDetails.totalFee) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('totalFee', ecbDetails.totalFee)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <h5 className="mt-3 mb-0">Penal Interest for late payments</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Fixed:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.penalIntLatePaymentFixed}
                                    onChange={(e) => handleFieldChange('penalIntLatePaymentFixed', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.penalIntLatePaymentFixed && OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentFixed', ecbDetails.penalIntLatePaymentFixed) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentFixed', ecbDetails.penalIntLatePaymentFixed)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Base:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.penalIntLatePaymentBase}
                                    onChange={(e) => handleFieldChange('penalIntLatePaymentBase', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.penalIntLatePaymentBase && OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentBase', ecbDetails.penalIntLatePaymentBase) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentBase', ecbDetails.penalIntLatePaymentBase)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Margin:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.penalIntLatePaymentMargin}
                                    onChange={(e) => handleFieldChange('penalIntLatePaymentMargin', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.penalIntLatePaymentMargin && OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentMargin', ecbDetails.penalIntLatePaymentMargin) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('penalIntLatePaymentMargin', ecbDetails.penalIntLatePaymentMargin)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <h5 className="mt-3 mb-0">Commitment Charges</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">% per annum of:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.commitmentChargesPerAnnum}
                                    onChange={(e) => handleFieldChange('commitmentChargesPerAnnum', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.commitmentChargesPerAnnum && OtherchargesAndEcbDetailsValidateField('commitmentChargesPerAnnum', ecbDetails.commitmentChargesPerAnnum) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('commitmentChargesPerAnnum', ecbDetails.commitmentChargesPerAnnum)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">% of Undrawn Amount:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={ecbDetails.commitmentChargesPerUndrawnAmount}
                                    onChange={(e) => handleFieldChange('commitmentChargesPerUndrawnAmount', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.commitmentChargesPerUndrawnAmount && OtherchargesAndEcbDetailsValidateField('commitmentChargesPerUndrawnAmount', ecbDetails.commitmentChargesPerUndrawnAmount) && (
                                    <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('commitmentChargesPerUndrawnAmount', ecbDetails.commitmentChargesPerUndrawnAmount)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="row mt-4">
                        <div className="d-flex justify-content-between align-items-center pt-3 pb-2">
                            <h5 className="mb-0"> Details of ECB already availed</h5>
                        </div>
                        {/* <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Year:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbYearDetails}
                                        onChange={(e) => handleFieldChange('ecbYearDetails', e.target.value)}
                                    />
                                    {fieldChangedStatus.ecbYearDetails && OtherchargesAndEcbDetailsValidateField('ecbYearDetails', ecbDetails.ecbYearDetails) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbYearDetails', ecbDetails.ecbYearDetails)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Loan Reg.No:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbLoanRegNo}
                                        onChange={(e) => handleFieldChange('ecbLoanRegNo', e.target.value)}
                                    // onInput={(e) => {
                                    //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    // }}
                                    />
                                    {fieldChangedStatus.ecbLoanRegNo && OtherchargesAndEcbDetailsValidateField('ecbLoanRegNo', ecbDetails.ecbLoanRegNo) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbLoanRegNo', ecbDetails.ecbLoanRegNo)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Currency:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbCurrencyDetails}
                                        onChange={(e) => handleFieldChange('ecbCurrencyDetails', e.target.value)}
                                    />
                                    {fieldChangedStatus.ecbCurrencyDetails && OtherchargesAndEcbDetailsValidateField('ecbCurrencyDetails', ecbDetails.ecbCurrencyDetails) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbCurrencyDetails', ecbDetails.ecbCurrencyDetails)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <h5 className="mb-0">Amount of Loan</h5>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Principal: <span className="note_info">(as per agreement) Amount of Loan</span></label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbPrincipalAmount}
                                        onChange={(e) => handleFieldChange('ecbPrincipalAmount', e.target.value)}
                                    />
                                    {fieldChangedStatus.ecbPrincipalAmount && OtherchargesAndEcbDetailsValidateField('ecbPrincipalAmount', ecbDetails.ecbPrincipalAmount) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbPrincipalAmount', ecbDetails.ecbPrincipalAmount)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Disbursed so far:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbDisbursedSoFar}
                                        onChange={(e) => handleFieldChange('ecbDisbursedSoFar', e.target.value)}
                                    />
                                    {fieldChangedStatus.ecbDisbursedSoFar && OtherchargesAndEcbDetailsValidateField('ecbDisbursedSoFar', ecbDetails.ecbDisbursedSoFar) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbDisbursedSoFar', ecbDetails.ecbDisbursedSoFar)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Net outstanding <span className="note_info">(Principal)</span>:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                        placeholder="Type here"
                                        value={ecbDetails.ecbNetOutstanding}
                                        onChange={(e) => handleFieldChange('ecbNetOutstanding', e.target.value)}
                                    />
                                    {fieldChangedStatus.ecbNetOutstanding && OtherchargesAndEcbDetailsValidateField('ecbNetOutstanding', ecbDetails.ecbNetOutstanding) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('ecbNetOutstanding', ecbDetails.ecbNetOutstanding)}</span>
                                    )}
                                </div> */}
                    </div>
                    <div className="row mt-4">
                        <table>
                            <thead>
                                <tr>
                                    <th className="col-1">Year</th>
                                    <th className="col-2">Loan Reg.No. (LRN)</th>
                                    <th className="col-1">Currency</th>
                                    <th className="col-2" colspan="4">Amount of Loan</th>
                                    {/* <th></th>
                                    <th></th> */}
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Principal (as per agreement)</th>
                                    <th>Disbursed so far</th>
                                    <th>Net outstanding (Principal)</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td colspan="7">
                                        No data found
                                    </td>
                                </tr>
                                {/* {drawdowns?.map((drawdown, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {drawdown.date}
                                        </td>
                                        <td>
                                            {drawdown.currency}
                                        </td>
                                        <td>
                                            {drawdown.amount}
                                        </td>
                                        <td>
                                            {drawdown.totalNoOfDrawals}
                                        </td>
                                        <td>
                                            {drawdown.totalNoOfDrawalsInCalendarYear}
                                        </td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => handleEditRow(index, drawdown)}>
                                                edit
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => handleDelete(index)}>
                                                delete
                                            </button>
                                        </td>
                                    </tr> 
                                ))}*/}
                            </tbody>
                        </table>
                    </div>


                </div>
                <p className="note_info my-3">We hereby certify that the particulars given above are true and correct to the best of our knowledge and
                    belief and nomaterial information has been withheld and/ormisrepresented. Furthermore,the ECB is in
                    compliance with the extant ECB guidelines and the ECB to be raisedwill be utilised for permitted purposes.</p>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Place:</label>
                        <div className="col-md-8">
                            <input
                                type="text"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.place}
                                onChange={(e) => handleFieldChange('place', e.target.value)}
                                maxLength={50}
                            />
                            {fieldChangedStatus.place && OtherchargesAndEcbDetailsValidateField('place', ecbDetails.place) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('place', ecbDetails.place)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Date:</label>
                        <div className="col-md-8">
                            <input
                                type="date"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Select Date"
                                value={moment(ecbDetails?.date).format('YYYY-MM-DD')}
                                onChange={(e) => handleFieldChange('date', e.target.value)}
                            />
                            {fieldChangedStatus.date && OtherchargesAndEcbDetailsValidateField('date', ecbDetails.date) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('date', ecbDetails.date)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">(Signature of the Authorised Official of the Company with stamp):</label>
                        <div className="col-md-8">
                            <input
                                type="text"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.signature}
                                onChange={(e) => handleFieldChange('signature', e.target.value)}
                            />
                            {/* {fieldChangedStatus.signature && OtherchargesAndEcbDetailsValidateField('signature', ecbDetails.signature) && (
                                        <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('signature', ecbDetails.signature)}</span>
                                    )} */}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Name:</label>
                        <div className="col-md-8">
                            <input
                                type="text"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.name}
                                onChange={(e) => handleFieldChange('name', e.target.value)}
                                maxLength={50}
                            />
                            {fieldChangedStatus.name && OtherchargesAndEcbDetailsValidateField('name', ecbDetails.name) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('name', ecbDetails.name)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Designation:</label>
                        <div className="col-md-8">
                            <input
                                type="text"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.designation}
                                onChange={(e) => handleFieldChange('designation', e.target.value)}
                                maxLength={50}
                            />
                            {fieldChangedStatus.designation && OtherchargesAndEcbDetailsValidateField('designation', ecbDetails.designation) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('designation', ecbDetails.designation)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Phone No.Fax:</label>
                        <div className="col-md-8">
                            <input
                                type="number"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.phoneOrFaxNumber}
                                onChange={(e) => handleFieldChange('phoneOrFaxNumber', e.target.value)}
                                onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }}
                            />
                            {fieldChangedStatus.phoneOrFaxNumber && OtherchargesAndEcbDetailsValidateField('phoneOrFaxNumber', ecbDetails.phoneOrFaxNumber) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('phoneOrFaxNumber', ecbDetails.phoneOrFaxNumber)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-3">
                    <div className="row">
                        <label className="col-md-4 form-label label_head">Email:</label>
                        <div className="col-md-8">
                            <input
                                type="email"
                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                disabled={isDisabled}
                                placeholder="Type here"
                                value={ecbDetails.emailId}
                                onChange={(e) => handleFieldChange('emailId', e.target.value)}
                                maxLength={50}
                            />
                            {fieldChangedStatus.emailId && OtherchargesAndEcbDetailsValidateField('emailId', ecbDetails.emailId) && (
                                <span className="text-danger">{OtherchargesAndEcbDetailsValidateField('emailId', ecbDetails.emailId)}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="">
                    <button className="btn back_btn" onClick={() => Back()}>Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleSubmit} className="btn next_btn">Submit</button>
                </div>
            </div>}
            {isDraft == false && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="">
                    <button className="btn back_btn" onClick={handleOriginalBack} >Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleOriginalNext} className="btn next_btn">Next</button>
                </div>
            </div>}
            {
                isloading && <Loader />
            }
        </React.Fragment >
    );
}

export default OtherChargesAndEcbDetails;
