import { doAction, doResponseAction } from ".";

const FORM_SUMMARY_DETAILS_LIST = 'FORM_SUMMARY_DETAILS_LIST';
const FORM_SUMMARY_DETAILS_LIST_SUCCESS = 'FORM_SUMMARY_DETAILS_LIST_SUCCESS';
const FORM_SUMMARY_DETAILS_LIST_FAILURE = 'FORM_SUMMARY_DETAILS_LIST_FAILURE';

const GET_SUMMARY_DETAILS_LIST = 'GET_SUMMARY_DETAILS_LIST';
const GET_SUMMARY_DETAILS_LIST_SUCCESS = 'GET_SUMMARY_DETAILS_LIST_SUCCESS';
const GET_SUMMARY_DETAILS_LIST_FAILURE = 'GET_SUMMARY_DETAILS_LIST_FAILURE';

const GET_PDF_LIST = 'GET_PDF_LIST';
const GET_PDF_LIST_SUCCESS = 'GET_PDF_LIST_SUCCESS';
const GET_PDF_LIST_FAILURE = 'GET_PDF_LIST_FAILURE';

export {
    FORM_SUMMARY_DETAILS_LIST,
    FORM_SUMMARY_DETAILS_LIST_SUCCESS,
    FORM_SUMMARY_DETAILS_LIST_FAILURE,

    GET_SUMMARY_DETAILS_LIST,
    GET_SUMMARY_DETAILS_LIST_SUCCESS,
    GET_SUMMARY_DETAILS_LIST_FAILURE,

    
    GET_PDF_LIST,
    GET_PDF_LIST_SUCCESS,
    GET_PDF_LIST_FAILURE,
}

export const FormSummayDetailsList = (params, callback) => doAction(FORM_SUMMARY_DETAILS_LIST, params, callback);
export const FormSummaryDetailsListSuccess = (data) => doResponseAction(FORM_SUMMARY_DETAILS_LIST_SUCCESS, data);
export const FormSummaryDetailsListFailure = (data) => doResponseAction(FORM_SUMMARY_DETAILS_LIST_FAILURE, data);

export const getSummayDetailsList = (params, callback) => doAction(GET_SUMMARY_DETAILS_LIST, params, callback);
export const getSummaryDetailsListSuccess = (data) => doResponseAction(GET_SUMMARY_DETAILS_LIST_SUCCESS, data);
export const getSummaryDetailsListFailure = (data) => doResponseAction(GET_SUMMARY_DETAILS_LIST_FAILURE, data);

export const getPdfList = (params, callback) => doAction(GET_PDF_LIST, params, callback);
export const getPdfListSuccess = (data) => doResponseAction(GET_PDF_LIST_SUCCESS, data);
export const getPdfListFailure = (data) => doResponseAction(GET_PDF_LIST_FAILURE, data);