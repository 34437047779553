import React, { useState } from "react"

const Form2 = () => {
    const [isTab, setTab] = useState(1);

    const changeSideBar = () => {

        let sideBar = document.getElementsByClassName('sidebar_align')
        sideBar[0].classList.add("sidebar_align_show")
    }

    return (
        <React.Fragment>
            <div className="form_content py-3 px-md-4 px-2">
                <div className="d-flex justify-content-center align-items-start">
                    <div onClick={() => changeSideBar()}><i className="bi bi-list menu_icon fa-2xl"></i></div>
                    <h3 className="heading">KDM & CO LLP Chartered Accountant</h3>
                </div>
                <div className="d-flex align-items-center justify-content-end py-4">
                    <p className="m-0">To view the instruction of the policy: </p>
                    <button className="common_btn">View</button>
                </div>
                <div className="row tab_content">
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                        <p className={`bg_align ${isTab === 1 ? "active" : ""}`}>1</p>
                        <p className={`ms-2 ${isTab === 1 ? "activetext" : ""}`}>Loan Identification Particulars</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 2 ? "active" : ""}`} onClick={() => setTab(2)}>
                        <p className={`bg_align ${isTab === 2 ? "active" : ""}`}>2</p>
                        <p className={`ms-2 ${isTab === 2 ? "activetext" : ""}`}>Disbursement</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 3 ? "active" : ""}`} onClick={() => setTab(3)}>
                        <p className={`bg_align ${isTab === 3 ? "active" : ""}`}>3</p>
                        <p className={`ms-2 ${isTab === 3 ? "activetext" : ""}`}>Utilisation</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 4 ? "active" : ""}`} onClick={() => setTab(4)}>
                        <p className={`bg_align ${isTab === 4 ? "active" : ""}`}>4</p>
                        <p className={`ms-2 ${isTab === 4 ? "activetext" : ""}`}>Debt Servicing</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 5 ? "active" : ""}`} onClick={() => setTab(5)}>
                        <p className={`bg_align ${isTab === 5 ? "active" : ""}`}>5</p>
                        <p className={`ms-2 ${isTab === 5 ? "activetext" : ""}`}>Others</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 6 ? "active" : ""}`} onClick={() => setTab(6)}>
                        <p className={`bg_align ${isTab === 6 ? "active" : ""}`}>6</p>
                        <p className={`ms-2 ${isTab === 6 ? "activetext" : ""}`}>Outstanding Principal Amount</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 7 ? "active" : ""}`} onClick={() => setTab(7)}>
                        <p className={`bg_align ${isTab === 7 ? "active" : ""}`}>7</p>
                        <p className={`ms-2 ${isTab === 7 ? "activetext" : ""}`}>Summary Sheet (SS 2) </p>
                    </div>
                </div>

                {isTab === 1 &&
                    <React.Fragment>
                        <div className="py-3">
                            <div className="row mt-2">
                                <div className="col-md-4">
                                    <p className="label_head">Loan Registration Number (LRN):</p>
                                </div>
                                <div className="col-md-4">
                                    <p className="lrn_number">201804120</p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <h5>Loan Amount</h5>
                                <h6 className="mt-1">As per Agreement</h6>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">USD</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">20,000,000</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Revised: <span className="note_info">(please indicate if period of
                                                disbursement elapsed/ cancelled/
                                                not to be drawn in future)</span></label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <h5>Borrower Particulars</h5>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Name of the Borrower:</label>
                                            <div className="col-md-7">
                                                <p className="">BYD INDIA PRIVATE LIMITED</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Address of the Borrower:</label>
                                            <div className="col-md-7">
                                                <p className="">Plot. OZ 7, SIPCOT, Oragadam Sripermbudur, Tamil Nadu, India Pin code – 602 105</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Contact Person's Name:</label>
                                            <div className="col-md-7">
                                                <p className="">Wu Xiaohong</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Designation:</label>
                                            <div className="col-md-7">
                                                <p className="">Finance Executive</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Phone No:</label>
                                            <div className="col-md-7">
                                                <p className="">9940315256</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">E‐mail ID:</label>
                                            <div className="col-md-7">
                                                <p className="">wu.xiaohong4@byd.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    isTab === 2 &&
                    <React.Fragment>
                        <div className="py-3">
                            <h5 className="mt-4">B.1: Draw‐down (Disbursement) during the month (in loan currency)</h5>
                            <h6 className="mt-4">A. Amount Parked Abroad (Particulars)</h6>
                            <div className="row">
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Date: <span className="note_info">(YYYY/MM/DD):</span></label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Currency:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Amount:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Name of Bank/branch:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Account No:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h6 className="mt-4">B. Amount Remitted to India (Particulars)</h6>
                            <div className="row">
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Date: <span className="note_info">(YYYY/MM/DD):</span></label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Currency:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Amount:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Name of Bank/branch:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3">
                                    <div className="row">
                                        <label className="col-md-5 form-label label_head">Account No:</label>
                                        <div className="col-md-7">
                                            <p className="">NIL</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 d-flex">
                                <div className="">
                                    <span className="info_red me-2">*</span>
                                </div>
                                <div className="">
                                    <p className="note_info">1. In the case of import of goods or services, date of import may be furnished against date of drawdown.</p>
                                    <p className="note_info">2. In the case of financial lease date of acquisition of the goods is to be mentioned as date of drawdown.</p>
                                    <p className="note_info">3. In the case of securitised instruments, date of issue may be shown as date of drawdown</p>
                                    <p className="note_info">4. In the case of multi‐currency loan a separate block(s) may be attached to the return</p>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h5 className="mt-4">B.2: Balance amount of loan to be drawn in future</h5>
                                <div className="row">
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Expected Date of drawdown:</label>
                                            <div className="col-md-8">
                                                <input type="date" className="form-control" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Currency:</label>
                                            <div className="col-md-8">
                                                <input type="number" className="form-control" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Amount:</label>
                                            <div className="col-md-8">
                                                <input type="number" className="form-control" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="my-2">If more than one installment</h6>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Total number of drawals:</label>
                                            <div className="col-md-8">
                                                <input type="number" className="form-control" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">No. of drawals in a calendar year:</label>
                                            <div className="col-md-8">
                                                <input type="number" className="form-control" placeholder="Type here" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    isTab === 3 &&
                    <React.Fragment>
                        <div className="py-3">
                            <div className="mt-3">
                                <h5 className="mt-4">C.1: Details of utilisation of drawdowns (only principal amount) during the month:</h5>
                                <h6 className="mt-4">From Amount Held Abroad (Particulars)</h6>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Date: <span className="note_info">(YYYY/MM/DD):</span></label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Purpose code:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Country:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Name of Bank:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Account No:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mt-4">From Amount Remitted to India (Particulars)</h6>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Date: <span className="note_info">(YYYY/MM/DD):</span></label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Purpose code:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Country:</label>
                                            <div className="col-md-7">
                                                <p className="">Not Required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Name of Bank:</label>
                                            <div className="col-md-7">
                                                <p className="">Not Required</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Account No:</label>
                                            <div className="col-md-7">
                                                <p className="">Not Required</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <h5 className="mt-4">C.2: Outstanding balance amount (principal only) as at month end:</h5>
                                <h6 className="mt-4">Parked Abroad (Particulars)</h6>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Deposits / Others</label>
                                            <div className="col-md-7">
                                                <p className="">Others</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Cumulative period in months:</label>
                                            <div className="col-md-7">
                                                <p className="">57</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">USD</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">69,496</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Name of bank and branch:</label>
                                            <div className="col-md-7">
                                                <p className="">Citi Bank Hong Kong</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Account No:</label>
                                            <div className="col-md-7">
                                                <p className="">1/087541/017</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h6 className="mt-4">Parked in India (Particulars)</h6>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Deposits / Others</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Cumulative period in months:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Name of bank and branch:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Account No:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    isTab === 4 &&
                    <React.Fragment>
                        <div className="py-3">
                            < div className="mt-3">
                                <h5 className="mt-4">D.1: Principal repayment, interest payment etc. during the month (in loan currency):</h5>
                                <h6 className="mt-4">Principal Repayment @ (Purpose)</h6>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <label className="col-md-4 form-label label_head">Tranche No.</label>
                                                    <div className="col-md-8">
                                                        <input type="number" className="form-control" placeholder="Type here" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Date of Remittance</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Code for Source of remittance</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Whether Prepayment of Principal (Y/N)#</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 d-flex">
                                    <div className="">
                                        <span className="info_red me-2">*</span>
                                    </div>
                                    <div className="">
                                        <p className="note_info">1. In case of prepayment please provide details of Automatic / Approval Route No., Date, Amount as Annex.</p>
                                        <p className="note_info">2. In case of conversion of FCCB/ECB into equity, Buyback/Redemption of outstanding FCCB or write‐off of ECB
                                            principal amount, the transactions still to be shown against Principal Repayment with appropriate remarks.</p>
                                    </div>
                                </div>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="col-1" rowSpan="2" >Date <span className="note_info">(YYYY/MM/DD) First repayment date</span></th>
                                        <th className="col-3" rowSpan="2">Currency</th>
                                        <th className="col-2" rowSpan="2">Amount in Loan Currency in each transactions</th>
                                        <th className="col-4" colSpan="2">If more than one installment</th>
                                        <th className="col-2" rowSpan="2">Annuity Rate (if annuity payment)</th>
                                    </tr>
                                    <tr>
                                        <th>Total Number of installments</th>
                                        <th>No. of drawals in a calendar year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="py-3">
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                    <tr className="py-3">
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* <div className="mt-3">
                                <h5 className="mt-4">D.2: Revised Principal Repayment Schedule (if revised / entered into interest rate swap):</h5>
                                <div className="row">
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Date: <span className="note_info">(YYYY/MM/DD) First repayment date</span></label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Currency:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Amount in Loan Currency in each transactions:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <h6 className="my-2">If more than one equal installments</h6>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Total Number of installments:</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">No. of payments in a calendar year (1, 2, 3, 4, 6, 12):</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-3">
                                        <div className="row">
                                            <label className="col-md-5 form-label label_head">Annuity Rate (if annuity payment):</label>
                                            <div className="col-md-7">
                                                <p className="">NIL</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </React.Fragment>
                }
                {
                    isTab === 5 &&
                    <React.Fragment>
                        <div className="py-3">
                            <h5 className="mt-4">E.1 Hedging details:</h5>
                            <table className="mt-4">
                                <thead>
                                    <tr>
                                        <th className="col-2" rowSpan="2" >Outstanding Principal ECB amount*</th>
                                        <th className="col-2" rowSpan="2">Currency</th>
                                        <th className="col-3" colSpan="2">Financial hedge(s)</th>
                                        <th className="col-3" colSpan="2">Natural hedge</th>
                                        <th className="col-2" rowSpan="2">Annualised percentage cost of financial hedge(s) for ECB
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Notional value</th>
                                        <th>% of outstanding ECB amount</th>
                                        <th>Notional value</th>
                                        <th>% of outstanding ECB amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="py-3">
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                    </tr>
                                    <tr className="py-3">
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mt-4 d-flex">
                                <div className="">
                                    <span className="info_red me-2">*</span>
                                </div>
                                <div className="">
                                    <p className="note_info">as on the last date of the reporting month</p>
                                </div>
                            </div>
                            <h5 className="mt-4">E.2 Foreign exchange earnings and expenditure, if any, for the last three financial years (only corresponding to same currency of ECB):</h5>
                            <table className="mt-4">
                                <thead>
                                    <tr>
                                        <th className="col-2">Financial Year</th>
                                        <th className="col-2">Currency</th>
                                        <th className="col-3">Foreign Currency Earnings</th>
                                        <th className="col-3">Foreign Currency expenditure</th>
                                        <th className="col-2">Annual EBID** in INR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="py-3">
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                        <td>NA</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mt-4 d-flex">
                                <div className="">
                                    <span className="info_red me-2">**</span>
                                </div>
                                <div className="">
                                    <p className="note_info">Earnings before Interest and Depreciation (EBID), as defined table above = Profit After Tax + Depreciation + Interest on debt + Lease Rentals, if any</p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
                {
                    isTab === 6 &&
                    <React.Fragment>
                        <div className="py-3">

                        </div>
                    </React.Fragment>
                }
            </div >

        </React.Fragment >
    )
}

export default Form2