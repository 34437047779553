import { combineReducers } from 'redux';
import { OtherCharges } from './OtherChargesReducers';
import { loginReducer } from './loginReducer';
import { formlistreducers } from './FormListReducers';
import { formSummaryReducers } from './FormSummaryReducers';
import { getMasterTableReducers } from './MasterTable';
import { borrowingPurposeReducers } from './BorrowingPurposeReducers';
import { drawdownReducers } from './DrawdownReducers';
import { repaymentReducers } from './RepaymentReducers';
const rootReducer = combineReducers({
    USER: loginReducer,
    FORM: formlistreducers,
    SUMMARY: formSummaryReducers,
    OTHERS_CHARGES: OtherCharges,
    MASTERTABLE: getMasterTableReducers,
    BORROWINGPURPOSE:borrowingPurposeReducers,
    DRAWDOWN:drawdownReducers,
    REPAYMENT:repaymentReducers
});
export default rootReducer;