import React from 'react'
import Pagination from "react-js-pagination"

class Paginator extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { pageDisplayed, onChange, activePage } = this.props
        return (
            <div className="mt-3 d-flex justify-content-center">
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={pageDisplayed * 10}
                    pageRangeDisplayed={10}
                    onChange={onChange}
                    activeLinkClass="active"
                    itemClass="page-item"
                    hideFirstLastPages
                    linkClass="page-link"
                />
            </div>
        )
    }
}
export default Paginator