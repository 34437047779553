import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { GET_BORROWING_PURPOSE_CODE, GET_GUARANTEE_STATUS_CODE } from '../action/masterTable';

export function* getGuaranteeStatusCodeList(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_GUARANTEE_STATUS_CODE, params)
        yield put(Actions.getGuaranteeStatusCodeSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getGuaranteeStatusCodeFailure(error))
    }
}
export function* getBorrowingPurposeCodeList(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_BORROWING_PURPOSE_CODE, params)
        yield put(Actions.getBorrowingPurposeCodeSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getBorrowingPurposeCodeFailure(error))
    }
}
export default function* getMasterTableWatcher() {
    yield all([
        takeLatest(GET_BORROWING_PURPOSE_CODE, getBorrowingPurposeCodeList),
        takeLatest(GET_GUARANTEE_STATUS_CODE, getGuaranteeStatusCodeList),
    ]);
}
