import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_REPAYMENT, UPDATE_REPAYMENT,DELETE_REPAYMENT } from '../action/repayment';

export function* createRepayment(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.CREATE_REPAYMENT, params)
        yield put(Actions.createRepaymentSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.createRepaymentFailure(error))
    }
}
export function* updateRepayment(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.UPDATE_REPAYMENT, params)
        yield put(Actions.updateRepaymentSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.updateRepaymentFailure(error))
    }
}
export function* deleteRepayment(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.DELETE_REPAYMENT, params)
        yield put(Actions.deleteRepaymentSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.deleteRepaymentFailure(error))
    }
}

export default function* repaymentWatcher() {
    yield all([
        takeLatest(CREATE_REPAYMENT, createRepayment),
        takeLatest(UPDATE_REPAYMENT, updateRepayment),
        takeLatest(DELETE_REPAYMENT, deleteRepayment),
    ]);
}
