import { Actions } from '../action';

const initialState = {
    isLoading: false,
    formSummaryDetailslist: [],
    getSummaryDetailslist: [],
    getPdfDetails: [],
    error: false,
}
export const formSummaryReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FORM_SUMMARY_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_SUMMARY_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formSummaryDetailslist: action?.data,
                error: false
            };
        }
        case Actions.FORM_SUMMARY_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_SUMMARY_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_SUMMARY_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getSummaryDetailslist: action?.data,
                error: false
            };
        }
        case Actions.GET_SUMMARY_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_PDF_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_PDF_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getPdfDetails: action?.data,
                error: false
            };
        }
        case Actions.GET_PDF_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};



export const getFormSummaryDetails = (state) => state?.SUMMARY?.getSummaryDetailslist;
