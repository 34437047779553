import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../redux/action";
import bg1 from './bg1.png';
import bg2 from './bg2.png';
import bg3 from './bg3.png';
import bg4 from './bg4.png';
import bg5 from './bg5.png';
import bg6 from './bg6.png';
import bg7 from './bg7.png';
import bg8 from './bg8.png';
import moment from "moment";
import { toast } from "react-toastify";

const FORM1PDF = React.forwardRef((props, ref) => {

    const { loanId } = props
    const dispatch = useDispatch();
    const [pdfData, setPdfData] = useState({})
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const [borrowingPurposeCode, setBorrowingPurposeCode] = useState([])
    const { AgreementDetail, BorrowerDetail, DeclarationDetail, DrawdownSchedules, LenderDetail, OtherCharge, PrincipalSchedules, Sumarry, NatureDetailsSecurities, ...loanPfdDatas } = pdfData || {}
    const [isloading, setLoading] = useState(false)

    useEffect(() => {
        const callback = (res) => {
            setPdfData(res?.response)
        }
        let params = {
            loanId: loanId,
            isDraft: isDraft
        }
        getBorrowingPurposeCode();
        dispatch(Actions.getPdfList(params, callback))
    }, [loanId])
    const getBorrowingPurposeCode = () => {
        const callback = (res) => {
            if (!res.error) {
                setBorrowingPurposeCode(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        }
        dispatch(Actions.getBorrowingPurposeCode('', callback))
    }
    let count = 0;
    return (
        // <div id="page-container" ref={ref}>
        //     <div id="pf1" className="pf w0 h0" data-page-no="1">
        //         <div className="pc pc1 w0 h0">
        //             <img className="bi x0 y0 w1 h1" alt="" src={bg1} />
        //             <div className="t m0 x1 h2 y1 ff1 fs0 fc0 sc0 ls0 ws0">Annex I</div>
        //             <div className="t m1 x2 h2 y2 ff1 fs0 fc0 sc0 ls0 ws0">Form ECB</div>
        //             <div className="t m2 x3 h3 y3 ff2 fs0 fc0 sc0 ls0 ws0">
        //                 (Application and Reporting loan agreement details under Foreign Exchange Management Act,1999)</div>
        //             <div className="t m3 x5 h4 y4 ff3 fs0 fc0 sc0 ls0 ws0">1.</div>
        //             <div className="t m1 x6 h3 y4 ff2 fs0 fc0 sc0 ls0 ws0">All dates should be in the format YYYY/MM/DD (e.g., 2012/01/21 for January 21, 2012).</div>
        //             <div className="t m3 x5 h4 y5 ff3 fs0 fc0 sc0 ls0 ws0">2.</div>
        //             <div className="t m1 x6 h3 y5 ff2 fs0 fc0 sc0 ls0 ws0">No item should be left blank.Incase, anyitemis notapplicable,write ‘N.A.’againstit.</div>
        //             <div className="t m3 x5 h4 y6 ff3 fs0 fc0 sc0 ls0 ws0">3.</div>
        //             <div className="t m1 x6 h3 y6 ff2 fs0 fc0 sc0 ls0 ws0">Ifspace isnotsufficient forgivingfull detailsagainstany item,separate sheet(s) maybe attached </div>
        //             <div className="t m2 x6 h3 y7 ff2 fs0 fc0 sc0 ls0 ws0">to the Form and serially numbered as Annex. Each such Annex should be certified by both the </div>
        //             <div className="t m1 x6 h3 y8 ff2 fs0 fc0 sc0 ls0 ws0">borrower and AD.</div>
        //             <div className="t m3 x5 h4 y9 ff3 fs0 fc0 sc0 ls0 ws0">4.</div>
        //             <div className="t m1 x6 h3 y9 ff2 fs0 fc0 sc0 ls0 ws0">The borrower should give a brief description of his business (whether in manufacturing/</div>
        //             <div className="t m1 x6 h3 ya ff2 fs0 fc0 sc0 ls0 ws0">trade/ provide servicesetc.) for theAD’s use.</div>
        //             <div className="t m3 x5 h4 yb ff3 fs0 fc0 sc0 ls0 ws0">5.</div>
        //             <div className="t m1 x6 h3 yb ff2 fs0 fc0 sc0 ls0 ws0">Before forwarding the Form to the Reserve Bank of India, AD must ensure that the form is </div>
        //             <div className="t m1 x6 h3 yc ff2 fs0 fc0 sc0 ls0 ws0">complete in all respects and scrutinise all the related original documents at its end.</div>
        //             <div className="t m1 x6 h3 yd ff2 fs0 fc0 sc0 ls0 ws0">Incomplete Forms a reliable to be rejected/returnedby RBItoAD.</div>
        //             <div className="t m3 x5 h4 ye ff3 fs0 fc0 sc0 ls0 ws0">6.</div>
        //             <div className="t m1 x6 h3 ye ff2 fs0 fc0 sc0 ls0 ws0">Following codes are for use in fillingPart C of the Form:</div>
        //             <div className="t m3 x5 h4 yf ff3 fs0 fc0 sc0 ls0 ws0">7.</div>
        //             <div className="t m1 x6 h3 yf ff2 fs0 fc0 sc0 ls0 ws0">To be submitted in duplicate by the borrower to designated Authorised Dealer (AD) for all </div>
        //             <div className="t m1 x6 h3 y10 ff2 fs0 fc0 sc0 ls0 ws0">categories and any amount of external commercial borrowing(ECB). After examining </div>
        //             <div className="t m1 x6 h3 y11 ff2 fs0 fc0 sc0 ls0 ws0">conformity with the extant ECB guidelines, the AD may provide requisite details in the </div>
        //             <div className="t m1 x6 h3 y12 ff2 fs0 fc0 sc0 ls0 ws0">summary Sheet of the Form and forward one copy(within 7daysfrom the date of signing loan </div>
        //             <div className="t m1 x6 h3 y13 ff2 fs0 fc0 sc0 ls0 ws0">agreement between borrower and lender) for allotment of Loan Registration Number (LRN) to:</div>
        //             <div className="c x0 y14 w2 h5">
        //                 <div className="t m1 x9 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0">Box 1: Guarantee Status Code</div>
        //             </div>
        //             <div className="c x0 y16 w2 h5">
        //                 <div className="t m4 x9 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0">Sr. No. Code</div>
        //                 <div className="t m1 x6 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0">Description</div>
        //             </div>
        //             <div className="c x0 y17 w2 h6">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">1<span className="_ _d"> </span>GG Govt.ofIndia guarantee</div>
        //             </div>
        //             <div className="c x0 y19 w2 h7">
        //                 <div className="t m1 x9 h3 y1a ff2 fs0 fc0 sc0 ls0 ws0">2<span className="_ _e"> </span>CG PublicSector guarantee</div>
        //             </div>
        //             <div className="c x0 y1b w2 h6">
        //                 <div className="t m1 x9 h3 y1c ff2 fs0 fc0 sc0 ls0 ws0">3</div>
        //                 <div className="t m4 xa h3 y1c ff2 fs0 fc0 sc0 ls0 ws0">PB Public SectorBank guarantee</div>
        //             </div>
        //             <div className="c x0 y1d w2 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //                 <div className="t m4 xa h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">FI Financial Institutionguarantee</div>
        //             </div>
        //             <div className="c x0 y1e w2 h8">
        //                 <div className="t m1 x9 h3 y1f ff2 fs0 fc0 sc0 ls0 ws0">5<span className="_ _10"> </span>MB Multilateral/ Bilateral
        //                 </div>
        //                 <div className="t m1 xb h3 y20 ff2 fs0 fc0 sc0 ls0 ws0">Institution guarantee</div>
        //             </div>
        //             <div className="c x0 y21 w2 h6">
        //                 <div className="t m1 x9 h3 y1c ff2 fs0 fc0 sc0 ls0 ws0">6<span className="_ _e"> </span>PG Private Bankguarantee</div>
        //             </div>
        //             <div className="c x0 y22 w2 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">7<span className="_ _11"> </span>PS Private Sector guarantee</div>
        //             </div>
        //             <div className="c x0 y23 w2 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">8<span className="_ _12"> </span>MS Mortgage of assets/ security
        //                 </div>
        //             </div>
        //             <div className="c x0 y24 w2 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">9<span className="_ _d"> </span>OG Other guarantee</div>
        //             </div>
        //             <div className="c x0 y25 w2 h6">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">10<span className="_ _13"> </span>NN Not guaranteed</div>
        //             </div>
        //             <div className="c xc y14 w3 h5">
        //                 <div className="t m1 x9 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0">Box II: Borrowing Purpose Code</div>
        //             </div>
        //             <div className="c xc y16 w3 h5">
        //                 <div className="t m1 x9 h2 y15 ff1 fs0 fc0 sc0 ls0 ws0">Sr. No <span className="_ _14"></span>Code Description</div>
        //             </div>
        //             <div className="c xc y17 w3 h6">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">1<span className="_ _11"> </span>IC Import of capital goods</div>
        //             </div>
        //             <div className="c xc y26 w3 h8">
        //                 <div className="t m1 x9 h3 y27 ff2 fs0 fc0 sc0 ls0 ws0">2<span className="_ _16"> </span>RL Local sourcing of capital goods</div>
        //                 <div className="t m1 xd h3 y28 ff2 fs0 fc0 sc0 ls0 ws0">(Rupeeexpenditure)</div>
        //             </div>
        //             <div className="c xc y29 w3 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">3<span className="_ _17"> </span>SL On-lending or sub-lending</div>
        //             </div>
        //             <div className="c xc y2a w3 h6">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">4<span className="_ _18"> </span>RF Refinancing ofearlierECB
        //                 </div>
        //             </div>
        //             <div className="c xc y2b w3 h7">
        //                 <div className="t m1 x9 h3 y1a ff2 fs0 fc0 sc0 ls0 ws0">5<span className="_ _e"> </span>NP New Project</div>
        //             </div>
        //             <div className="c xc y2c w3 h9">
        //                 <div className="t m1 x9 h3 y2d ff2 fs0 fc0 sc0 ls0 ws0">6<span className="_ _11"> </span>ME</div>
        //                 <div className="t m4 xd h3 y2d ff2 fs0 fc0 sc0 ls0 ws0">Modernisation/Expansion of existing</div>
        //                 <div className="t m1 xd h3 y2e ff2 fs0 fc0 sc0 ls0 ws0">Units</div>
        //             </div>
        //             <div className="c xc y2f w3 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">7<span className="_ _e"> </span>OI Overseas investment inJV/WOS</div>
        //             </div>
        //             <div className="c xc y30 w3 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">8<span className="_ _d"> </span>MF Micro Finance activity</div>
        //             </div>
        //             <div className="c xc y31 w3 h6">
        //                 <div className="t m1 x9 h3 y1c ff2 fs0 fc0 sc0 ls0 ws0">9<span className="_ _e"> </span>OT Others (specify)</div>
        //             </div>
        //             <div className="c xc y32 w3 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">10<span className="_ _19"> </span>RR Refinancingof rupee loans</div>
        //             </div>
        //             <div className="c xc y33 w3 h5">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">11<span className="_ _19"> </span>RB Redemption of FCCBs</div>
        //             </div>
        //             <div className="c xc y34 w3 h6">
        //                 <div className="t m1 x9 h3 y1c ff2 fs0 fc0 sc0 ls0 ws0">12<span className="_ _19"> </span>IF Infrastructuredevelopment</div>
        //             </div>
        //             <div className="c xc y35 w3 h8">
        //                 <div className="t m1 x9 h3 y27 ff2 fs0 fc0 sc0 ls0 ws0">13<span className="_ _1c"> </span>RC Working capital/ general corporate </div>
        //                 <div className="t m1 x9 h3 y28 ff2 fs0 fc0 sc0 ls0 ws0">purpose</div>
        //             </div>
        //         </div>
        //     </div>
        //     <div id="pf2" className="pf w0 h0" data-page-no="2">
        //         <div className="pc pc2 w0 h0"><img className="bi x0 y36 w4 ha" alt="" src={bg2} />
        //             <div className="c xf y37 w5 hb">
        //                 <div className="t m1 x10 hc y38 ff2 fs1 fc0 sc0 ls0 ws0">16</div>
        //             </div>
        //             <div className="t m1 x11 h3 y39 ff2 fs0 fc0 sc0 ls0 ws0">The Director</div>
        //             <div className="t m1 x11 h3 y3a ff2 fs0 fc0 sc0 ls0 ws0">Balance of Payments Statistics Division</div>
        //             <div className="t m2 x11 h3 y3b ff2 fs0 fc0 sc0 ls0 ws0">Departmentof Statisticsand InformationManagement (DSIM) </div>
        //             <div className="t m1 x11 h3 y3c ff2 fs0 fc0 sc0 ls0 ws0">Reserve Bank of India</div>
        //             <div className="t m0 x12 h3 y3d ff2 fs0 fc0 sc0 ls0 ws0">C-8-9 Bandra-Kurla Complex </div>
        //             <div className="t m1 xf h3 y3d ff2 fs0 fc0 sc0 ls0 ws0">Mumbai – 400 051</div>
        //             <div className="c x0 y3e w6 hd">
        //                 <div className="t m1 x13 h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Agreement Details (To be filled by borrowers of External Commercial B orrowings)</div>
        //             </div>
        //             <div className="c x0 y40 w7 he">
        //                 <div className="t m1 x14 hf y41 ff4 fs0 fc0 sc0 ls0 ws0">ECB application</div>
        //             </div>
        //             <div className="c x12 y40 w8 he">
        //                 <div className="t m1 x15 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Original</div>
        //             </div>
        //             <div className="c x16 y40 w9 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.ecbApplicationType === 1 ? "√" : ""}</div>
        //             </div>
        //             <div className="c x18 y40 wa he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Revised</div>
        //             </div>
        //             <div className="c x19 y40 wb he">
        //                 <div className="t m1 x17 h3 y42 ff5 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.ecbApplicationType === 2 ? "√" : ""}</div>
        //             </div>
        //             <div className="c x0 y43 w7 hd">
        //                 <div className="t m1 x13 hf y3f ff4 fs0 fc0 sc0 ls0 ws0">Form</div>
        //             </div>
        //             <div className="c x12 y43 w8 hd">
        //                 <div className="t m1 x14 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">FCYECB</div>
        //             </div>
        //             <div className="c x16 y43 w9 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftAgreementDetail?.formType === 1 ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c x18 y43 wa hd">
        //                 <div className="t m1 x1a h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">INR ECB</div>
        //             </div>
        //             <div className="c x16 y43 w9 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftAgreementDetail?.formType === 2 ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c x0 y45 w6 h10">
        //                 <div className="t m1 x9 h2 y46 ff1 fs0 fc0 sc0 ls0 ws0">In case of Revised</div>
        //             </div>
        //             <div className="c x0 y47 wc hd">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">Loan Registration Number allotted</div>
        //             </div>
        //             <div className="c x18 y47 wd hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(0)}</div>
        //             </div>
        //             <div className="c x1b y47 we hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(1)}</div>
        //             </div>
        //             <div className="c x1c y47 wf hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(2)}</div>
        //             </div>
        //             <div className="c x1d y47 w10 h11">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(3)}</div>
        //             </div>
        //             <div className="c x1e y47 wf hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(4)}</div>
        //             </div>
        //             <div className="c x1f y47 we hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(5)}</div>
        //             </div>
        //             <div className="c x20 y47 wd hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(6)}</div>
        //             </div>
        //             <div className="c x21 y47 we hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(7)}</div>
        //             </div>
        //             <div className="c x22 y47 wd hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">{pdfData?.DraftAgreementDetail?.lrn?.charAt(8)}</div>
        //             </div>
        //             <div className="c x0 y49 w7 h12">
        //                 <div className="t m1 x23 hf y4a ff4 fs0 fc0 sc0 ls0 ws0">ECB under</div>
        //             </div>
        //             <div className="c x12 y49 w8 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Approval</div>
        //                 <div className="t m1 x9 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Route</div>
        //             </div>
        //             <div className="c x19 y49 wb h13">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftAgreementDetail?.ecbUnderRoute === "approvalRoute" ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c x18 y49 wa h13">
        //                 <div className="t m1 x9 h3 y4c ff2 fs0 fc0 sc0 ls0 ws0">Automatic</div>
        //                 <div className="t m1 x9 h3 y4d ff2 fs0 fc0 sc0 ls0 ws0">Route</div>
        //             </div>
        //             <div className="c x19 y49 wb h13">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftAgreementDetail?.ecbUnderRoute === "automicRoute" ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c x0 y4f wc h14">
        //                 <div className="t m1 x9 h3 y50 ff2 fs0 fc0 sc0 ls0 ws0">Whether requires clearance from any statutory
        //                 </div>
        //                 <div className="t m1 x9 h3 y51 ff2 fs0 fc0 sc0 ls0 ws0">authority? If yes, furnish the name of
        //                     authority, </div>
        //                 <div className="t m1 x9 h3 y52 ff2 fs0 fc0 sc0 ls0 ws0">clearance no. and date.</div>
        //             </div>
        //             <div className="c x18 y4f w11 h14">
        //                 <div className="t m1 x17 h3 y53 ff2 fs0 fc0 sc0 ls0 ws0"> NO</div>
        //             </div>
        //             <div className="c x0 y54 wc he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Comments/ recommendation of AD bank:</div>
        //             </div>
        //             <div className="c x0 y55 w12 he">
        //                 <div className="t m1 x24 h2 y41 ff1 fs0 fc0 sc0 ls0 ws0">Part A: Borrower details</div>
        //             </div>
        //             <div className="c xc y56 w13 hd">
        //                 <div className="t m1 x9 h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Borrower Category <span className="ff2">(Tick
        //                     one)</span></div>
        //             </div>
        //             <div className="c xc y57 w14 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Public</div>
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">Sector</div>
        //             </div>
        //             <div className="c x25 y57 w16 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftBorrowerDetail?.borrowerCategoryType === 1 ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c x1f y57 w15 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Private</div>
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">Sector</div>
        //             </div>
        //             <div className="c x25 y57 w16 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">{pdfData?.DraftBorrowerDetail?.borrowerCategoryType === 2 ? "√" : ""}</span></div>
        //             </div>
        //             <div className="c xc y59 w13 h15">
        //                 <div className="t m1 x9 h2 y5a ff1 fs0 fc0 sc0 ls0 ws0">Detailed Category <span className="ff2">(Tick
        //                     one)</span></div>
        //             </div>
        //             <div className="c xc y5b w17 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Corporate - Manufacturing</div>
        //             </div>
        //             <div className="c x26 y5b w18 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">√</span></div>
        //             </div>
        //             <div className="c xc y5c w17 h16">
        //                 <div className="t m1 x9 h3 y5d ff2 fs0 fc0 sc0 ls0 ws0">Corporate –Infrastructure</div>
        //                 <div className="t m1 x9 h3 y5e ff2 fs0 fc0 sc0 ls0 ws0">a) Transport </div>
        //                 <div className="t m1 x9 h3 y5f ff2 fs0 fc0 sc0 ls0 ws0">b) Energy</div>
        //                 <div className="t m1 x9 h3 y60 ff2 fs0 fc0 sc0 ls0 ws0">c) Water and Sanitation</div>
        //                 <div className="t m1 x9 h3 y61 ff2 fs0 fc0 sc0 ls0 ws0">d) Communication</div>
        //                 <div className="t m1 x9 h3 y62 ff2 fs0 fc0 sc0 ls0 ws0">e) Social and Commercial Infrastructure </div>
        //                 <div className="t m1 x9 h3 y63 ff2 fs0 fc0 sc0 ls0 ws0">f) Exploration, Mining and Refinery</div>
        //                 <div className="t m1 x9 h3 y64 ff2 fs0 fc0 sc0 ls0 ws0">g) Others</div>
        //                 <div className="t m1 x9 h3 y65 ff2 fs0 fc0 sc0 ls0 ws0">Sub-Sector:</div>
        //             </div>
        //             <div className="c xc y66 w17 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Corporate –Service Secto<span
        //                     className="_ _f"></span>r -</div>
        //             </div>
        //             <div className="c xc y67 w17 h17">
        //                 <div className="t m1 x9 h3 y68 ff2 fs0 fc0 sc0 ls0 ws0">Others</div>
        //                 <div className="t m1 x9 h3 y69 ff2 fs0 fc0 sc0 ls0 ws0">a) Units in SEZ;</div>
        //                 <div className="t m1 x9 h3 y6a ff2 fs0 fc0 sc0 ls0 ws0">b) SIDBI;</div>
        //                 <div className="t m1 x9 h3 y6b ff2 fs0 fc0 sc0 ls0 ws0">c) EXIM Bank;</div>
        //                 <div className="t m1 x9 h3 y6c ff2 fs0 fc0 sc0 ls0 ws0">d) Micro-finance entities</div>
        //                 <div className="t m1 x9 h3 y6d ff2 fs0 fc0 sc0 ls0 ws0">e) Others : </div>
        //             </div>
        //             <div className="c xc y6e w17 he">
        //                 <div className="t m1 x9 h2 y41 ff1 fs0 fc0 sc0 ls0 ws0">Bank</div>
        //             </div>
        //             <div className="c xc y6f w17 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Financial Institution (other than NBFC)</div>
        //             </div>
        //             <div className="c x0 y70 w19 h18">
        //                 <div className="t m1 x9 h3 y71 ff2 fs0 fc0 sc0 ls0 ws0">Name and address of the Borrower (in BLOCK
        //                 </div>
        //                 <div className="t m1 x9 h3 y72 ff2 fs0 fc0 sc0 ls0 ws0">letters)</div>
        //                 <div className="t m1 x9 h2 y73 ff1 fs0 fc0 sc0 ls0 ws0">BYD INDIA PRIVATE LIMITED,</div>
        //                 <div className="t m1 x9 h3 y74 ff2 fs0 fc0 sc0 ls0 ws0">DEVELOPED PLOT NO.OZ-7,SIPCOT, </div>
        //                 <div className="t m1 x9 h3 y75 ff2 fs0 fc0 sc0 ls0 ws0">ORAGADAM, SRIPERAMBUDUR TALUK, </div>
        //                 <div className="t m1 x9 h3 y76 ff2 fs0 fc0 sc0 ls0 ws0">KANCHIPURAM KANCHEEPURAM TN 602105 </div>
        //                 <div className="t m1 x9 h3 y77 ff2 fs0 fc0 sc0 ls0 ws0">Registration Number given by </div>
        //                 <div className="t m1 x9 h3 y78 ff2 fs0 fc0 sc0 ls0 ws0">the Registrar of Companies:062621</div>
        //                 <div className="t m1 x9 h3 y79 ff2 fs0 fc0 sc0 ls0 ws0">PAN Number of Company: AADCB0819N</div>
        //                 <div className="t m1 x9 h3 y7a ff2 fs0 fc0 sc0 ls0 ws0">Business Activity: Manufacturing of Electric
        //                 </div>
        //                 <div className="t m1 x9 h3 y7b ff2 fs0 fc0 sc0 ls0 ws0">Vehicles</div>
        //                 <div className="t m1 x9 h3 y7c ff2 fs0 fc0 sc0 ls0 ws0">Contact Official’s Name: ZHANG JIE</div>
        //                 <div className="t m1 x9 h3 y7d ff2 fs0 fc0 sc0 ls0 ws0">Designation: EXECUTIVE DIRECTOR</div>
        //                 <div className="t m1 x9 h3 y7e ff2 fs0 fc0 sc0 ls0 ws0">Phone No. :+91 7397775966</div>
        //                 <div className="t m1 x9 h3 y7f ff2 fs0 fc0 sc0 ls0 ws0">Fax No. :</div>
        //                 <div className="t m1 x9 h3 y80 ff2 fs0 fc0 sc0 ls0 ws0">E-mail ID : KETSU.ZHANG@BYD.COM</div>
        //                 <div className="t m1 x9 h3 y81 ff2 fs0 fc0 sc0 ls0 ws0">(No item should be left blank)</div>
        //             </div>
        //             <div className="c xc y82 w1a he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">NBFC- IFC/AFC</div>
        //             </div>
        //             <div className="c x27 y82 w1b he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Reg.No.</div>
        //             </div>
        //         </div>
        //     </div>
        //     <div id="pf3" className="pf w0 h0" data-page-no="3">
        //         <div className="pc pc3 w0 h0"><img className="bi x0 y83 w1c h19" alt="" src={bg3} />
        //             <div className="c x28 y84 w1d hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">NBFC- MFI</div>
        //             </div>
        //             <div className="c x29 y84 w1e hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Reg.No.</div>
        //             </div>
        //             <div className="c x28 y85 w1d he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">NBFC- Others</div>
        //             </div>
        //             <div className="c x29 y85 w1e he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Reg.No.</div>
        //             </div>
        //             <div className="c x28 y86 w1f h15">
        //                 <div className="t m1 x9 h3 y5a ff2 fs0 fc0 sc0 ls0 ws0">Non-Government Organization (NGO)</div>
        //             </div>
        //             <div className="c x28 y87 w1f he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">MicroFinance Institution (MFI)</div>
        //             </div>
        //             <div className="c x28 y88 w20 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Others</div>
        //                 <div className="t m1 x9 h3 y89 ff2 fs0 fc0 sc0 ls0 ws0">(Specify)</div>
        //             </div>
        //             <div className="c x0 y8a w21 h15">
        //                 <div className="t m1 x2a h2 y8b ff1 fs0 fc0 sc0 ls0 ws0">PartB: Lender details</div>
        //             </div>
        //             <div className="c x2b y8c w22 hd">
        //                 <div className="t m1 x9 h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Lender Category <span className="ff2">(Tick
        //                     one)</span></div>
        //             </div>
        //             <div className="c x2b y8d w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Multilateral Financial Institution</div>
        //             </div>
        //             <div className="c x2b y8e w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Foreign Government(Bilateral Agency)</div>
        //             </div>
        //             <div className="c x2b y8f w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Export Credit Agency</div>
        //             </div>
        //             <div className="c x2b y90 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Indian Commercial Bank branch abroad</div>
        //             </div>
        //             <div className="c x2b y91 w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Other Commercial Bank</div>
        //             </div>
        //             <div className="c x2b y92 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Supplier of Equipment</div>
        //             </div>
        //             <div className="c x2b y93 w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Leasing Company</div>
        //             </div>
        //             <div className="c x2b y94 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Foreign Collaborator /Foreign Equity Holder
        //                 </div>
        //             </div>
        //             <div className="c x2c y94 w24 hd">
        //                 <div className="t m1 x17 h3 y44 ff5 fs0 fc0 sc0 ls0 ws0">√</div>
        //             </div>
        //             <div className="c x2b y95 w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">International Capital Market</div>
        //             </div>
        //             <div className="c x2b y96 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Regional Financial Institution</div>
        //             </div>
        //             <div className="c x2b y97 w23 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Government Owned Development Financial</div>
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">Institution</div>
        //             </div>
        //             <div className="c x2b y98 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Private placement (RDBs)</div>
        //             </div>
        //             <div className="c x2b y99 w23 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Public Offer (RDBs)</div>
        //             </div>
        //             <div className="c x0 y9a w25 h1a">
        //                 <div className="t m1 x9 h3 y9b ff2 fs0 fc0 sc0 ls0 ws0">Name and address of the lender/ lessor </div>
        //                 <div className="t m1 x9 h3 y9c ff2 fs0 fc0 sc0 ls0 ws0">/foreign supplier (in BLOCK letters)</div>
        //                 <div className="t m1 x9 h2 y9d ff1 fs0 fc0 sc0 ls0 ws0">LEAD WEALTH INTERNATIONAL LIMITED</div>
        //                 <div className="t m1 x9 h3 y9e ff2 fs0 fc0 sc0 ls0 ws0">PO BOX 957, OFFSHORE INCORPORATIONS </div>
        //                 <div className="t m1 x9 h3 y9f ff2 fs0 fc0 sc0 ls0 ws0">CENTRE, ROAD TOWN, TORTOLA, BRITISH </div>
        //                 <div className="t m1 x9 h3 ya0 ff2 fs0 fc0 sc0 ls0 ws0">VIRGIN ISLANDS.</div>
        //                 <div className="t m1 x9 h3 ya1 ff2 fs0 fc0 sc0 ls0 ws0">Country: BRITISH VIRGIN ISLANDS.</div>
        //                 <div className="t m1 x9 h3 ya2 ff2 fs0 fc0 sc0 ls0 ws0">E-mail ID : chen.jiyang@byd.com</div>
        //                 <div className="t m1 x9 h3 ya3 ff2 fs0 fc0 sc0 ls0 ws0">(No item should be left blank)</div>
        //             </div>
        //             <div className="c x2b ya4 w23 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Others (Specify)</div>
        //             </div>
        //             <div className="c x0 ya5 w26 h1b">
        //                 <div className="t m1 x9 h3 ya6 ff2 fs0 fc0 sc0 ls0 ws0">Details of foreign equity holding of the le<span
        //                     className="_ _7"></span>nder in </div>
        //                 <div className="t m1 x9 h3 ya7 ff2 fs0 fc0 sc0 ls0 ws0">the borrower company:</div>
        //                 <div className="t m1 x1a h3 ya8 ff2 fs0 fc0 sc0 ls0 ws0">(a)Share in paid-up equity of the borrower
        //                 </div>
        //                 <div className="t m1 x1a h3 ya9 ff2 fs0 fc0 sc0 ls0 ws0">100%</div>
        //             </div>
        //             <div className="c x2d ya5 w27 h1b">
        //                 <div className="t m1 x2e h3 yaa ff2 fs0 fc0 sc0 ls0 ws0">(b) Amount of paid-up capital</div>
        //                 <div className="t m1 x2e h3 yab ff2 fs0 fc0 sc0 ls0 ws0">2562804700</div>
        //             </div>
        //             <div className="c x0 yac w21 h10">
        //                 <div className="t m1 x9 h3 yad ff2 fs0 fc0 sc0 ls0 ws0">ECB-Liability: equity ratio in case of
        //                     borrowings above USD5millio<span className="_ _f"></span>n from fo<span
        //                         className="_ _f"></span>reign equity holder<span className="fs1">: </span>
        //                 </div>
        //                 <div className="t m1 x2f h3 yae ff2 fs1 fc0 sc0 ls0 ws0">189<span className="fs0">:1</span></div>
        //             </div>
        //             <div className="c x0 yaf w21 hd">
        //                 <div className="t m1 x30 h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Part C: Loan Details</div>
        //             </div>
        //             <div className="c x0 yb0 w28 h12">
        //                 <div className="t m1 x9 h2 y4a ff1 fs0 fc0 sc0 ls0 ws0">Loan Agreement Date</div>
        //                 <div className="t m1 x9 h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">(<span className="ff2">YYYY/MM/DD)</span></div>
        //             </div>
        //             <div className="c x31 yb0 w29 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x32 yb0 w2a h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x28 yb0 w2b h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x33 yb0 w2c h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">8</div>
        //             </div>
        //             <div className="c x34 yb0 w2d h12">
        //                 <div className="t m1 x35 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x36 yb0 w2e h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x37 yb0 w29 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //             </div>
        //             <div className="c x2f yb0 w2f h12">
        //                 <div className="t m1 x35 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x38 yb0 w30 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x39 yb0 w31 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x0 yb1 w28 hd">
        //                 <div className="t m1 x9 h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Effective Date of the Loan</div>
        //             </div>
        //             <div className="c x31 yb1 w29 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x32 yb1 w2a hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x28 yb1 w2b hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x33 yb1 w2c hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">8</div>
        //             </div>
        //             <div className="c x34 yb1 w2d hd">
        //                 <div className="t m1 x35 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x36 yb1 w2e hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x37 yb1 w29 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //             </div>
        //             <div className="c x2f yb1 w2f hd">
        //                 <div className="t m1 x35 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x38 yb1 w30 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x39 yb1 w31 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">3</div>
        //             </div>
        //             <div className="c x0 yb2 w28 he">
        //                 <div className="t m1 x9 h2 y41 ff1 fs0 fc0 sc0 ls0 ws0">Last Date of Disbursement</div>
        //             </div>
        //             <div className="c x31 yb2 w29 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x32 yb2 w2a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x28 yb2 w2b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x33 yb2 w2c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x34 yb2 w2d he">
        //                 <div className="t m1 x35 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x36 yb2 w2e he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x37 yb2 w29 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x2f yb2 w2f he">
        //                 <div className="t m1 x35 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x38 yb2 w30 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x39 yb2 w31 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">8</div>
        //             </div>
        //             <div className="c x0 yb3 w32 h1c">
        //                 <div className="t m1 x9 h2 yb4 ff1 fs0 fc0 sc0 ls0 ws0">Maturity Date <span className="ff2">(Last
        //                     payment</span></div>
        //                 <div className="t m1 x9 h3 yb5 ff2 fs0 fc0 sc0 ls0 ws0">date)</div>
        //             </div>
        //             <div className="c x31 yb3 w33 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x32 yb3 w34 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x28 yb3 w35 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x33 yb3 w36 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">8</div>
        //             </div>
        //             <div className="c x34 yb3 w37 h1d">
        //                 <div className="t m1 x35 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x36 yb3 w38 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">0</div>
        //             </div>
        //             <div className="c x37 yb3 w33 h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //             </div>
        //             <div className="c x2f yb3 w39 h1d">
        //                 <div className="t m1 x35 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x38 yb3 w3a h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x39 yb3 w3b h1d">
        //                 <div className="t m1 x17 h3 yb6 ff2 fs0 fc0 sc0 ls0 ws0">5</div>
        //             </div>
        //             <div className="c x0 yb7 w28 he">
        //                 <div className="t m1 x9 h2 y41 ff1 fs0 fc0 sc0 ls0 ws0">Grace Period <span className="ff2">(if in
        //                     agreement)</span></div>
        //             </div>
        //             <div className="c x31 yb7 w3c h1e">
        //                 <div className="t m1 x3a h3 yad ff2 fs0 fc0 sc0 ls0 ws0">Years</div>
        //             </div>
        //             <div className="c x36 yb7 w3d h1e">
        //                 <div className="t m1 x3b h3 yad ff2 fs0 fc0 sc0 ls0 ws0">Months</div>
        //             </div>
        //             <div className="c x0 yb8 w28 h1f">
        //                 <div className="t m1 x9 h2 yb9 ff1 fs0 fc0 sc0 ls0 ws0">Currency Name</div>
        //                 <div className="t m1 x9 h3 yba ff2 fs0 fc0 sc0 ls0 ws0">1. USD</div>
        //                 <div className="t m1 x9 h3 ybb ff2 fs0 fc0 sc0 ls0 ws0">2.</div>
        //                 <div className="t m1 x9 h2 ybc ff2 fs0 fc0 sc0 ls0 ws0">3<span className="ff1">.</span></div>
        //             </div>
        //             <div className="c x34 yb8 w3e h1f">
        //                 <div className="t m1 x9 h2 yb9 ff1 fs0 fc0 sc0 ls0 ws0">Currency Code <span className="ff2">(SWIFT)</span>
        //                 </div>
        //             </div>
        //             <div className="c x0 ybd w28 h20">
        //                 <div className="t m1 x9 h2 ybe ff1 fs0 fc0 sc0 ls0 ws0">Amount <span className="ff2">(in Foreign
        //                     Currency)</span></div>
        //                 <div className="t m1 x9 h3 ybf ff2 fs0 fc0 sc0 ls0 ws0">1. 20,000,000 USD</div>
        //                 <div className="t m1 x9 h3 yc0 ff2 fs0 fc0 sc0 ls0 ws0">2.</div>
        //             </div>
        //             <div className="t m5 x3c h3 yc1 ff2 fs0 fc0 sc0 ls0 ws0">17</div>
        //         </div>
        //     </div>
        //     <div id="pf4" className="pf w0 h0" data-page-no="4">
        //         <div className="pc pc4 w0 h0"><img className="bi x0 yc2 w3f h21" alt="" src={bg4} />
        //             <div className="c x0 y84 w28 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">3.</div>
        //             </div>
        //             <div className="c x0 yc3 w28 h20">
        //                 <div className="t m1 x9 h2 yc4 ff1 fs0 fc0 sc0 ls0 ws0">Equivalent Amount <span className="ff2">(in US
        //                 </span></div>
        //                 <div className="t m1 x9 h3 yc5 ff2 fs0 fc0 sc0 ls0 ws0">Dollars)</div>
        //                 <div className="t m1 x9 h22 yc6 ff6 fs0 fc0 sc0 ls0 ws0">(as on date of this form)</div>
        //             </div>
        //             <div className="c x31 yc3 w40 h20">
        //                 <div className="t m1 x17 h3 yc4 ff2 fs0 fc0 sc0 ls0 ws0"> 20,000,000</div>
        //             </div>
        //             <div className="c x0 yc7 w28 h12">
        //                 <div className="t m1 x9 h2 y4e ff1 fs0 fc0 sc0 ls0 ws0">Proposed Bifurcation of the </div>
        //                 <div className="t m1 x9 h2 yc8 ff1 fs0 fc0 sc0 ls0 ws0">amount</div>
        //             </div>
        //             <div className="c x31 yc7 w41 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Foreign Currency Expenditure</div>
        //             </div>
        //             <div className="c x27 yc7 w42 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Rupee Expenditure</div>
        //             </div>
        //             <div className="c x0 yc9 w43 h11">
        //                 <div className="t m1 x9 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">(in loan currency)</div>
        //             </div>
        //             <div className="c x31 yc9 w44 h11">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">USD 8,000,000</div>
        //             </div>
        //             <div className="c x27 yc9 w45 hd">
        //                 <div className="t m1 x17 h3 y48 ff2 fs0 fc0 sc0 ls0 ws0">USD 12,000,000</div>
        //             </div>
        //             <div className="c x0 yca w46 h12">
        //                 <div className="t m1 x9 h2 y4e ff1 fs0 fc0 sc0 ls0 ws0">Hedging details <span className="ff2">(Tick </span>
        //                 </div>
        //                 <div className="t m1 x9 h3 yc8 ff2 fs0 fc0 sc0 ls0 ws0">one)</div>
        //             </div>
        //             <div className="c x3d yca w47 h13">
        //                 <div className="t m1 x9 h3 ycb ff2 fs0 fc0 sc0 ls0 ws0">Currency Swap</div>
        //             </div>
        //             <div className="c x3e yca w48 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Interest Rate Swap</div>
        //             </div>
        //             <div className="c x3f yca w49 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Others</div>
        //             </div>
        //             <div className="c x40 yca w4a h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Unhedged</div>
        //                 <div className="t m1 x9 h3 ycc ff5 fs0 fc0 sc0 ls0 ws0">√</div>
        //             </div>
        //             <div className="c x0 ycd w46 h12">
        //                 <div className="t m1 x9 h2 y4a ff1 fs0 fc0 sc0 ls0 ws0">Hedging percentage</div>
        //                 <div className="t m1 x9 h2 y89 ff1 fs0 fc0 sc0 ls0 ws0">(proposed)</div>
        //             </div>
        //             <div className="c x3d ycd w47 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Financial Hedge </div>
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">0%</div>
        //             </div>
        //             <div className="c x3e ycd w48 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Natural Hedge</div>
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">0%</div>
        //             </div>
        //             <div className="c x3f ycd w49 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Total</div>
        //                 <div className="t m1 x9 h3 y89 ff2 fs0 fc0 sc0 ls0 ws0">Hedge</div>
        //             </div>
        //             <div className="c x40 ycd w4a h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">0%</div>
        //             </div>
        //             <div className="c x0 yce w4b h15">
        //                 <div className="t m1 x9 h2 y8b ff1 fs0 fc0 sc0 ls0 ws0">In case options are provided in the loan
        //                     agreement <span className="ff2">(tick in the appropriate box) NOT APPLICABLE</span></div>
        //             </div>
        //             <div className="c x0 ycf w4c h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Call Option</div>
        //             </div>
        //             <div className="c x3d ycf w47 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0"> per cent of</div>
        //                 <div className="t m1 x9 h3 y89 ff2 fs0 fc0 sc0 ls0 ws0">Debt</div>
        //             </div>
        //             <div className="c x3e ycf w48 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Can be executed after date</div>
        //             </div>
        //             <div className="c x41 ycf w4d h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x42 ycf w4e h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x0 yd0 w4c h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">Put Option</div>
        //             </div>
        //             <div className="c x3d yd0 w47 h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0"> per cent of</div>
        //                 <div className="t m1 x9 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Debt</div>
        //             </div>
        //             <div className="c x3e yd0 w48 h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">Can be executed after date</div>
        //             </div>
        //             <div className="c x41 yd0 w4d h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x42 yd0 w4e h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x0 yd2 w4b h23">
        //                 <div className="t m1 x9 h2 yd3 ff1 fs0 fc0 sc0 ls0 ws0">Name and address of the Guarantor <span
        //                     className="ff2">(in Block letters) </span>NOT APPLICABLE</div>
        //                 <div className="t m1 x9 h3 yd4 ff2 fs0 fc0 sc0 ls0 ws0">Contact Official’s Name:</div>
        //                 <div className="t m1 x9 h3 yd5 ff2 fs0 fc0 sc0 ls0 ws0">Designation :</div>
        //                 <div className="t m1 x9 h3 yd6 ff2 fs0 fc0 sc0 ls0 ws0">Phone No.: Fax No.: E-mail id: </div>
        //                 <div className="t m1 x9 h3 yd7 ff2 fs0 fc0 sc0 ls0 ws0">Guarantee Status Code:</div>
        //             </div>
        //             <div className="c x0 yd8 w4f h24">
        //                 <div className="t m1 x9 h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">Nature and details of security, if any </div>
        //                 <div className="t m1 x43 h2 ycc ff1 fs0 fc0 sc0 ls0 ws0">NOT APPLICABLE</div>
        //             </div>
        //             <div className="c x0 yd9 w4b h1f">
        //                 <div className="t m1 x9 h3 yb9 ff2 fs0 fc0 sc0 ls0 ws0">End-use <span className="ff6">(% share if more than
        //                     one end-use):</span></div>
        //                 <div className="t m1 x1a h2 yba ff1 fs0 fc0 sc0 ls0 ws0">(i)<span className="_ _1d"> </span>Borrowing
        //                     Purpose Code Amount Percent<span className="_ _f"></span>age : NP-USD 10,000,000-50% </div>
        //                 <div className="t m1 x1a h2 ybb ff2 fs0 fc0 sc0 ls0 ws0">(ii)<span className="_ _1e"> </span><span
        //                     className="ff1">Borrowing Purpose Code Amount Percent<span className="_ _f"></span>age : IC-USD
        //                     4,000,000-20% </span></div>
        //                 <div className="t m1 x1a h2 yda ff2 fs0 fc0 sc0 ls0 ws0">(iii)<span className="_ _1f"> </span><span
        //                     className="ff1">Borrowing Purpose Code Amount Percent<span className="_ _f"></span>age : RL- USD
        //                     6,000,000-30%</span></div>
        //             </div>
        //             <div className="c x0 ydb w4b h1c">
        //                 <div className="t m1 x9 h2 yd1 ff1 fs0 fc0 sc0 ls0 ws0">Project Details (Name, Location and Cost): For
        //                     setting up facility for manufacturing and as<span className="_ _f"></span>sembly of </div>
        //                 <div className="t m1 x9 h2 y4b ff1 fs0 fc0 sc0 ls0 ws0">Electric Vehicle and Capital Expenditure for
        //                     Electronic Division USD 20,000,000</div>
        //             </div>
        //             <div className="c x0 ydc w4b h12">
        //                 <div className="t m1 x9 h2 y4a ff1 fs0 fc0 sc0 ls0 ws0">If import<span className="ff2">, specify the Country
        //                     of import (if more than one country, attach details as Annex):</span></div>
        //             </div>
        //             <div className="c x0 ydd w50 h25">
        //                 <div className="t m1 x9 h2 yde ff1 fs0 fc0 sc0 ls0 ws0">Industry Code <span className="ff2">(as per
        //                     NIC-2008)</span></div>
        //             </div>
        //             <div className="c x44 ydd w51 h25">
        //                 <div className="t m1 x17 h2 y18 ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff1">461</span></div>
        //             </div>
        //             <div className="c x0 ydf w4b h26">
        //                 <div className="t m1 x9 h2 ye0 ff1 fs0 fc0 sc0 ls0 ws0">Type of ECB<span className="ff2">(Tick in
        //                     appropriate box)</span></div>
        //             </div>
        //             <div className="c x0 ye1 w52 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">1.Buyers’ Credit</div>
        //             </div>
        //             <div className="c x45 ye1 w53 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">2.Commercial Loan / Syndicated Loan</div>
        //                 <div className="t m1 x9 h3 y89 ff2 fs0 fc0 sc0 ls0 ws0">(attach sheet for percentage distribution among
        //                     lenders)</div>
        //             </div>
        //             <div className="c x39 ye1 w54 h12">
        //                 <div className="t m1 x17 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0"> <span className="ff5">√</span></div>
        //             </div>
        //             <div className="c x0 ye2 w52 h25">
        //                 <div className="t m1 x9 h3 yde ff2 fs0 fc0 sc0 ls0 ws0">3.Suppliers’ Credit</div>
        //             </div>
        //             <div className="c x45 ye2 w53 h25">
        //                 <div className="t m1 x9 h3 yde ff2 fs0 fc0 sc0 ls0 ws0">4.Export Credit from Bilateral Sources</div>
        //             </div>
        //             <div className="c x0 ye3 w52 h24">
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">5.Line of Credit</div>
        //             </div>
        //             <div className="c x45 ye3 w53 h24">
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">6. Securitized Instruments (Bonds, CP, FRN,
        //                     etc.)</div>
        //             </div>
        //             <div className="c x0 ye4 w52 h27">
        //                 <div className="t m1 x9 h3 ye5 ff2 fs0 fc0 sc0 ls0 ws0">7.Financial Lease</div>
        //             </div>
        //             <div className="c x45 ye4 w53 h27">
        //                 <div className="t m1 x9 h3 ye5 ff2 fs0 fc0 sc0 ls0 ws0">8.FCCB, FCEB, Non-Convertible Preference Shares,
        //                     O<span className="_ _f"></span>ptionally</div>
        //                 <div className="t m1 x9 h3 ye6 ff2 fs0 fc0 sc0 ls0 ws0">Convertible Preference Shares, Partially
        //                     Convertible Preference</div>
        //                 <div className="t m1 x9 h3 ye7 ff2 fs0 fc0 sc0 ls0 ws0">Shares</div>
        //             </div>
        //             <div className="c x0 ye8 w55 h28">
        //                 <div className="t m1 x9 h3 y50 ff2 fs0 fc0 sc0 ls0 ws0">9.Refinancing of old ECBs</div>
        //                 <div className="t m1 x46 h3 ye9 ff2 fs0 fc0 sc0 ls0 ws0">LRN of the old ECB: Approval No. Date of
        //                     Approval: </div>
        //                 <div className="t m1 x46 h3 y52 ff2 fs0 fc0 sc0 ls0 ws0">Amount refinanced: Reason:</div>
        //             </div>
        //             <div className="c x0 yea w56 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">10.Others</div>
        //                 <div className="t m1 x9 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">(Specify)</div>
        //             </div>
        //             <div className="t m5 x3c h3 yeb ff2 fs0 fc0 sc0 ls0 ws0">18</div>
        //         </div>
        //     </div>
        //     <div id="pf5" className="pf w0 h0" data-page-no="5">
        //         <div className="pc pc5 w0 h0"><img className="bi x0 yec w57 h29" alt="" src={bg5} />
        //             <div className="c xf y37 w5 hb">
        //                 <div className="t m1 x10 hc y38 ff2 fs1 fc0 sc0 ls0 ws0">20</div>
        //             </div>
        //             <div className="c x0 yed w58 h24">
        //                 <div className="t m1 x9 h2 y58 ff1 fs0 fc0 sc0 ls0 ws0">Interest Payment Schedule – <span
        //                     className="ff6">Interest Free loan</span></div>
        //             </div>
        //             <div className="c x0 yee w59 h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">First Payment</div>
        //                 <div className="t m1 x9 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Date</div>
        //             </div>
        //             <div className="c x47 yee w5a h1c">
        //                 <div className="t m1 x48 h2 yd1 ff1 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x2d yee w5b h1c">
        //                 <div className="t m1 x9 h2 yd1 ff1 fs0 fc0 sc0 ls0 ws0">/</div>
        //             </div>
        //             <div className="c x49 yee w5c h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">No. of payments/</div>
        //                 <div className="t m1 x4a h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">year</div>
        //             </div>
        //             <div className="c x0 yef w5d h24">
        //                 <div className="t m1 x9 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">Fixed Rate</div>
        //             </div>
        //             <div className="c x0 yf0 w5d h2a">
        //                 <div className="t m1 x9 h3 yf1 ff2 fs0 fc0 sc0 ls0 ws0">Floating Rate</div>
        //             </div>
        //             <div className="c x4b yf0 w5e h2b">
        //                 <div className="t m1 x9 h3 yf2 ff2 fs0 fc0 sc0 ls0 ws0">Base ith </div>
        //                 <div className="t m1 x9 h3 yf3 ff2 fs0 fc0 sc0 ls0 ws0">currency</div>
        //             </div>
        //             <div className="c x47 yf0 w5f h2b">
        //                 <div className="t m1 x9 h3 yf2 ff2 fs0 fc0 sc0 ls0 ws0">Margin</div>
        //             </div>
        //             <div className="c x34 yf0 w60 h2a">
        //                 <div className="t m1 x9 h3 yf1 ff2 fs0 fc0 sc0 ls0 ws0">Cap Rate</div>
        //             </div>
        //             <div className="c x2f yf0 w61 h2a">
        //                 <div className="t m1 x48 h3 yf1 ff2 fs0 fc0 sc0 ls0 ws0">Floor</div>
        //                 <div className="t m1 x9 h3 yf4 ff2 fs0 fc0 sc0 ls0 ws0">Rate</div>
        //             </div>
        //             <div className="c x0 yf5 w58 h10">
        //                 <div className="t m1 x9 h2 yf6 ff1 fs0 fc0 sc0 ls0 ws0">Drawdown Schedule</div>
        //             </div>
        //             <div className="c x4c yf7 w62 hd">
        //                 <div className="t m1 x4d h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">If more than one installment</div>
        //             </div>
        //             <div className="c x0 yf8 w63 h2c">
        //                 <div className="t m1 x4e h3 yf9 ff2 fs0 fc0 sc0 ls0 ws0">Tranche</div>
        //                 <div className="t m1 x14 h3 yfa ff2 fs0 fc0 sc0 ls0 ws0">No.</div>
        //             </div>
        //             <div className="c x4f yf8 w64 h2c">
        //                 <div className="t m1 x23 h3 yf9 ff2 fs0 fc0 sc0 ls0 ws0">Date*</div>
        //                 <div className="t m1 x2e h3 yfa ff2 fs0 fc0 sc0 ls0 ws0">(YYYY-MM-DD)</div>
        //             </div>
        //             <div className="c x2a yf8 w65 h2c">
        //                 <div className="t m1 x4e h3 yf9 ff2 fs0 fc0 sc0 ls0 ws0">Currency</div>
        //             </div>
        //             <div className="c x50 yf8 w66 h2c">
        //                 <div className="t m1 x51 h3 yf9 ff2 fs0 fc0 sc0 ls0 ws0">Amount</div>
        //             </div>
        //             <div className="c x4c yfb w67 h12">
        //                 <div className="t m1 x9 h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Total No. of drawals</div>
        //             </div>
        //             <div className="c x52 yfb w68 h12">
        //                 <div className="t m1 x9 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">No. of drawals in a </div>
        //                 <div className="t m1 x9 h3 yc8 ff2 fs0 fc0 sc0 ls0 ws0">calendar year</div>
        //             </div>
        //             <div className="c x0 yfc w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x4f yfc w64 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2018-04-23</div>
        //             </div>
        //             <div className="c x2a yfc w65 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 yfc w66 hd">
        //                 <div className="t m1 x53 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">5,000,000</div>
        //             </div>
        //             <div className="c x0 yfd w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x4f yfd w64 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2018-07-26</div>
        //             </div>
        //             <div className="c x2a yfd w65 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 yfd w66 he">
        //                 <div className="t m1 x54 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">800,000</div>
        //             </div>
        //             <div className="c x0 yfe w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">3</div>
        //             </div>
        //             <div className="c x4f yfe w64 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2018-09-20</div>
        //             </div>
        //             <div className="c x2a yfe w65 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 yfe w66 he">
        //                 <div className="t m1 x53 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1,200,000</div>
        //             </div>
        //             <div className="c x0 yff w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //             </div>
        //             <div className="c x4f yff w64 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2018-10-06</div>
        //             </div>
        //             <div className="c x2a yff w65 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 yff w66 he">
        //                 <div className="t m1 x53 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">3,000,000</div>
        //             </div>
        //             <div className="c x0 y100 w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">5</div>
        //             </div>
        //             <div className="c x4f y100 w64 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2020-02-13</div>
        //             </div>
        //             <div className="c x2a y100 w65 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 y100 w66 hd">
        //                 <div className="t m1 x53 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1,000,000</div>
        //             </div>
        //             <div className="c x0 y101 w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">6</div>
        //             </div>
        //             <div className="c x4f y101 w64 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2020-10-28</div>
        //             </div>
        //             <div className="c x2a y101 w65 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 y101 w66 hd">
        //                 <div className="t m1 x53 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">5,000,000</div>
        //             </div>
        //             <div className="c x0 y102 w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">7</div>
        //             </div>
        //             <div className="c x4f y102 w64 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2021-06-25</div>
        //             </div>
        //             <div className="c x2a y102 w65 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 y102 w66 hd">
        //                 <div className="t m1 x53 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1,000,000</div>
        //             </div>
        //             <div className="c x0 y103 w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">8</div>
        //             </div>
        //             <div className="c x4f y103 w64 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2021-12-08</div>
        //             </div>
        //             <div className="c x2a y103 w65 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x50 y103 w66 hd">
        //                 <div className="t m1 x53 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">3,000,000</div>
        //             </div>
        //             <div className="c x0 y104 w58 h2d">
        //                 <div className="t m1 x9 h3 y105 ff2 fs0 fc0 sc0 ls0 ws0">* 1.In case of import of goods or services,
        //                     date of import is to be furnished against date of </div>
        //                 <div className="t m1 x9 h3 y106 ff2 fs0 fc0 sc0 ls0 ws0">drawdown.</div>
        //                 <div className="t m1 x3a h3 y107 ff2 fs0 fc0 sc0 ls0 ws0">2. In case of financial lease, date of
        //                     acquisition (import) of the goods is to be mentioned as date of </div>
        //                 <div className="t m1 x9 h3 y108 ff2 fs0 fc0 sc0 ls0 ws0">drawdown.</div>
        //                 <div className="t m1 x3a h3 y109 ff2 fs0 fc0 sc0 ls0 ws0">3. In case securitised instrume<span
        //                     className="_ _7"></span>nts, date of issue has to be shown as date of drawdown.</div>
        //                 <div className="t m1 x3a h3 y10a ff2 fs0 fc0 sc0 ls0 ws0">4. In case of more than one equal drawdown
        //                     transactions are shown in a row, the first date of </div>
        //                 <div className="t m1 x55 h3 y10b ff2 fs0 fc0 sc0 ls0 ws0">transaction should be mentioned.</div>
        //             </div>
        //             <div className="c x0 y10c w58 h25">
        //                 <div className="t m1 x9 h2 yde ff1 fs0 fc0 sc0 ls0 ws0">Principal Repayment Schedule</div>
        //             </div>
        //             <div className="c x56 y10d w69 h24">
        //                 <div className="t m1 x57 h3 y58 ff2 fs0 fc0 sc0 ls0 ws0">If more than one installment</div>
        //             </div>
        //             <div className="c x0 y10e w5d h2e">
        //                 <div className="t m1 x54 h3 y10f ff2 fs0 fc0 sc0 ls0 ws0">Date</div>
        //                 <div className="t m1 x9 h3 y110 ff2 fs0 fc0 sc0 ls0 ws0">(YYYY-MM-DD)</div>
        //             </div>
        //             <div className="c x4b y10e w6a h2e">
        //                 <div className="t m1 x4e h3 y10f ff2 fs0 fc0 sc0 ls0 ws0">Currency</div>
        //             </div>
        //             <div className="c x30 y10e w6b h2e">
        //                 <div className="t m1 x51 h3 y10f ff2 fs0 fc0 sc0 ls0 ws0">Amount</div>
        //             </div>
        //             <div className="c x56 y10e w6c h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">Total No. of</div>
        //                 <div className="t m1 x9 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Payments</div>
        //             </div>
        //             <div className="c x58 y10e w6d h1c">
        //                 <div className="t m1 x9 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">No. of payments in a calendar year</div>
        //             </div>
        //             <div className="c x0 y111 w5d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2024-04-15</div>
        //             </div>
        //             <div className="c x4b y111 w6a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x30 y111 w6b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">4,000,000</div>
        //             </div>
        //             <div className="c x56 y111 w6c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x58 y111 w6d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x0 y112 w5d hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2025-04-15</div>
        //             </div>
        //             <div className="c x4b y112 w6a hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x30 y112 w6b hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">4,000,000</div>
        //             </div>
        //             <div className="c x56 y112 w6c hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x58 y112 w6d hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x0 y113 w5d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2026-04-15</div>
        //             </div>
        //             <div className="c x4b y113 w6a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x30 y113 w6b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">4,000,000</div>
        //             </div>
        //             <div className="c x56 y113 w6c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x58 y113 w6d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x0 y114 w5d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2027-04-15</div>
        //             </div>
        //             <div className="c x4b y114 w6a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x30 y114 w6b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">4,000,000</div>
        //             </div>
        //             <div className="c x56 y114 w6c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x58 y114 w6d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x0 y115 w5d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2028-04-15</div>
        //             </div>
        //             <div className="c x4b y115 w6a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x30 y115 w6b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">4,000,000</div>
        //             </div>
        //             <div className="c x56 y115 w6c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x58 y115 w6d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x0 y116 w58 he">
        //                 <div className="t m1 x59 h2 y41 ff1 fs0 fc0 sc0 ls0 ws0">Part D: Other Charges</div>
        //             </div>
        //             <div className="c x34 y117 w6e h25">
        //                 <div className="t m1 x1a h3 yde ff2 fs0 fc0 sc0 ls0 ws0">In case of multiple equal payments</div>
        //             </div>
        //             <div className="c x0 y118 w6f h2f">
        //                 <div className="t m1 x9 h3 y119 ff2 fs0 fc0 sc0 ls0 ws0">Nature of charge</div>
        //             </div>
        //             <div className="c x5a y118 w70 h2f">
        //                 <div className="t m1 x2e h3 y119 ff2 fs0 fc0 sc0 ls0 ws0">Expected</div>
        //                 <div className="t m1 x35 h3 y11a ff2 fs0 fc0 sc0 ls0 ws0">Date of</div>
        //                 <div className="t m1 x2e h3 y11b ff2 fs0 fc0 sc0 ls0 ws0">Payment</div>
        //             </div>
        //             <div className="c x5b y118 w71 h2f">
        //                 <div className="t m1 x9 h3 y119 ff2 fs0 fc0 sc0 ls0 ws0">Currency</div>
        //             </div>
        //             <div className="c x56 y118 w72 h2f">
        //                 <div className="t m1 x46 h3 y119 ff2 fs0 fc0 sc0 ls0 ws0">Amount</div>
        //             </div>
        //             <div className="c x34 y118 w73 h12">
        //                 <div className="t m1 x4e h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">No. of payments in a</div>
        //                 <div className="t m1 x5c h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Year</div>
        //             </div>
        //             <div className="c x52 y118 w68 h12">
        //                 <div className="t m1 x1a h3 y4a ff2 fs0 fc0 sc0 ls0 ws0">Total no. of</div>
        //                 <div className="t m1 x55 h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">payments</div>
        //             </div>
        //             <div className="c x0 y11c w74 hd">
        //                 <div className="t m1 x15 h3 y18 ff2 fs0 fc0 sc0 ls0 ws0">Upfront fee</div>
        //             </div>
        //             <div className="c x0 y11d w74 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Management fee</div>
        //             </div>
        //             <div className="c x0 y11e w74 h1c">
        //                 <div className="t m1 x51 h3 yd1 ff2 fs0 fc0 sc0 ls0 ws0">Commitment</div>
        //                 <div className="t m1 x5d h3 y4b ff2 fs0 fc0 sc0 ls0 ws0">Fees</div>
        //             </div>
        //             <div className="c x0 y11f w74 he">
        //                 <div className="t m1 x3a h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Guarantee fees</div>
        //             </div>
        //             <div className="c x0 y120 w74 hd">
        //                 <div className="t m1 x14 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">ECA charges</div>
        //             </div>
        //             <div className="c x5a y121 w75 h30">
        //                 <div className="t m1 x59 h3 y122 ff2 fs0 fc0 sc0 ls0 ws0">NIL</div>
        //             </div>
        //         </div>
        //     </div>
        //     <div id="pf6" className="pf w0 h0" data-page-no="6">
        //         <div className="pc pc6 w0 h0"><img className="bi x0 y123 w57 h31" alt="" src={bg6} />
        //             <div className="c xf y37 w5 hb">
        //                 <div className="t m1 x10 hc y38 ff2 fs1 fc0 sc0 ls0 ws0">21</div>
        //             </div>
        //             <div className="c x0 y124 w74 he">
        //                 <div className="t m1 x23 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Others</div>
        //             </div>
        //             <div className="c x0 y125 w74 h32">
        //                 <div className="t m1 x5e h3 y126 ff2 fs0 fc0 sc0 ls0 ws0">Total</div>
        //             </div>
        //             <div className="c x5f y127 w76 h33">
        //                 <div className="t m1 x9 h3 y128 ff2 fs0 fc0 sc0 ls0 ws0">Penal Interest for late </div>
        //                 <div className="t m1 x9 h3 y129 ff2 fs0 fc0 sc0 ls0 ws0">payments</div>
        //             </div>
        //             <div className="c x60 y127 w77 h33">
        //                 <div className="t m1 x9 h3 y128 ff2 fs0 fc0 sc0 ls0 ws0">Fixed 2 % or Base: Margin:</div>
        //             </div>
        //             <div className="c x5f y12a w76 h32">
        //                 <div className="t m1 x9 h3 y126 ff2 fs0 fc0 sc0 ls0 ws0">Commitment Charges</div>
        //             </div>
        //             <div className="c x60 y12a w77 h32">
        //                 <div className="t m1 x61 h3 y126 ff2 fs0 fc0 sc0 ls0 ws0">% per annum of: % of Undrawn Amount:</div>
        //             </div>
        //             <div className="c x0 y12b w12 hd">
        //                 <div className="t m1 xb h2 y3f ff1 fs0 fc0 sc0 ls0 ws0">Part E: Details of ECB already availed<span
        //                     className="ff2">(not applicable for the first-time borrower)</span></div>
        //             </div>
        //             <div className="c x62 y12c w78 he">
        //                 <div className="t m1 x63 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">Amount of Loan</div>
        //             </div>
        //             <div className="c x0 y12d w63 h34">
        //                 <div className="t m1 x9 h3 y12e ff2 fs0 fc0 sc0 ls0 ws0">Year</div>
        //             </div>
        //             <div className="c x4f y12d w79 h34">
        //                 <div className="t m1 x9 h3 y12f ff2 fs0 fc0 sc0 ls0 ws0">Loan Reg.No. </div>
        //                 <div className="t m1 x9 h3 y130 ff2 fs0 fc0 sc0 ls0 ws0">(LRN)</div>
        //             </div>
        //             <div className="c x64 y12d w7a h34">
        //                 <div className="t m1 x9 h3 y12e ff2 fs0 fc0 sc0 ls0 ws0">Currency</div>
        //             </div>
        //             <div className="c x62 y12d w7b h20">
        //                 <div className="t m1 x15 h3 ybe ff2 fs0 fc0 sc0 ls0 ws0">Principal</div>
        //                 <div className="t m1 x1a h3 ybf ff2 fs0 fc0 sc0 ls0 ws0">(as per </div>
        //                 <div className="t m1 x65 h3 yc0 ff2 fs0 fc0 sc0 ls0 ws0">agreement)</div>
        //             </div>
        //             <div className="c x4c y12d w7c h20">
        //                 <div className="t m1 x3b h3 ybe ff2 fs0 fc0 sc0 ls0 ws0">Disbursed so far</div>
        //             </div>
        //             <div className="c x66 y12d w7d h20">
        //                 <div className="t m1 x46 h3 ybe ff2 fs0 fc0 sc0 ls0 ws0">Net outstanding</div>
        //                 <div className="t m1 x23 h3 ybf ff2 fs0 fc0 sc0 ls0 ws0">(Principal)</div>
        //             </div>
        //             <div className="c x0 y131 w63 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2008</div>
        //             </div>
        //             <div className="c x4f y131 w79 hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">2008564</div>
        //             </div>
        //             <div className="c x64 y131 w7a hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x62 y131 w7b hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">20,000,000</div>
        //             </div>
        //             <div className="c x4c y131 w7c hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">10,945,400</div>
        //             </div>
        //             <div className="c x66 y131 w7d hd">
        //                 <div className="t m1 x17 h3 y44 ff2 fs0 fc0 sc0 ls0 ws0">5,945,400</div>
        //             </div>
        //             <div className="c x0 y132 w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2021</div>
        //             </div>
        //             <div className="c x4f y132 w79 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">202101109</div>
        //             </div>
        //             <div className="c x64 y132 w7a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">USD</div>
        //             </div>
        //             <div className="c x62 y132 w7b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">50,000,000</div>
        //             </div>
        //             <div className="c x4c y132 w7c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">10,000,000</div>
        //             </div>
        //             <div className="c x66 y132 w7d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">10,000,000</div>
        //             </div>
        //             <div className="c x0 y133 w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2021</div>
        //             </div>
        //             <div className="c x4f y133 w79 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">202112161</div>
        //             </div>
        //             <div className="c x64 y133 w7a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">INR</div>
        //             </div>
        //             <div className="c x62 y133 w7b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1,000,000,000</div>
        //             </div>
        //             <div className="c x4c y133 w7c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">900,000,000</div>
        //             </div>
        //             <div className="c x66 y133 w7d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">900,000,000</div>
        //             </div>
        //             <div className="c x0 y134 w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2022</div>
        //             </div>
        //             <div className="c x4f y134 w79 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">202201108</div>
        //             </div>
        //             <div className="c x64 y134 w7a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">INR</div>
        //             </div>
        //             <div className="c x62 y134 w7b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">1,000,000,000</div>
        //             </div>
        //             <div className="c x4c y134 w7c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">640,000,000</div>
        //             </div>
        //             <div className="c x66 y134 w7d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">640,000,000</div>
        //             </div>
        //             <div className="c x0 y135 w63 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2022</div>
        //             </div>
        //             <div className="c x4f y135 w79 he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">202203163</div>
        //             </div>
        //             <div className="c x64 y135 w7a he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">INR</div>
        //             </div>
        //             <div className="c x62 y135 w7b he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">3,000,000,000</div>
        //             </div>
        //             <div className="c x4c y135 w7c he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2,000,000,000</div>
        //             </div>
        //             <div className="c x66 y135 w7d he">
        //                 <div className="t m1 x17 h3 y42 ff2 fs0 fc0 sc0 ls0 ws0">2,000,000,000</div>
        //             </div>
        //             <div className="t m1 x5f h3 y136 ff2 fs0 fc0 sc0 ls0 ws0">We <span className="_ _f"></span>hereby <span
        //                 className="_ _f"></span>certify that the particulars <span
        //                     className="_ _5"></span>given above <span className="_ _f"></span>are <span className="_ _f"></span>true
        //                 <span className="_ _f"></span>and correct to the <span
        //                     className="_ _5"></span>best of <span className="_ _f"></span>our <span className="_ _f"></span>knowledge
        //                 and
        //             </div>
        //             <div className="t m1 x5f h3 y137 ff2 fs0 fc0 sc0 ls0 ws0">belief and <span
        //                 className="_ _f"></span>no material <span className="_ _f"></span>information
        //                 <span className="_ _f"></span>has been <span className="_ _f"></span>withheld
        //                 <span className="_ _f"></span>and/or <span className="_ _f"></span>misrepresented. <span
        //                     className="_ _5"></span>Furthermore, <span className="_ _f"></span>the ECB
        //                 is in
        //             </div>
        //             <div className="t m1 x5f h3 y138 ff2 fs0 fc0 sc0 ls0 ws0">compliance with <span
        //                 className="_ _2"></span>the extant ECB <span
        //                     className="_ _2"></span>guidelines and the <span
        //                         className="_ _2"></span>ECB to be <span
        //                             className="_ _2"></span>raised will be <span
        //                                 className="_ _2"></span>utilised for <span className="_ _f"></span>permitted
        //                 purposes.
        //             </div>
        //             <div className="t m1 x5f h3 y139 ff2 fs0 fc0 sc0 ls0 ws0">Place: China</div>
        //             <div className="t m1 x67 h3 y13a ff2 fs0 fc0 sc0 ls0 ws0">(Signature of the Authorised Official of the
        //                 Company with stamp)</div>
        //             <div className="t m1 x5f h3 y13b ff2 fs0 fc0 sc0 ls0 ws0">Date: 2023/04/13<span className="_ _20"> </span>Name:
        //                 FAN YUHUAN<span className="_ _21"> </span>Designation: FINANCE MANAGER</div>
        //             <div className="t m1 x67 h3 y13c ff2 fs0 fc0 sc0 ls0 ws0">Phone No. Fax<span className="_ _22"> </span> </div>
        //             <div className="t m1 x67 h3 y13d ff2 fs0 fc0 sc0 ls0 ws0">E-mail: fan.yuhuan@byd.com</div>
        //         </div>
        //     </div>
        //     <div id="pf7" className="pf w0 h0" data-page-no="7">
        //         <div className="pc pc7 w0 h0"><img className="bi x68 y13e w7e h35" alt="" src={bg7} />
        //             <div className="c xf y37 w5 hb">
        //                 <div className="t m1 x10 hc y38 ff2 fs1 fc0 sc0 ls0 ws0">22</div>
        //             </div>
        //             <div className="t m1 x69 h2 y13f ff1 fs0 fc0 sc0 ls0 ws0">Summary Sheet (SS) for Form ECB</div>
        //             <div className="t m1 x68 h3 y140 ff2 fs0 fc0 sc0 ls0 ws0">We have scrutinized the related documents and
        //                 confirm the following:</div>
        //             <div className="c xc y141 w7f h1f">
        //                 <div className="t m1 x9 h3 y142 ff2 fs0 fc0 sc0 ls0 ws0">Permissible </div>
        //                 <div className="t m1 x9 h3 y143 ff2 fs0 fc0 sc0 ls0 ws0">under </div>
        //                 <div className="t m1 x9 h3 y144 ff2 fs0 fc0 sc0 ls0 ws0">Automatic </div>
        //                 <div className="t m1 x9 h3 y145 ff2 fs0 fc0 sc0 ls0 ws0">Route</div>
        //             </div>
        //             <div className="c x1d y141 w80 h1f">
        //                 <div className="t m1 x9 h3 y142 ff2 fs0 fc0 sc0 ls0 ws0">Approved <span className="_ _23"> </span>by <span
        //                     className="_ _23"> </span>Foreign </div>
        //                 <div className="t m1 x9 h3 y143 ff2 fs0 fc0 sc0 ls0 ws0">Exchange <span className="_ _23">
        //                 </span>Department, </div>
        //                 <div className="t m1 x9 h3 y144 ff2 fs0 fc0 sc0 ls0 ws0">RBI <span className="_ _24"> </span>under <span
        //                     className="_ _24"> </span>Approval </div>
        //                 <div className="t m1 x9 h3 y145 ff2 fs0 fc0 sc0 ls0 ws0">Route</div>
        //             </div>
        //             <div className="c x5f y146 w81 h36">
        //                 <div className="t m1 x9 h3 y147 ff2 fs0 fc0 sc0 ls0 ws0">1</div>
        //             </div>
        //             <div className="c x5 y146 w82 h36">
        //                 <div className="t m1 x9 h3 y147 ff2 fs0 fc0 sc0 ls0 ws0">End-use</div>
        //                 <div className="t m1 x9 h22 y148 ff6 fs0 fc0 sc0 ls0 ws0">(%share if more than one </div>
        //                 <div className="t m1 x9 h22 y149 ff6 fs0 fc0 sc0 ls0 ws0">end-use)</div>
        //             </div>
        //             <div className="c x6a y146 w83 h36">
        //                 <div className="t m1 x35 h3 y14a ff2 fs2 fc0 sc0 ls0 ws0">1.<span className="_ _25"> </span><span
        //                     className="fs0">NP-50%</span></div>
        //                 <div className="t m1 x35 h3 y14b ff2 fs2 fc0 sc0 ls0 ws0">2.<span className="_ _25"> </span><span
        //                     className="fs0">IC-20%</span></div>
        //                 <div className="t m1 x35 h3 y14c ff2 fs2 fc0 sc0 ls0 ws0">3.<span className="_ _25"> </span><span
        //                     className="fs0">RL-30%</span></div>
        //             </div>
        //             <div className="c xc y14d w7f h37">
        //                 <div className="t m6 x6b h38 y14e ff7 fs3 fc0 sc0 ls0 ws0"></div>
        //             </div>
        //             <div className="c x6a y14f w84 h15">
        //                 <div className="t m1 x4a h3 y5a ff2 fs0 fc0 sc0 ls0 ws0">Years</div>
        //             </div>
        //             <div className="c x1d y14f w80 hd">
        //                 <div className="t m1 x5c h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">Months</div>
        //             </div>
        //             <div className="c x5f y150 w81 h39">
        //                 <div className="t m1 x9 h3 y151 ff2 fs0 fc0 sc0 ls0 ws0">2</div>
        //             </div>
        //             <div className="c x5 y150 w82 h39">
        //                 <div className="t m1 x9 h3 y151 ff2 fs0 fc0 sc0 ls0 ws0">Average Maturity</div>
        //             </div>
        //             <div className="c x6a y150 w85 h3a">
        //                 <div className="t m1 x6c h3 y152 ff2 fs0 fc0 sc0 ls0 ws0">6</div>
        //             </div>
        //             <div className="c x1d y150 w86 he">
        //                 <div className="t m1 x0 h3 y152 ff2 fs0 fc0 sc0 ls0 ws0">10</div>
        //             </div>
        //             <div className="c xc y153 w87 h15">
        //                 <div className="t m1 xe h3 y8b ff2 fs0 fc0 sc0 ls0 ws0">Floating Rate Loan</div>
        //             </div>
        //             <div className="c x5 y154 w82 h2c">
        //                 <div className="t m1 x9 h3 y155 ff2 fs0 fc0 sc0 ls0 ws0">Cost Factors (%)</div>
        //             </div>
        //             <div className="c x6a y154 w83 h2c">
        //                 <div className="t m1 x9 h3 y156 ff2 fs0 fc0 sc0 ls0 ws0">Fixed Rate</div>
        //                 <div className="t m1 x9 h3 y157 ff2 fs0 fc0 sc0 ls0 ws0">Loan</div>
        //             </div>
        //             <div className="c xc y158 w88 h12">
        //                 <div className="t m1 x9 h3 y4e ff2 fs0 fc0 sc0 ls0 ws0">Margin (spread) over </div>
        //                 <div className="t m1 x9 h3 yc8 ff2 fs0 fc0 sc0 ls0 ws0">base</div>
        //             </div>
        //             <div className="c x6d y158 w89 h12">
        //                 <div className="t m1 x6e h3 y159 ff2 fs0 fc0 sc0 ls0 ws0">Base</div>
        //             </div>
        //             <div className="c x5 y15a w82 hd">
        //                 <div className="t m1 x9 h3 y3f ff2 fs0 fc0 sc0 ls0 ws0">a) Interest Rate</div>
        //             </div>
        //             <div className="c x5f y15b w81 h3b">
        //                 <div className="t m1 x9 h3 y15c ff2 fs0 fc0 sc0 ls0 ws0">3</div>
        //             </div>
        //             <div className="c x5 y15d w82 he">
        //                 <div className="t m1 x9 h3 y41 ff2 fs0 fc0 sc0 ls0 ws0">b) All-in-cost</div>
        //             </div>
        //             <div className="c x6a y15d w8a h39">
        //                 <div className="t m1 x6f h3 y15e ff2 fs0 fc0 sc0 ls0 ws0">NIL ( interest free loan)</div>
        //             </div>
        //             <div className="c x5f y15f w81 h1b">
        //                 <div className="t m1 x9 h3 yaa ff2 fs0 fc0 sc0 ls0 ws0">4</div>
        //             </div>
        //             <div className="c x5 y15f w8b h23">
        //                 <div className="t m1 x9 h3 y160 ff2 fs0 fc0 sc0 ls0 ws0">In <span className="_ _f"></span>case <span
        //                     className="_ _f"></span>of loan <span className="_ _f"></span>from <span
        //                         className="_ _f"></span>‘Foreign Equity <span
        //                             className="_ _f"></span>Holder’, <span className="_ _f"></span>it <span className="_ _f"></span>is <span
        //                                 className="_ _5"></span>confirmed that <span
        //                                     className="_ _f"></span>ECB liability: <span
        //                                         className="_ _5"></span>Equity ratio </div>
        //                 <div className="t m1 x9 h3 y161 ff2 fs0 fc0 sc0 ls0 ws0">(7:1) criteria is satisfied. Further, in <span
        //                     className="_ _f"></span>case of working capital/general corporate purpose/ </div>
        //                 <div className="t m1 x9 h3 y162 ff2 fs0 fc0 sc0 ls0 ws0">repayment of <span
        //                     className="_ _3"></span>rupee loans e<span
        //                         className="_ _7"></span>nd-use, it is <span
        //                             className="_ _4"></span>confirm<span className="_ _f"></span>ed that <span
        //                                 className="_ _4"></span>equity holding of
        //                     lender is <span
        //                         className="_ _4"></span>at
        //                 </div>
        //                 <div className="t m1 x9 h3 y163 ff2 fs0 fc0 sc0 ls0 ws0">least 25 <span
        //                     className="_ _3"></span>percent (direct)/51 <span
        //                         className="_ _7"></span>percent (indirect) <span
        //                             className="_ _3"></span>of the <span
        //                                 className="_ _7"></span>paid-up equity <span
        //                                     className="_ _7"></span>or the lender <span
        //                                         className="_ _4"></span>is a </div>
        //                 <div className="t m1 x9 h3 y164 ff2 fs0 fc0 sc0 ls0 ws0">group company with common overseas parent. No
        //                 </div>
        //             </div>
        //             <div className="c x5f y165 w8c h20">
        //                 <div className="t m1 x9 h3 y166 ff2 fs0 fc0 sc0 ls0 ws0">5</div>
        //             </div>
        //             <div className="c x5 y165 w8d h3c">
        //                 <div className="t m1 x9 h3 y166 ff2 fs0 fc0 sc0 ls0 ws0">Borrower has given written undertaking to AD to
        //                     the effect that it</div>
        //                 <div className="t m1 x9 h3 y167 ff2 fs0 fc0 sc0 ls0 ws0">
        //                     hasbeensubmittingECB-2ReturnsregularlytoRBIinrespectof past </div>
        //                 <div className="t m1 x9 h3 y168 ff2 fs0 fc0 sc0 ls0 ws0">ECB/FCCB loans)</div>
        //             </div>
        //             <div className="c x6d y165 w8e h20">
        //                 <div className="t m1 x4e h22 y166 ff6 fs0 fc0 sc0 ls0 ws0">Yes</div>
        //             </div>
        //             <div className="c x5f y169 w81 h3d">
        //                 <div className="t m1 x9 h3 y16a ff2 fs0 fc0 sc0 ls0 ws0">6</div>
        //             </div>
        //             <div className="c x5 y169 w82 h3d">
        //                 <div className="t m1 x9 h3 y16a ff2 fs0 fc0 sc0 ls0 ws0">Security provided, if any</div>
        //             </div>
        //             <div className="c x6a y169 w8a h3e">
        //                 <div className="t m1 x17 h3 y16b ff2 fs0 fc0 sc0 ls0 ws0">None</div>
        //             </div>
        //             <div className="c x5f y16c w81 h3f">
        //                 <div className="t m1 x9 h3 y16d ff2 fs0 fc0 sc0 ls0 ws0">7</div>
        //             </div>
        //             <div className="c x5 y16c w82 h3f">
        //                 <div className="t m1 x9 h3 y16e ff2 fs0 fc0 sc0 ls0 ws0">Other <span className="_ _26"> </span>important
        //                     <span className="_ _26"> </span>facts
        //                 </div>
        //                 <div className="t m1 x9 h3 y16f ff2 fs0 fc0 sc0 ls0 ws0">relevant <span className="_ _a"> </span>fo<span
        //                     className="_ _f"></span>r <span className="_ _a"> </span>the allotment
        //                 </div>
        //                 <div className="t m1 x9 h3 y170 ff2 fs0 fc0 sc0 ls0 ws0">of LRN</div>
        //             </div>
        //             <div className="t m1 x68 h3 y171 ff2 fs0 fc0 sc0 ls0 ws0">Place : Chennai</div>
        //             <div className="t m1 x68 h3 y172 ff2 fs0 fc0 sc0 ls0 ws0">Date: 202<span className="_ _f"></span>3/04/13</div>
        //             <div className="t m1 x70 h3 y173 ff2 fs0 fc0 sc0 ls0 ws0">Name: Dharmaraj P, Chartered Accountant</div>
        //             <div className="t m1 x70 h3 y174 ff2 fs0 fc0 sc0 ls0 ws0"> Registration No.:224216</div>
        //             <div className="t m1 x0 h3 y175 ff2 fs0 fc0 sc0 ls0 ws0">We certify <span
        //                 className="_ _7"></span>that the borrower is
        //                 our customer and <span
        //                     className="_ _7"></span>the particulars given in
        //                 this Form are <span
        //                     className="_ _7"></span>true and correct
        //             </div>
        //             <div className="t m1 x0 h3 y176 ff2 fs0 fc0 sc0 ls0 ws0">to the <span
        //                 className="_ _4"></span>best of our <span
        //                     className="_ _3"></span>knowledge and belief.
        //                 We have scrutinized
        //                 the application and
        //                 the original letter
        //                 of
        //             </div>
        //             <div className="t m1 x0 h3 y177 ff2 fs0 fc0 sc0 ls0 ws0">offer from the <span
        //                 className="_ _7"></span>lender/supplier and documents relating to proposed
        //                 borrowing and found the same to be </div>
        //             <div className="t m1 x0 h3 y178 ff2 fs0 fc0 sc0 ls0 ws0">in order. This
        //                 application complies with the extant <span
        //                     className="_ _7"></span>ECB guidelines and we <span
        //                         className="_ _7"></span>recommend it for allotment <span
        //                             className="_ _7"></span>of </div>
        //             <div className="t m1 x0 h3 y179 ff2 fs0 fc0 sc0 ls0 ws0">Loan Registration Number (LRN) by RBI.</div>
        //             <div className="t m1 x68 h3 y17a ff2 fs0 fc0 sc0 ls0 ws0">Place:</div>
        //             <div className="t m1 x68 h3 y17b ff2 fs0 fc0 sc0 ls0 ws0">Date: </div>
        //             <div className="t m1 x67 h3 y17c ff2 fs0 fc0 sc0 ls0 ws0">(Signature of the Authorised Official with stamp)
        //             </div>
        //             <div className="t m1 x67 h3 y17d ff2 fs0 fc0 sc0 ls0 ws0">Name: Designation: <span className="_ _27"> </span>
        //             </div>
        //             <div className="t m1 x67 h3 y17e ff2 fs0 fc0 sc0 ls0 ws0">Name of the bank/ branch <span className="_ _28">
        //             </span> </div>
        //             <div className="t m1 x67 h3 y17f ff2 fs0 fc0 sc0 ls0 ws0">AD Code (Part Iand PartII):</div>
        //             <div className="t m1 x21 h3 y180 ff2 fs0 fc0 sc0 ls0 ws0">Tel.No.:_Fax </div>
        //             <div className="t m1 x67 h3 y181 ff2 fs0 fc0 sc0 ls0 ws0">No.</div>
        //             <div className="t m1 x21 h3 y182 ff2 fs0 fc0 sc0 ls0 ws0">e-mail:</div>
        //         </div>
        //     </div>
        //     <div id="pf8" className="pf w0 h0" data-page-no="8">
        //         <div className="pc pc8 w0 h0"><img className="bi x71 y183 w8f h40" alt="" src={bg8} />
        //             <div className="c xf y37 w5 hb">
        //                 <div className="t m1 x10 hc y38 ff2 fs1 fc0 sc0 ls0 ws0">23</div>
        //             </div>
        //             <div className="t m1 x68 h2 y184 ff1 fs0 fc0 sc0 ls0 ws0">For RBI(DSIM)Use only</div>
        //             <div className="c x64 y185 w90 h41">
        //                 <div className="t m1 x3a h3 y186 ff2 fs0 fc0 sc0 ls0 ws0">Received on</div>
        //             </div>
        //             <div className="c x72 y185 w91 h41">
        //                 <div className="t m1 x35 h3 y186 ff2 fs0 fc0 sc0 ls0 ws0">Action Taken </div>
        //                 <div className="t m1 x3a h3 y187 ff2 fs0 fc0 sc0 ls0 ws0">on</div>
        //             </div>
        //             <div className="c x73 y185 w92 h41">
        //                 <div className="t m1 x65 h3 y186 ff2 fs0 fc0 sc0 ls0 ws0">Loan Classification</div>
        //             </div>
        //             <div className="c x71 y188 w93 h42">
        //                 <div className="t m1 x3a h3 y189 ff2 fs0 fc0 sc0 ls0 ws0">RBI Team</div>
        //             </div>
        //             <div className="c x71 y18a w93 h43">
        //                 <div className="t m1 x3a h3 y18b ff2 fs0 fc0 sc0 ls0 ws0">LRN (if allotted)</div>
        //             </div>
        //         </div>
        //     </div>
        // </div>

        <div ref={ref}>
            <div className="pdf_design">
                <div className="py-4 main_content">
                    <h6 className="fw-600 text-center">Form ECB</h6>
                    <p className="text-center">(ApplicationandReportingofloanagreementdetailsunderForeignExchangeManagementAct, 1999)</p>
                </div>
                <div className="instructions px-5 d-flex justify-content-center">
                    <div>
                        <p>1. AlldatesshouldbeintheformatYYYY/MM/DD(e.g.,2012/01/21forJanuary21,2012).</p>
                        <p>2. Noitemshouldbeleftblank.Incase,any itemisnot applicable,write‘N.A.’againstit.</p>
                        <p>3. Ifspaceisnotsufficientforgivingfulldetailsagainstanyitem,separatesheet(s)maybe attached
                            to the Form and serially numbered as Annex. Each such Annex should be certified by both the
                            borrower and AD.</p>
                        <p>4. The borrower should give a brief description of his business activity (whether in
                            manufacturing/trade/ provide services etc.)fortheAD’s use</p>
                        <p>5. Before forwarding the Form to the Reserve Bank of India, AD must ensure that the form is
                            complete in all respects and scrutinise all the related original documents at its end.
                            IncompleteForms are liable to be rejected/returned by RBItoAD.
                        </p>
                        <p>6. Following codes are for use in filling Part C ofthe Form:</p>
                    </div>
                </div>
                <div className="row w-100 mx-0 mt-3">
                    <div className="col-6 d-flex justify-content-end">
                        <div className="box_info w-90 mr-0">
                            <p className="box_head col-12  fw-600">Box 1: Guarantee Status Code</p>
                            <div className="row">
                                <p className="col-4  fw-600">Sr.No.Code</p>
                                <p className="col-8 ps-2 fw-600">Description</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">1</p>
                                <p className="col-10">GG Govt.ofIndia guarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">2</p>
                                <p className="col-10">CG PublicSectorguarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">3</p>
                                <p className="col-10">PB Public Sector Bankguarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">4</p>
                                <p className="col-10">FI Financial Institution guarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">5</p>
                                <p className="col-10">MB Multilateral/Bilateral
                                    Institution guarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">6</p>
                                <p className="col-10">PG PrivateBankguarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">7</p>
                                <p className="col-10">PS PrivateSectorguarantee</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">8</p>
                                <p className="col-10">MS Mortgage of assets/security</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">9</p>
                                <p className="col-10">OG Other guarantee/security</p>
                            </div>
                            <div className="row last_box">
                                <p className="col-2 ">10</p>
                                <p className="col-10">NN Notguaranteed</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 d-flex justify-content-start">
                        <div className="box_info w-90 ml-0">
                            <p className="box_head col-12  fw-600">Box 2: Borrowing Purpose Code</p>
                            <div className="row">
                                <p className="col-4  fw-600">Sr.No.Code</p>
                                <p className="col-8 ps-2 fw-600">Description</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">1</p>
                                <p className="col-10">IC Import of capital goods
                                </p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">2</p>
                                <p className="col-10">RL Localsourcingofcapitalgoods
                                    (Rupeeexpenditure)</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">3</p>
                                <p className="col-10">SL On-lending orsub-lending</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">4</p>
                                <p className="col-10">RF Refinancingof earlier ECB
                                </p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">5</p>
                                <p className="col-10">NP New Project</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">6</p>
                                <p className="col-10">ME Modernisation/Expansion ofexisting
                                    Units</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">7</p>
                                <p className="col-10">OI Overseas investment in JV/WOS</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">8</p>
                                <p className="col-10">MF Micro Finance activity</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">9</p>
                                <p className="col-10">OT Others(specify)</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">10</p>
                                <p className="col-10">RR Refinancing ofrupee loans</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">11</p>
                                <p className="col-10">RB Redemption ofFCCBs</p>
                            </div>
                            <div className="row">
                                <p className="col-2 ">12</p>
                                <p className="col-10">IF Infrastructure development
                                </p>
                            </div>
                            <div className="row last_box">
                                <p className="col-2 ">13</p>
                                <p className="col-10">RC Working capital/ general corporate
                                    purpose</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="instructions px-5 d-flex justify-content-center mt-4">
                    <div>
                        <p className="wrap_text">7. To be submitted in duplicate by the borrower to designated Authorised Dealer (AD) for all
                            categories and any amount of external commercial borrowing (ECB). After examining
                            conformity with the extant ECB guidelines, the AD may provide requisite details in the
                            Summary Sheet ofthe Form and forward one copy (within 7 daysfrom the date ofsigning loan
                            agreementbetweenborrowerandlender)forallotmentofLoanRegistrationNumber (LRN) to:</p>
                        <div className="d-flex justify-content-center">
                            <div>
                                <p>The Director</p>
                                <p>Balance of Payments Statistics Division</p>
                                <p> Departmentof StatisticsandInformationManagement(DSIM)</p>
                                <p>Reserve Bank ofIndia</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="agreement_detail mx-5 pt-40">
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">Agreement Details (To be filled by borrowers of External Commercial Borrowings)</p>
                    </div>
                    <div className="row">
                        <div className="w-20">
                            <p className="fw-600">ECB application</p>
                        </div>
                        <div className="w-20">
                            <p>Original</p>
                        </div>
                        <div className="w-20">
                            {AgreementDetail?.ecbApplicationType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="w-20">
                            <p>Revised</p>
                        </div>
                        <div className="w-20">
                            {AgreementDetail?.ecbApplicationType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="w-20">
                            <p className="fw-600">Form</p>
                        </div>
                        <div className="w-20">
                            <p>FCYECB</p>
                        </div>
                        <div className="w-20">
                            {AgreementDetail?.formType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="w-20">
                            <p>INR ECB</p>
                        </div>
                        <div className="w-20">
                            {AgreementDetail?.formType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">In case of Revised</p>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="fw-600 ">Loan Registration Number allotted</p>
                        </div>
                        {AgreementDetail?.ecbApplicationType === 2 ?
                            <>
                                <div className="col-6 row">
                                    {Array.from(AgreementDetail?.lrn).map((digit, index) => (
                                        <div className="width_auto " key={index}>
                                            <p className="fw-600">{digit}</p>
                                        </div>
                                    ))}
                                </div>
                            </> : <div className="col-6 row">
                                <div className="width_auto ">
                                    <p className="fw-600"></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                            </div>

                        }
                    </div>
                    <div className="row">
                        <div className="w-20">
                            <p className="fw-600">ECB under</p>
                        </div>
                        <div className="w-20">
                            <p>Approval
                                Route</p>
                        </div>
                        <div className="w-20">
                            {AgreementDetail?.ecbUnderRoute === "approvalRoute" ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="w-20">
                            <p>Automatic
                                Route</p>
                        </div>
                        <div className="w-20 ">
                            {AgreementDetail?.ecbUnderRoute === "automaticRoute" ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="">Whether requires clearance from any statutory
                                authority? If yes, furnish the name of authority,
                                clearance no. and date.</p>
                        </div>
                        <div className="col-6 row ">
                            {AgreementDetail?.isRequireClearance === 1 ? <p>Yes</p> : <p>NO</p>}
                        </div>
                    </div>
                    <div className="row border_bottom">
                        <div className="col-6">
                            <p className="">Comments/ recommendation of AD bank:</p>
                        </div>
                        <div className="col-6 row ">
                        </div>
                    </div>
                </div>
                <div className="agreement_detail mx-5 pt-40">
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">Part A: Borrower details</p>
                    </div>
                    <div className="row">
                        <div className="col-5 border_bottom ">
                            <p>Name and address of the Borrower (in BLOCK letters)</p>
                            <p>{BorrowerDetail?.name} {BorrowerDetail?.address}</p>
                            <p>Registration Number given by
                                the Registrar of Companies: {BorrowerDetail?.registerNumber}</p>
                            <p>PAN Number of Company: {BorrowerDetail?.panNumber}</p>
                            <p>Business Activity: {BorrowerDetail?.businessActivity}</p>
                            <p>Contact Official’s Name: {BorrowerDetail?.contactOfficialName}</p>
                            <p>Designation: {BorrowerDetail?.designation}</p>
                            <p>Phone No: {BorrowerDetail?.phoneNumber}</p>
                            <p>Fax No: {BorrowerDetail?.faxNumber}</p>
                            <p>E-mail ID: {BorrowerDetail?.emailId}</p>
                        </div>
                        <div className="col-7 border_bottom">
                            <div className="row m-0">
                                <p className="py-2 fw-600">Borrower Category (Tick one)</p>
                            </div>
                            <div className="row m-0">
                                <div className="w-20">
                                    <p className="fw-600">Form</p>
                                </div>
                                <div className="w-20">
                                    <p>Public Sector</p>
                                </div>
                                <div className="w-20">
                                    {BorrowerDetail?.borrowerCategoryType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                                <div className="w-20">
                                    <p>Private Sector</p>
                                </div>
                                <div className="w-20 ">
                                    {BorrowerDetail?.borrowerCategoryType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <p className="box_head  py-2 fw-600">Detailed Category (Tick one)</p>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Corporate - Manufacturing</p>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.detailedCategoryType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Corporate –Infrastructure</p>
                                    <p className="">a) Transport</p>
                                    <p className="">b) Energy</p>
                                    <p className="">c) Water and Sanitation</p>
                                    <p className="">d) Communication</p>
                                    <p className="">e) Social and Commercial Infrastructure</p>
                                    <p className="">f) Exploration, Mining and Refinery</p>
                                    <p className="">g) Others</p>
                                    <div className="d-flex justify-content-baseline">
                                        <p className="">Sub-Sector:{BorrowerDetail?.subSector}</p>
                                        <div className="hr_line"></div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.detailedCategoryType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Corporate –Service Sector-</p>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.detailedCategoryType === 3 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Others</p>
                                    <p className="">a) Units in SEZ;</p>
                                    <p className="">b) SIDBI;</p>
                                    <p className="">c) EXIM Bank;</p>
                                    <p className="">d) Micro-finance entities</p>
                                    <div className="d-flex justify-content-baseline">
                                        <p className="">e) Others:{BorrowerDetail?.detailCategoryOther}</p>
                                        <div className="hr_line"></div>
                                    </div>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.detailedCategoryType === 4 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className=" fw-600">Bank</p>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.isFinancialInstitution === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Financial Institution (other than NBFC)</p>
                                </div>
                                <div className="col-1">
                                    <p className=""></p>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-6">
                                    <p className="">NBFC- IFC/AFC</p>
                                </div>
                                <div className="col-5">
                                    <p className="">Reg.No.{BorrowerDetail?.nbfcMfcRegNumber}</p>
                                </div>
                                <div className="col-1">
                                    <p className=""></p>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-6">
                                    <p className="">NBFC- MFI</p>
                                </div>
                                <div className="col-5">
                                    <p className="">Reg.No.{BorrowerDetail?.nbfcMfcRegNumber}</p>
                                </div>
                                <div className="col-1">
                                    <p className=""></p>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-6">
                                    <p className="">NBFC- Others</p>
                                </div>
                                <div className="col-5">
                                    <p className="">Reg.No.{BorrowerDetail?.nbfcOthersRegNumber}</p>
                                </div>
                                <div className="col-1">
                                    <p className=""></p>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Non-Government Organization (NGO)</p>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.isNgo === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">MicroFinance Institution (MFI)</p>
                                </div>
                                <div className="col-1">
                                    {BorrowerDetail?.isMicroFinanceInstitution === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-4">
                                    <p className="">Others (Specify)</p>
                                </div>
                                <div className="col-8 ">
                                    <p className="">{BorrowerDetail?.bankOthers}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="agreement_detail mx-5 pt-40">
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">Part B: Lender details</p>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <p>Name and address of the lender/ lessor /foreign supplier (in BLOCK letters)</p>
                            <p>{LenderDetail?.name} {LenderDetail?.address}</p>
                            <p>E-mail ID: {LenderDetail?.emailId}</p>
                            <p>(No item should be left blank)</p>
                        </div>
                        <div className="col-7">
                            <div className="row m-0">
                                <p className="py-2 fw-600">Lender Category (Tick one)</p>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Multilateral Financial Institution</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Foreign Government(Bilateral Agency)</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Export Credit Agency</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 3 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Indian Commercial Bank branch abroad</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 4 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Other Commercial Bank</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 5 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Supplier of Equipment</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 6 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Leasing Company</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 7 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Foreign Collaborator /Foreign Equity Holder</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 8 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">International Capital Market</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 9 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Regional Financial Institution</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 10 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Government Owned Development Financial Institution</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 11 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Private placement (RDBs)</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 12 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Public Offer (RDBs)</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 13 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-11">
                                    <p className="">Others (Specify)</p>
                                </div>
                                <div className="col-1">
                                    {LenderDetail?.lenderCategoryType === 14 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <p className="">Details of foreign equity holding of the lender in the borrower company:</p>
                            <p className="">(a)Share in paid-up equity of the borrower 100%</p>
                        </div>
                        <div className="col-6 row ">
                            <p className="">(b)Amount of paid-up capital 2562804700</p>
                        </div>
                    </div>
                    <div className="row border_bottom"><p className="col-12  ">ECB-Liability: equity ratio in case of borrowings above USD5million from foreign equity holder: 189:1</p></div>
                </div>
                <div className="agreement_detail mx-5 pt-40">
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">Part C: Loan Details</p>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Loan Agreement Date (YYYY/MM/DD)</p>
                        </div>
                        <div className="col-8 row">
                            <div className="date_align">
                                {loanPfdDatas?.loanAgreementDate ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.loanAgreementDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                        {/* <div className="col-8 row">
                            {loanPfdDatas?.loanAgreementDate ?
                                <>
                                    {Array.from(moment(loanPfdDatas?.loanAgreementDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                        <div className="width_auto" key={index}>
                                            <p>{digit}</p>
                                        </div>
                                    ))}
                                </> : <div className="col-8 row">
                                    <div className="width_auto ">
                                        <p className="">2</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                </div>

                            }
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Effective Date of the Loan</p>
                        </div>
                        <div className="col-8 row">
                            <div className="date_align">
                                {loanPfdDatas?.effectiveDateOfLoan ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.effectiveDateOfLoan)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                        {/* <div className="col-8 row">
                            {loanPfdDatas?.effectiveDateOfLoan ?
                                <>
                                    {Array.from(moment(loanPfdDatas?.effectiveDateOfLoan)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                        <div className="width_auto" key={index}>
                                            <p>{digit}</p>
                                        </div>
                                    ))}
                                </> : <div className="col-8 row">
                                    <div className="width_auto ">
                                        <p className="fw-600">2</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                </div>

                            }
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Last Date of Disbursement</p>
                        </div>
                        <div className="col-8 row">
                            <div className="date_align">
                                {loanPfdDatas?.lastDateOfDispersement ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.lastDateOfDispersement)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                        {/* <div className="col-8 row">
                            {loanPfdDatas?.lastDateOfDispersement ?
                                <>
                                    {Array.from(moment(loanPfdDatas?.lastDateOfDispersement)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                        <div className="width_auto" key={index}>
                                            <p>{digit}</p>
                                        </div>
                                    ))}
                                </> : <div className="col-8 row">
                                    <div className="width_auto ">
                                        <p className="fw-600">2</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                </div>

                            }
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Maturity Date (Last payment date)</p>
                        </div>
                        <div className="col-8 row">
                            <div className="date_align">
                                {loanPfdDatas?.maturityDate ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.maturityDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                        {/* <div className="col-8 row">
                            {loanPfdDatas?.maturityDate ?
                                <>
                                    {Array.from(moment(loanPfdDatas?.maturityDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                        <div className="width_auto" key={index}>
                                            <p>{digit}</p>
                                        </div>
                                    ))}
                                </> : <div className="col-8 row">
                                    <div className="width_auto ">
                                        <p className="fw-600"></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p>/</p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                    <div className="width_auto ">
                                        <p></p>
                                    </div>
                                </div>

                            }
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Grace Period (if in agreement)</p>
                        </div>
                        <div className="col-8 border-0">
                            <div className="row m-0 border-0">
                                <div className="col-2">
                                    <p>Year</p>
                                </div>
                                <div className="col-2">
                                    <p>{loanPfdDatas?.gracePeriodInYear?.toString()?.charAt(0)}</p>
                                </div>
                                <div className="col-2">
                                    <p>{loanPfdDatas?.gracePeriodInYear?.toString()?.charAt(1)}</p>
                                </div>
                                <div className="col-2">
                                    <p>Month</p>
                                </div>
                                <div className="col-2">
                                    <p>{loanPfdDatas?.gracePeriodInMonth?.toString()?.charAt(0)}</p>
                                </div>
                                <div className="col-2">
                                    <p>{loanPfdDatas?.gracePeriodInMonth?.toString()?.charAt(1)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row border_bottom">
                        <div className="col-4">
                            <p className="fw-600 ">Currency Name</p>
                            <p>1. {loanPfdDatas?.currency}</p>
                        </div>
                        <div className="col-2">

                        </div>
                        <div className="col-3">
                            <p>Currency Code (SWIFT)</p>
                        </div>
                        <div className="col-1">

                        </div>
                        <div className="col-1">

                        </div>
                        <div className="col-1">

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Amount (in Foreign Currency)</p>
                            <p>1. {loanPfdDatas?.amount} USD</p>
                        </div>
                        <div className="col-8">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Equivalent Amount (in US
                                Dollars)
                                (as on date of this form)</p>
                        </div>
                        <div className="col-8">
                            <p>{loanPfdDatas?.equivalentAmount}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="fw-600 ">Proposed Bifurcation of the
                                amount</p>
                        </div>
                        <div className="col-4">
                            <p className="">Foreign Currency Expenditure</p>
                        </div>
                        <div className="col-4">
                            <p className="">Rupee Expenditure</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <p className="">(in loan currency)</p>
                        </div>
                        <div className="col-4">
                            <p className="">USD {loanPfdDatas?.foreignCurrencyExpenditure}</p>
                        </div>
                        <div className="col-4">
                            <p className="">USD {loanPfdDatas?.rupeeExpenditure}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p className="fw-600 ">Hedging details (Tick
                                one)
                            </p>
                        </div>
                        <div className="col-3">
                            <p className="">Currency Swap</p>
                            {loanPfdDatas?.hedgingDetailsType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-3">
                            <p className="">Interest Rate Swap</p>
                            {loanPfdDatas?.hedgingDetailsType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-2">
                            <p className="">Others</p>
                            {loanPfdDatas?.hedgingDetailsType === 3 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-2">
                            <p className="">Unhedged</p>
                            {loanPfdDatas?.hedgingDetailsType === 4 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p className="fw-600 ">Hedging percentage
                                (proposed)
                            </p>
                        </div>
                        <div className="col-3">
                            <p className="">Financial Hedge
                                {loanPfdDatas?.financialHedge}%</p>
                        </div>
                        <div className="col-3">
                            <p className="">Natural Hedge
                                {loanPfdDatas?.naturalHedge}%</p>
                        </div>
                        <div className="col-2">
                            <p className="">Total
                                Hedge</p>
                        </div>
                        <div className="col-2">
                            <p className="">{(loanPfdDatas?.naturalHedge + loanPfdDatas?.financialHedge)}%</p>
                        </div>
                    </div>
                    <div className="row"><p className="col-12  ">In case options are provided in the loan agreement (tick in the appropriate box) NOT APPLICABLE</p></div>
                    <div className="row">
                        <div className="col-2">
                            <p className="">Call Option
                            </p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-2">
                            {loanPfdDatas?.ecbType === 1 ? <p className="">per cent of
                                Debt: {loanPfdDatas?.percentOfDebt}</p> : <p className="">per cent of
                                    Debt </p>}
                        </div>
                        <div className="col-3">
                            <p className="">Can be executed after date</p>
                        </div>
                        <div className="col-4">
                            <div className="date_align">
                                {loanPfdDatas?.ecbType === 1 ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.executedAfterDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="row border_bottom">
                        <div className="col-2">
                            <p className="">Put Option
                            </p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-2">
                            {loanPfdDatas?.ecbType === 2 ? <p className="">per cent of
                                Debt: {loanPfdDatas?.percentOfDebt}</p> : <p className="">per cent of
                                    Debt </p>}
                        </div>
                        <div className="col-3">
                            <p className="">Can be executed after date</p>
                        </div>
                        <div className="col-4">
                            <div className="date_align">
                                {loanPfdDatas?.ecbType === 2 ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.executedAfterDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> :
                                    <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="fw-600">Name and address of the Guarantor (in Block letters) NOT APPLICABLE</p>
                            <p>Contact Official’s Name:<span>{loanPfdDatas?.guarantorName}</span></p>
                            <p>Designation :<span>{loanPfdDatas?.guarantorDesignation}</span></p>
                            <div className="row m-0">
                                <div className="col-4 border-0">
                                    <p>Phone No.:{loanPfdDatas?.guarantorPhoneNumber}</p>
                                </div>
                                <div className="col-4 border-0">
                                    <p>Fax No.:{loanPfdDatas?.guarantorFaxNumber} </p>
                                </div>
                                <div className="col-4 border-0">
                                    <p>E-mail id:{loanPfdDatas?.guarantorEmailId}</p>
                                </div>
                            </div>
                            <p>Guarantee Status Code:{loanPfdDatas?.guarantorStatusCode}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-10">
                            <p className="fw-600">Nature and details of security, if any NOT APPLICABLE</p>
                        </div>
                        <div className="col-2">
                            {loanPfdDatas?.isNatureAndDetailsOfSecurity == 1 ? "Yes" : 'No'}
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-12">
                            <p className="">End-use (% share if more than one end-use):</p>
                            <div className="row m-0">
                                {
                                    NatureDetailsSecurities?.map((o, index) => {
                                        return (
                                            <>
                                                <p key={index}>({index + 1}) Borrowing Purpose Code Amount Percentage : {o?.BorrowingPurposeCode?.code}-{o?.borrowingPurposeCurrency} {o?.borrowingPurposeAmount}-{o?.borrowingPurposePercentage}%</p>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="fw-600">Project Details (Name, Location and Cost): For setting up facility for manufacturing and assembly of
                                Electric Vehicle and Capital Expenditure for Electronic Division USD 20,000,000</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p><span className="fw-600">If import, </span>specify the Country of import (if more than one country, attach details as Annex):{loanPfdDatas?.moreThenOneCountry}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <p className=""><span className="fw-600">Industry Code</span> (as per NIC-2008):</p>
                        </div>
                        <div className="col-7">
                            <p className="fw-600">{loanPfdDatas?.projectIndustryCode}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className=""><span className="fw-600">Type of ECB</span> B(Tick in appropriate box)</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="">1.Buyers’ Credit</p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 1 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-7">
                            <p className="">2.Commercial Loan / Syndicated Loan
                                (attach sheet for percentage distribution among lenders)
                            </p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 2 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="">3.Suppliers’ Credit</p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 3 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-7">
                            <p className="">4.Export Credit from Bilateral Sources
                            </p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 4 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="">5.Line of Credit</p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 5 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-7">
                            <p className="">6.Securitized Instruments(Bonds, CP, FRN, etc.)</p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 6 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <p className="">7.Financial Lease</p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 7 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                        <div className="col-7">
                            <p className="">8.FCCB, FCEB, Non-Convertible Preference Shares, Optionally
                                Convertible Preference Shares, Partially Convertible Preference
                                Shares
                            </p>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 8 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-11">
                            <p className="">9.Refinancing of old ECBs</p>
                            <div className="row m-0">
                                <div className="col-4 border-0">
                                    <p className="">LRN of the old ECB:{loanPfdDatas?.oldEcbLrn} <span></span></p>
                                </div>
                                <div className="col-4 border-0">
                                    <p className="">Approval No.{loanPfdDatas?.oldEcbApprovalNumber}  <span></span></p>
                                </div>
                                <div className="col-4 border-0">
                                    <p className="">Date of Approval: {moment(loanPfdDatas?.oldEcbApprovalDate).format('YYYY-MM-DD')} <span></span></p>
                                </div>
                                <div className="col-4 border-0">
                                    <p className="">Amount refinanced: {loanPfdDatas?.oldEcbApprovalRefinanced}  <span></span></p>
                                </div>
                                <div className="col-4 border-0">
                                    <p className="">Reason: {loanPfdDatas?.oldEcbReason}<span></span></p>
                                </div>
                                <div className="col-4 border-0">
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            {loanPfdDatas?.ecbType === 9 ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 border_right">
                            <p className=""><span className="fw-600">Interest Payment Schedule – </span>Interest Free loan</p>
                        </div>
                        <div className="col-7 d-flex">
                            <p>First Payment Date</p>
                            <div className="date_align">
                                {loanPfdDatas?.firstPaymentDate ?
                                    <>
                                        {Array.from(moment(loanPfdDatas?.firstPaymentDate)?.format('YYYY/MM/DD'))?.map((digit, index) => (
                                            <div className="width_auto" key={index}>
                                                <p>{digit}</p>
                                            </div>
                                        ))}
                                    </> : <>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                        <p className="fw-600 ">/</p>
                                        <p className=""></p>
                                        <p className=""></p>
                                    </>

                                }
                            </div>
                        </div>
                        <div className="col-5 d-flex">
                            <p className="">No. of payments /
                                year</p>
                            <div className="date_align">
                                <p className="">{loanPfdDatas?.numberOfPaymentsPerYear}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex">
                            <p>Fixed Rate</p>
                            <div className="date_align">
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(0)}</p>
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(1)}</p>
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(2)}</p>
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(3)}</p>
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(4)}</p>
                                <p className="">{loanPfdDatas?.fixedRate?.toString()?.charAt(5)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2">
                            <p>Floating Rate</p>
                        </div>
                        <div className="col-10">
                            <div className="row m-0">
                                <div className="col-2 border-top-0 border-start-0">
                                    <p>Base ith currency</p>
                                </div>
                                <div className="col-1 border-top-0">
                                    <p>{loanPfdDatas?.baseIthCurrency}</p>
                                </div>
                                <div className="col-2 border-top-0">
                                    <p>Margin</p>
                                </div>
                                <div className="col-1 border-top-0">
                                    <p>{loanPfdDatas?.marginCurrency}</p>
                                </div>
                                <div className="col-2 border-top-0">
                                    <p>Cap Rate</p>
                                </div>
                                <div className="col-1 border-top-0">
                                    <p>{loanPfdDatas?.capRate}</p>
                                </div>
                                <div className="col-2 border-top-0">
                                    <p>Floor Rate</p>
                                </div>
                                <div className="col-1 border-top-0">
                                    <p>{loanPfdDatas?.floorRate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="fw-600">Drawdown Schedule</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Tranche No.</th>
                                        <th rowSpan="2">Date* (YYYY-MM-DD)</th>
                                        <th rowSpan="2">Currency</th>
                                        <th rowSpan="2">Amount</th>
                                        <th colSpan="2">If more than one installment</th>
                                    </tr>
                                    <tr>
                                        <th>Total No. of drawals</th>
                                        <th>No. of drawals in a calendar year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        DrawdownSchedules?.map((o, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{moment(o?.date).format('YYYY-MM-DD')}</td>
                                                <td>{o?.currency}</td>
                                                <td>{o?.amount}</td>
                                                <td>{o?.totalNoOfDrawals}</td>
                                                <td>{o?.totalNoOfDrawalsInCalendarYear}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="col-12 border_right">
                            <div className="d-flex">
                                <div>
                                    <p className="fs-20">*</p>
                                </div>
                                <div>
                                    <p>1.In case of import of goods or services, date of import is to be furnished against date of
                                        drawdown.</p>
                                    <p>2. In case of financial lease, date of acquisition (import) of the goods is to be mentioned as date of
                                        drawdown</p>
                                    <p>3. In case securitised instruments, date of issue has to be shown as date of drawdown.</p>
                                    <p>4. In case of more than one equal drawdown transactions are shown in a row, the first date of
                                        transaction should be mentioned.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="fw-600">Principal Repayment Schedule</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Date* (YYYY-MM-DD)</th>
                                        <th rowSpan="2">Currency</th>
                                        <th rowSpan="2">Amount</th>
                                        <th colSpan="2">If more than one installment</th>
                                    </tr>
                                    <tr>
                                        <th>Total No. of Payments</th>
                                        <th>No. of payments in a calendar year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        PrincipalSchedules?.map((o, index) => (
                                            <tr key={index}>
                                                <td>{moment(o?.date).format('YYYY-MM-DD')}</td>
                                                <td>{o?.currency}</td>
                                                <td>{o?.amount}</td>
                                                <td>{o?.totalNoOfPayment}</td>
                                                <td>{o?.totalNoOfPaymentInCalendarYear}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="agreement_detail mx-5 pt-40 border_bottom">
                    <div className="row">
                        <p className="box_head text-center py-2 fw-600">Part D: Other Charges</p>
                    </div>
                    <div className="row ">
                        <div className="col-2">
                            <p className="px-2">Nature of charge</p>
                            {/* {OtherCharge?.natureOfCharge} */}
                        </div>
                        <div className="col-2">
                            <p className="px-2">Expected Date of Payment</p>
                            {/* {moment(OtherCharge?.expectedDateOfPayment).format('YYYY-MM-DD')} */}
                        </div>
                        <div className="col-2">
                            <p className="px-2">Currency</p>
                            {/* {OtherCharge?.natureOfCharge} */}
                        </div>
                        <div className="col-2">
                            <p className="px-2">Amount</p>
                            {/* {OtherCharge?.amount} */}
                        </div>
                        <div className="col-4">
                            <div className="row m-0">
                                <p className="px-2">In case of multiple equal payments</p>
                                <div className="col-6">
                                    <p className="px-2">No. of payments in a Year</p>
                                </div>
                                <div className="col-6 ">
                                    <p className="px-2">Total no. of payments</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row last_box ">
                        <div className="col-2">
                            <p className="px-2 last_box border_bottom">Upfront fee</p>
                            <p className="px-2 last_box border_bottom">Management fee</p>
                            <p className="px-2 last_box border_bottom">Commitment Fees</p>
                            <p className="px-2 last_box border_bottom">Guarantee fees</p>
                            <p className="px-2 last_box">ECA charges</p>
                            <p>Others</p>
                            <p>Total</p>
                        </div>
                        <div className="col-10">
                        </div>
                    </div>
                    <div className="row last_box">
                        <div className="col-4">
                            <p className="px-2 last_box border_bottom">Penal Interest for late payments:{OtherCharge?.penalIntLatePaymentFixed}</p>
                        </div>
                        <div className="col-8">
                            <div className="row m-0">
                                <div className="col-6 border-start-0 border-top-0">
                                    <p>Fixed 2 % or Base:{OtherCharge?.penalIntLatePaymentBase}<span></span></p>
                                </div>
                                <div className="col-6 border-top-0">
                                    <p>Margin:{OtherCharge?.penalIntLatePaymentMargin}<span></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row last_box">
                        <div className="col-4">
                            <p className="px-2 last_box border_bottom">Commitment Charges</p>
                        </div>
                        <div className="col-8">
                            <div className="row m-0">
                                <div className="col-6 border-start-0 border-top-0">
                                    <p>% per annum of:{OtherCharge?.commitmentChargesPerAnnum}<span></span></p>
                                </div>
                                <div className="col-6 border-top-0">
                                    <p>% of Undrawn Amount:{OtherCharge?.commitmentChargesPerUndrawnAmount}<span></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="agreement_detail mx-5 pt-40 border_bottom">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-center py-2 fw-600">Part E: Details of ECB already availed (not applicable for the first-time borrower)</p>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <table className="">
                                <thead>
                                    <tr>
                                        <th rowSpan="2">Year</th>
                                        <th rowSpan="2">Loan Reg.No.(LRN)</th>
                                        <th rowSpan="2">Currency</th>
                                        <th colSpan="3">Amount of Loan
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Principal (as per agreement)</th>
                                        <th>Disbursed so far</th>
                                        <th>Net outstanding (Principal)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        PrincipalSchedules?.map((o,index) => (
                                            <tr key={index}>
                                                <td>{moment(o?.date).format('YYYY-MM-DD')}</td>
                                                <td>{o?.currency}</td>
                                                <td>{o?.amount}</td>
                                                <td>{o?.totalNoOfPayment}</td>
                                                <td>{o?.totalNoOfPaymentInCalendarYear}</td>
                                            </tr>
                                        ))} */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p className="pt-3">We hereby certify that the particulars given above are true and correct to the best of our knowledge and belief and no material information has been withheld and/or misrepresented. Furthermore, the ECB is in compliance with the extant ECB guidelines and the ECB to be raised will be utilised for permitted purposes.</p>
                    <div className="row pt-4">
                        <div className="col-4 border-0">
                            {/* <p>Place: <span className="highlight_text"> {OtherCharge?.place}</span></p> */}
                            <p>Place: <span className=""> {DeclarationDetail?.place}</span></p>
                            <p>Date:<span className="">  {moment(DeclarationDetail?.loanAgreementDate).format('YYYY-MM-DD')}</span></p>
                        </div>
                        <div className="col-8 border-0">
                            <p>(Signature of the Authorised Official of the Company with stamp)</p>
                            {/* <div className="d-flex justify-content-between"> */}
                            <p>Name: <span>{DeclarationDetail?.name} </span></p>
                            <p>Designation: <span>{DeclarationDetail?.designation}</span></p>
                            {/* </div> */}
                            <p>Phone No. Fax : {DeclarationDetail?.phoneOrFaxNumber}</p>
                            <p>E-mail: <span> {DeclarationDetail?.emailId}</span></p>
                        </div>
                    </div>
                    <div>
                        <p className="text-center fw-600 mt-5">Summary Sheet (SS) for Form ECB</p>
                        <p className="mt-2">We have scrutinized the related documents and confirm the following:</p>
                        <div className="row">
                            <div className="col-1">
                                <p>1</p>
                            </div>
                            <div className="col-3">
                                <p>End-use
                                    (%share if more than one
                                    end-use)</p>
                            </div>
                            <div className="col-2">
                                {NatureDetailsSecurities?.length > 0 ? NatureDetailsSecurities?.map((ele) => {
                                    count = count + 1;
                                    let code = borrowingPurposeCode.find((e) => e?.id === ele?.borrowingPurposeCodeId)
                                    return <React.Fragment>
                                        <p>{count}. {code.code} - {ele?.borrowingPurposePercentage ?? 0}%</p>
                                    </React.Fragment>
                                }) : "No end use"}
                            </div>
                            <div className="col-6">
                                <div className="row m-0">
                                    <div className="col-5 border-start-0 border-top-0">
                                        <p>Permissible
                                            under
                                            Automatic
                                            Route</p>
                                    </div>
                                    <div className="col-7 border-top-0">
                                        <p>Approved by Foreign
                                            Exchange Department,
                                            RBI under Approval
                                            Route</p>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-5">
                                        {AgreementDetail?.ecbUnderRoute === "approvalRoute" ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                    </div>
                                    <div className="col-7">
                                        {AgreementDetail?.ecbUnderRoute === "automaticRoute" ? <p><i class="fa-solid fa-check"></i></p> : <p></p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <p>2</p>
                            </div>
                            <div className="col-3">
                                <p>Average Maturity</p>
                            </div>
                            <div className="col-8">
                                <div className="row m-0">
                                    <div className="col-7 border-start-0 border-top-0">
                                        <p>Years </p>
                                    </div>
                                    <div className="col-5 border-top-0">
                                        <p>Months</p>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-7 border-start-0">
                                        <p className="">{pdfData?.gracePeriodInYear ?? 0}</p>
                                    </div>
                                    <div className="col-5">
                                        <p>{pdfData?.gracePeriodInMonth ?? 0}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <p>3</p>
                            </div>
                            <div className="col-11 border_right">
                                <div className="row m-0">
                                    <div className="col-3 border-start-0">
                                        <p>Cost Factors (%)</p>
                                    </div>
                                    <div className="col-9">
                                        <div className="row m-0">
                                            <div className="col-3 border-top-0">
                                                <p>Fixed Rate
                                                    Loan </p>
                                            </div>
                                            <div className="col-9 border-top-0">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <p>Floating Rate Loan</p>
                                                    </div>
                                                    <div className="col-7">
                                                        <p>Margin (spread) over
                                                            base</p>
                                                    </div>
                                                    <div className="col-5">
                                                        <p>Base</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-3 border-start-0">
                                        <p>a) Interest Rate</p>
                                        <p>b) All-in-cost</p>
                                    </div>
                                    <div className="col-9 border_left border_top">
                                        <p>{pdfData?.fixedRate ?? 'NIL ( interest free loan)'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row border_right">
                            <div className="col-1">
                                <p>4</p>
                            </div>
                            <div className="col-11">
                                <p>In case of loan from ‘Foreign Equity Holder’, it is confirmed that ECB liability: Equity ratio
                                    (7:1) criteria is satisfied. Further, in case of working capital/general corporate purpose/
                                    repayment of rupee loans end-use, it is confirmed that equity holding of lender is at
                                    least 25 percent (direct)/51 percent (indirect) of the paid-up equity or the lender is a
                                    group company with common overseas parent. No
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <p>5</p>
                            </div>
                            <div className="col-8">
                                <p>Borrower has given written undertaking to AD to the effect that it
                                    hasbeensubmittingECB-2ReturnsregularlytoRBIinrespectof past
                                    ECB/FCCB loans)
                                </p>
                            </div>
                            <div className="col-3">
                                <p>Yes</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <p>6</p>
                            </div>
                            <div className="col-4">
                                <p>Security provided, if any</p>
                            </div>
                            <div className="col-7">
                                <p>None</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-1">
                                <p>7</p>
                            </div>
                            <div className="col-4">
                                <p>Other important facts
                                    relevant for the allotment
                                    of LRN
                                </p>
                            </div>
                            <div className="col-7">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="row pt-4">
                            <div className="col-4 border-0">
                                <p>Place: <span className=""> {Sumarry?.place}</span></p>
                                <p>Date:<span className="">  {moment(Sumarry?.date).format('YYYY-MM-DD')}</span></p>
                            </div>
                            <div className="col-8 border-0">
                                <p>Name: <span className="">{Sumarry?.name}</span></p>
                                <p>Registration No.: <span className="">{Sumarry?.registrationNumber ?? "-"}</span></p>
                            </div>
                        </div>
                        <p className="pt-3">We certify that the borrower is our customer and the particulars given in this Form are true and correct
                            to the best of our knowledge and belief. We have scrutinized the application and the original letter of
                            offer from the lender/supplier and documents relating to proposed borrowing and found the same to be
                            in order. This application complies with the extant ECB guidelines and we recommend it for allotment of
                            Loan Registration Number (LRN) by RBI..</p>
                        <div className="row pt-4 border-0">
                            <div className="col-4 border-0">
                                <p>Place: <span className=""> {Sumarry?.place}</span></p>
                                <p>Date:<span className="">  {moment(Sumarry?.date).format('YYYY-MM-DD')}</span></p>
                            </div>
                            <div className="col-8 border-0">
                                <p>(Signature of the Authorised Official with stamp)</p>
                                <div className="">
                                    <p>Name: <span>{Sumarry?.name} </span></p>
                                    <p>Designation:  <span>{Sumarry?.designation} </span></p>
                                    <p>Name of the bank/ branch<span>{Sumarry?.nameOfBankandBranch}</span></p>
                                    <p>AD Code (Part Iand PartII):<span>{Sumarry?.ADCode}</span></p>
                                    <p>Tel.No.:_Fax No<span className="text_line">{Sumarry?.telNumber}</span></p>
                                    <p>e-mail:<span className="text_line">{Sumarry?.emailId}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <p>For RBI(DSIM)Use only</p>
                        <div className="row">
                            <div className="col-3">
                                <p>RBI Team</p>
                            </div>
                            <div className="col-3">
                                <p>Received on</p>
                            </div>
                            <div className="col-3">
                                <p>Action Taken on</p>
                            </div>
                            <div className="col-3">
                                <div className="row m-0">
                                    <div className="col-12 border-0">
                                        <p>Loan Classification</p>

                                    </div>
                                    <div className="col-12 border-start-0">
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p className="fw-600 ">LRN (if allotted)</p>
                            </div>
                            <div className="col-8 row">
                                <div className="width_auto ">
                                    <p className="fw-600"></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                                <div className="width_auto ">
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
)

export default FORM1PDF