import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, login,doPost } from '../../service';
import { Actions } from '../action/index';
import { LOGIN_USER } from '../action/loginAction';
import END_POINTS from '../../service/EndPoint';
import { FORM_LIST, FORM_LOAN_DETAILS_LIST, ORIGINAL_LOAN_LIST, FORM_LENDER_DETAILS_LIST, FORM_BORROWER_DETAILS_LIST, GET_LENDER_DETAILS_LIST, GET_BORROWER_DETAILS_LIST, AGREEMENT_DETAILS, GET_LOAN_DETAILS,ECB_DETAILS_LIST,GET_AGREEMENT_DETAILS } from '../action/form';

export function* FormList(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.DRAFT_FORM_LIST, params)
        yield put(Actions.FormListSuccess(response?.response))
        callback(response)

    } catch (error) {
        callback({ error })
        yield put(Actions.FormListFailure(error))
    }
}

export function* FormLoanDetailsList(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.FORM_LOAN_DETAILS_LIST, params)
        yield put(Actions.FormLoanDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback(error )
        yield put(Actions.FormLoanDetailsListFailure(error))
    }
}

export function* OriginalFormListSaga(param) {
    const { params, callback } = param
    try {
        const response = yield doGet(END_POINTS.FORM_LIST, params)
        yield put(Actions.OriginalFormListSuccess(response?.response))
        callback(response)
    } catch (error) {
        callback({ error })
        yield put(Actions.OriginalFormListFailure(error))
    }
}
export function* FormLenderDetailsList(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.FORM_LENDER_DETAILS_CREATE, params)
        yield put(Actions.FormLenderDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.FormLenderDetailsListFailure(error))
    }
}

export function* AgreementDetailsSaga(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.AGREEMENT_DETAILS, params)
        yield put(Actions.AgreementDetailsSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.AgreementDetailsFailure(error))
    }
}
export function* getAgreementDetailsSaga(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_AGREEMENT_DETAILS, params)
        yield put(Actions.getAgreementDetailsSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getAgreementDetailsFailure(error))
    }
}
export function* GetLoanDetailsSaga(param) {
    const { params, callback } = param
    try {
        const response = yield doGet(END_POINTS.GET_LOAN_DETAILS, params)
        yield put(Actions.GetLoanDetailsSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.GetLoanDetailsFailure(error))
    }
}
export function* FormBorrowerDetailsList(param) {
    const { params, callback } = param
    try {
        const response = yield doPost(END_POINTS.FORM_BORROWER_DETAILS_CREATE, params)
        yield put(Actions.FormBorrowerDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
       yield put(Actions.FormBorrowerDetailsListFailure(error))
    }
}
export function* getLenderDetailsList(param) {
    const { params, callback } = param
    try {
        const response = yield doGet(END_POINTS.GET_LENDER_DETAILS_CREATE, params)
        yield put(Actions.getLenderDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getLenderDetailsListFailure(error))
    }
}
export function* getBorrowerDetailsList(param) {
    const { params, callback } = param
    try {
        const response = yield doGet(END_POINTS.GET_BORROWER_DETAILS_CREATE, params)
        yield put(Actions.getBorrowerDetailsListSuccess(response?.response))
        callback && callback(response)
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getBorrowerDetailsListFailure(error))
    }
}
export function* ecbDetailsList(param) {
    const { params, callback } = param
    try {
        const response = yield doPost(END_POINTS.ECB_DETAILS_CREATE, params)
        yield put(Actions.ecbDetailsListSuccess(response?.response))
        callback && callback(response)
    } catch (error) {
        callback && callback({ error })
        yield put(Actions.ecbDetailsListFailure(error))
    }
}
export default function* formListWatcher() {
    yield all([
        takeLatest(FORM_LIST, FormList),
        takeLatest(FORM_LOAN_DETAILS_LIST, FormLoanDetailsList),
        takeLatest(ORIGINAL_LOAN_LIST, OriginalFormListSaga),
        takeLatest(FORM_LENDER_DETAILS_LIST, FormLenderDetailsList),
        takeLatest(AGREEMENT_DETAILS, AgreementDetailsSaga),
        takeLatest(GET_AGREEMENT_DETAILS, getAgreementDetailsSaga),
        takeLatest(GET_LOAN_DETAILS, GetLoanDetailsSaga),
        takeLatest(FORM_BORROWER_DETAILS_LIST, FormBorrowerDetailsList),
        takeLatest(GET_LENDER_DETAILS_LIST, getLenderDetailsList),
        takeLatest(GET_BORROWER_DETAILS_LIST, getBorrowerDetailsList),
        takeLatest(ECB_DETAILS_LIST, ecbDetailsList),

    ]);
}
