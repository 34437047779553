import { doAction, doResponseAction } from ".";


const CREATE_DRAWDOWN = 'CREATE_DRAWDOWN';
const CREATE_DRAWDOWN_SUCCESS = 'CREATE_DRAWDOWN_SUCCESS';
const CREATE_DRAWDOWN_FAILURE = 'CREATE_DRAWDOWN_FAILURE';

const UPDATE_DRAWDOWN = 'UPDATE_DRAWDOWN';
const UPDATE_DRAWDOWN_SUCCESS = 'UPDATE_DRAWDOWN_SUCCESS';
const UPDATE_DRAWDOWN_FAILURE = 'UPDATE_DRAWDOWN_FAILURE';

const DELETE_DRAWDOWN = 'DELETE_DRAWDOWN';
const DELETE_DRAWDOWN_SUCCESS = 'DELETE_DRAWDOWN_SUCCESS';
const DELETE_DRAWDOWN_FAILURE = 'DELETE_DRAWDOWN_FAILURE';

export {

    CREATE_DRAWDOWN,
    CREATE_DRAWDOWN_SUCCESS,
    CREATE_DRAWDOWN_FAILURE,

    UPDATE_DRAWDOWN,
    UPDATE_DRAWDOWN_SUCCESS,
    UPDATE_DRAWDOWN_FAILURE,

    DELETE_DRAWDOWN,
    DELETE_DRAWDOWN_SUCCESS,
    DELETE_DRAWDOWN_FAILURE,
}


export const createDrawdown = (params, callback) => doAction(CREATE_DRAWDOWN, params, callback);
export const createDrawdownSuccess = (data) => doResponseAction(CREATE_DRAWDOWN_SUCCESS, data);
export const createDrawdownFailure = (data) => doResponseAction(CREATE_DRAWDOWN_FAILURE, data);

export const updateDrawdown = (params, callback) => doAction(UPDATE_DRAWDOWN, params, callback);
export const updateDrawdownSuccess = (data) => doResponseAction(UPDATE_DRAWDOWN_SUCCESS, data);
export const updateDrawdownFailure = (data) => doResponseAction(UPDATE_DRAWDOWN_FAILURE, data);

export const deleteDrawdown = (params, callback) => doAction(DELETE_DRAWDOWN, params, callback);
export const deleteDrawdownSuccess = (data) => doResponseAction(DELETE_DRAWDOWN_SUCCESS, data);
export const deleteDrawdownFailure = (data) => doResponseAction(DELETE_DRAWDOWN_FAILURE, data);