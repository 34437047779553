import { Actions } from '../action';

const initialState = {
    isLoading: false,
    getGuaranteeStatusCodelist: [],
    getBorrowingPurposeCodeList: [],
    error: false,
}
export const getMasterTableReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_GUARANTEE_STATUS_CODE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_GUARANTEE_STATUS_CODE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getGuaranteeStatusCodelist: action?.data,
                error: false
            };
        }
        case Actions.GET_GUARANTEE_STATUS_CODE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_BORROWING_PURPOSE_CODE_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_BORROWING_PURPOSE_CODE_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getBorrowingPurposeCodeList: action?.data,
                error: false
            };
        }
        case Actions.GET_BORROWING_PURPOSE_CODE_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};



export const getGuaranteeStatusCode = (state) => state?.MASTERTABLE?.getGuaranteeStatusCodelist;

export const getBorrowingPurposeCode = (state) => state?.MASTERTABLE?.getBorrowingPurposeCodelist;