import { Actions } from '../action';

const initialState = {
    isLoading: false,
    otherCharges: [],
    user: null,
    error: false,
}
export const OtherCharges = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_OTHER_CHARGES: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_OTHER_CHARGES_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                otherCharges: action?.data,
               
                error: false
            };
        }
        case Actions.GET_OTHER_CHARGES_FAILURE: {
            return {
                ...state,
                isLoading: false,
               
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getOtherChargesData = (state) => state?.OTHERS_CHARGES;
