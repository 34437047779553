import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Paginator from "../component/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import { getFormList, getOriginalList } from "../redux/reducer/FormListReducers";
import moment from "moment/moment";
import Loader from "../component/HOC/Loader";
import { toast } from "react-toastify";
// import PdfDownloader from "./PdfDownloader";
// import { useReactToPrint } from 'react-to-print';

const FormList = ({ onEditForm, setFormPage }) => {
    const componentRef = useRef()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [count, setcount] = useState("")
    const [Orgcount, setOrgcount] = useState("")
    const loanList = useSelector(getFormList)
    const originalLoanList = useSelector(getOriginalList)
    const [tab, setTab] = useState(1)
    const [page, setPage] = useState(1)
    const [Orgpage, setOrgPage] = useState(1)
    const [isloading, setLoading] = useState(false)

    const isDraft = useSelector((state) => state.FORM.isDraft);

    useEffect(() => {
        getLoanList(page - 1)
    }, [dispatch, page])
    // useEffect(() => {
    //     getOriginalLoanList(Orgpage - 1)
    // }, [dispatch, Orgpage])

    const getOriginalLoanList = (page) => {
        dispatch(Actions.isDraft(false))
        setLoading(true)
        const callback = (res) => {
            setLoading(false)
            if (res?.response_code === 0) {
                setOrgcount(res?.response?.totalItems)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }

        }
        const params = {
            limit: 10,
            pageNumber: page,
            isDraft:false,
        }
        dispatch(Actions.OriginalFormList(params, callback))
    }

    const getLoanList = (page) => {
        dispatch(Actions.isDraft(true))
        setLoading(true)
        const callback = (res) => {
            setLoading(false)
            if (res?.response_code === 0) {
                setcount(res?.response?.totalItems)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        }
        const params = {
            limit: 10,
            pageNumber: page,
            isDraft:true,
        }
        dispatch(Actions.FormList(params, callback))
    }
    const handlepage = (e) => {
        setPage(e)
        getLoanList(e - 1)
    }
    const addForm = () => {
        dispatch(Actions.AgreementDetailsSuccess([]))
        dispatch(Actions.FormLoanDetailsListSuccess([]))
        dispatch(Actions.loanDetailsData([]))
        dispatch(Actions.lenderDetailsData([]))
        dispatch(Actions.borrowerDetailsData([]))
        dispatch(Actions.ecbDetailsData([]))
        dispatch(Actions.getAgreementDetailsSuccess([]))
        setFormPage(true)
        navigate('/home')

    }
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef?.current,
    // });
    const onEdit = (o) => {
        dispatch(Actions.AgreementDetailsSuccess([]))
        dispatch(Actions.FormLoanDetailsListSuccess([]))
        dispatch(Actions.loanDetailsData([]))
        dispatch(Actions.lenderDetailsData([]))
        dispatch(Actions.borrowerDetailsData([]))
        dispatch(Actions.ecbDetailsData([]))
        dispatch(Actions.getAgreementDetailsSuccess([]))
        onEditForm(o)
        
    }
    const onView = (o) => {
        dispatch(Actions.AgreementDetailsSuccess([]))
        dispatch(Actions.FormLoanDetailsListSuccess([]))
        dispatch(Actions.loanDetailsData([]))
        dispatch(Actions.lenderDetailsData([]))
        dispatch(Actions.borrowerDetailsData([]))
        dispatch(Actions.ecbDetailsData([]))
        dispatch(Actions.getAgreementDetailsSuccess([]))
        onEditForm(o)
        
    }
    const handleTab = () => {
        if (tab === 1) {
            setTab(2)
            getOriginalLoanList(Orgpage - 1)
        } else {
            setTab(1)
            getLoanList(page - 1)
        }
    }

    return <>
        <div className="form_content py-3 px-md-4 px-2">
            <div className="">
                <div ><i className="bi bi-list menu_icon fa-2xl"></i></div>
                <h3 className="heading">Form list</h3>
            </div>
            <nav className="nav nav-pills flex-column flex-sm-row">
                <a className={`flex-sm-fill text-sm-center nav-link ${tab === 1 && "common_btn"}`} aria-current="page" href="#" onClick={() => handleTab(1)}>Drafted loan list</a>
                <a className={`flex-sm-fill text-sm-center nav-link ${tab === 2 && " common_btn"}`} href="#" onClick={() => handleTab(2)}>Original loan list</a>
            </nav>
            {/* <div className="d-none">
            <PdfDownloader ref={componentRef}  />
        </div> */}
            <div className="d-flex align-items-center justify-content-end py-4">
                <p className="m-0"> </p>
                { tab===1 &&
                <button className="common_btn" onClick={() => addForm()}>Add Form</button>}
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Loan Registration No</th>
                        <th scope="col">Guarantor Name</th>
                        <th scope="col">Guarantor Phone</th>
                        <th scope="col">Project</th>
                        <th scope="col">Amount</th>
                        <th scope="col">createdDate</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tab === 1 ? loanList?.length > 0 ? loanList?.map((o, index) => {
                        return <tr key={index}>
                            <th scope="row">{o?.id}</th>
                            <td>{o?.lrn}</td>
                            <td>{o?.guarantorName}</td>
                            <td>{o?.guarantorPhoneNumber}</td>
                            <td>{o?.projectName}</td>
                            <td>{o?.amount}</td>
                            <td>{moment(o?.createdDate).format("DD-MM-YYYY")}</td>
                            <td><button className="common_btn" onClick={() => onEdit(o)}>Edit</button></td>
                        </tr>
                    }) : <tr className="tr-shadow">
                        <td colSpan="8" className="text-center">
                            No data found
                        </td>
                    </tr> : originalLoanList?.length > 0 ? originalLoanList?.map((o, index) => {
                        return <tr key={index}>
                            <th scope="row">{o?.id}</th>
                            <td>{o?.lrn}</td>
                            <td>{o?.guarantorName}</td>
                            <td>{o?.guarantorPhoneNumber}</td>
                            <td>{o?.projectName}</td>
                            <td>{o?.amount}</td>
                            <td>{moment(o?.createdDate).format("DD-MM-YYYY")}</td>
                            <td><button className="common_btn" onClick={() => onView(o)}>View</button></td>
                        </tr>
                    }) : <tr className="tr-shadow">
                        <td colSpan="8" className="text-center">
                            No data found
                        </td>
                    </tr>}
                </tbody>
            </table>
            {tab === 1 ? <Paginator
                pageDisplayed={Math.ceil(count) / 10}
                onChange={(e) => handlepage(e)}
                activePage={page} /> : <Paginator
                pageDisplayed={Math.ceil(Orgcount) / 10}
                onChange={(e) => handlepage(e)}
                activePage={Orgpage} />}

        </div>
        {
            isloading && <Loader />
        }
    </>

}
export default FormList;