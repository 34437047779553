import './App.css';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRouter';
import Login from './container/Login';
import Dashboard from './container/Dashboard';
import { ToastContainer } from 'react-toastify';
import Home from './container/Home';
import Form2 from './container/SecondForm';
import FormList from './container/Formlist';
import '../src/component/form1pdf/base.min.css';
import '../src/component/form1pdf/fancy.min.css';
import '../src/component/form1pdf/main.css';
function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/home' element={<Home />} />
          <Route path='/secondForm' element={<Form2 />} />
         <Route path='/formlist' element={<FormList />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
