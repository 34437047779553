import React, { useCallback, useEffect, useState } from 'react';
import { LoanDetailsValidateField } from '../utils/Validation';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from "../redux/action";
import { getagreementDetails, getAgreementDetailsList, getFormLoanDetailslist, loanDetailsDatas } from '../redux/reducer/FormListReducers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import Loader from './HOC/Loader';
const LoanDetails = ({ agreementDetails, onNext, isBack, setBack, isFormPage }) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const loanId = useSelector(getFormLoanDetailslist)
    const [isDisabled, setIsDisabled] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const getAgreementDetails = useSelector(getagreementDetails)
    const loanDetailsData = useSelector(loanDetailsDatas)
    const [errors, setErrors] = useState([]);
    const [isloading, setLoading] = useState(false)
    const [guaranteeStatusCode, setGuaranteeStatusCode] = useState([])
    const [borrowingPurposeCode, setBorrowingPurposeCode] = useState([])
    let [loanDetails, setLoanDetails] = useState({

        loanAgreementDate: '',
        effectiveDateOfLoan: '',
        lastDateOfDispersement: '',
        maturityDate: '',
        gracePeriodInYear: '',
        gracePeriodInMonth: '',
        currency: '',
        amount: '',
        equivalentAmount: '',

        foreignCurrencyExpenditure: '',
        rupeeExpenditure: '',
        hedgingDetailsType: '',

        financialHedge: '',
        naturalHedge: '',
        loanTotalHedge: '',
        isOptionForLoanAgreement: '',
        percentOfDebt: '',
        executedAfterDate: '',

        guarantorName: '',
        guarantorDesignation: '',
        guarantorPhoneNumber: '',
        guarantorFaxNumber: '',
        guarantorEmailId: '',
        guarantorStatusCode: '',
        isNatureAndDetailsOfSecurity: '',

        projectName: '',
        location: '',
        projectCost: '',
        moreThenOneCountry: '',
        projectIndustryCode: '',
        ecbType: '',

        oldEcbLrn: '',
        oldEcbApprovalNumber: '',
        oldEcbApprovalDate: '',
        oldEcbApprovalRefinanced: '',
        oldEcbReason: '',
        oldEcbOthers: '',

        firstPaymentDate: '',
        numberOfPaymentsPerYear: '',
        fixedRate: '',
        baseIthCurrency: '',
        marginCurrency: '',
        capRate: '',
        floorRate: '',
        isDraft: isDraft

    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        loanAgreementDate: false,
        effectiveDateOfLoan: false,
        lastDateOfDispersement: false,
        maturityDate: false,
        gracePeriodInYear: false,
        gracePeriodInMonth: false,
        currency: false,
        amount: false,
        equivalentAmount: false,

        foreignCurrencyExpenditure: false,
        rupeeExpenditure: false,
        hedgingDetailsType: false,

        financialHedge: false,
        naturalHedge: false,
        loanTotalHedge: false, //Use to manual calculation
        isOptionForLoanAgreement: false,
        percentOfDebt: false,
        executedAfterDate: false,

        guarantorName: false,
        guarantorDesignation: false,
        guarantorPhoneNumber: false,
        guarantorFaxNumber: false,
        guarantorEmailId: false,
        guarantorStatusCode: false,
        isNatureAndDetailsOfSecurity: false,

        projectName: false,
        location: false,
        projectCost: false,
        moreThenOneCountry: false,
        projectIndustryCode: false,
        ecbType: false,

        oldEcbLrn: false,
        oldEcbApprovalNumber: false,
        oldEcbApprovalDate: false,
        oldEcbApprovalRefinanced: false,
        oldEcbReason: false,
        oldEcbOthers: false,

        firstPaymentDate: false,
        numberOfPaymentsPerYear: false,
        fixedRate: false,

        baseIthCurrency: false,
        marginCurrency: false,
        capRate: false,
        floorRate: false,

    });

    const [editRowIndex, setEditRowIndex] = useState(null);
    const [editPrincipalRowIndex, setEditPrincipalRowIndex] = useState(null);
    const [editBorrowingPurposeRowIndex, setEditBorrowingPurposeRowIndex] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditPrincipal, setIsEditPrincipal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [formPrincipalErrors, setFormPrincipalErrors] = useState({});
    const [formBorrowingPurposeErrors, setFormBorrowingPurposeErrors] = useState({});
    const [drawdowns, setDrawdowns] = useState([]);
    const [draw, setDraw] = useState([]);
    const [borrowingPurpose, setborrowingPurpose] = useState([]);
    const [isEditBorrowingPurpose, setIsEditBorrowingPurpose] = useState(false);

    const [editRow, setEditRow] = useState({
        date: '',
        currency: '',
        amount: '',
        totalNoOfDrawals: '',
        totalNoOfDrawalsInCalendarYear: '',
    });
    const [editRowPrincipal, setEditRowPrincipal] = useState({
        date: '',
        currency: '',
        amount: '',
        totalNoOfPayment: '',
        totalNoOfPaymentInCalendarYear: '',
    });
    const [editRowBorrowingPurpose, setEditRowBorrowingPurpose] = useState({
        borrowingPurposeCodeId: '',
        borrowingPurposeCurrency: '',
        borrowingPurposeAmount: '',
        borrowingPurposePercentage: '',
    });



    const handleDrawChange = (index, field, value) => {
        if (isEditPrincipal) {
            const updatedDrawdowns = [...draw];
            updatedDrawdowns[index][field] = value;
            setDraw(updatedDrawdowns);
        } else {
            const updatedEditRow = { ...editRowPrincipal, [field]: value };
            setEditRowPrincipal(updatedEditRow);
        }
    };
    const handleDrawdownChange = (index, field, value) => {
        if (isEdit) {
            const updatedDrawdowns = [...drawdowns];
            updatedDrawdowns[index][field] = value;
            setDrawdowns(updatedDrawdowns);
        } else {
            const updatedEditRow = { ...editRow, [field]: value };
            setEditRow(updatedEditRow);
        }

    };

    const handleBorrowingPurposeChange = (index, field, value) => {
        if (isEditBorrowingPurpose) {
            const updatedBorrowingPurpose = [...borrowingPurpose];
            updatedBorrowingPurpose[index][field] = value;
            setborrowingPurpose(updatedBorrowingPurpose);
        } else {
            const updatedEditRowBorrowingPurpose = { ...editRowBorrowingPurpose, [field]: value };
            setEditRowBorrowingPurpose(updatedEditRowBorrowingPurpose);
        }
    };

    const handleSaveEdit = () => {
        const newErrors = {};
        let hasErrors = false;
        if (!editRow?.date) {
            newErrors.date = true;
            hasErrors = true;
        } if (!editRow?.currency) {
            newErrors.currency = true;
            hasErrors = true;
        } if (!editRow?.amount) {
            newErrors.amount = true;
            hasErrors = true;
        } if (!editRow?.totalNoOfDrawals) {
            newErrors.totalNoOfDrawals = true;
            hasErrors = true;
        } if (!editRow?.totalNoOfDrawalsInCalendarYear) {
            newErrors.totalNoOfDrawalsInCalendarYear = true;
            hasErrors = true;
        }

        // If there are errors, set the formErrors state and return
        if (hasErrors) {
            setFormErrors(newErrors);
            return;
        }

        if (isEdit) {
            setDrawdowns(prevArray => {
                const newArray = [...prevArray];
                newArray[editRowIndex] = editRow;
                return newArray;
            });
            setEditRow({ date: '', currency: '', amount: '', totalNoOfDrawals: '', totalNoOfDrawalsInCalendarYear: '' })
            setEditRowIndex('');
            setIsEdit(false)
            setFormErrors({})


        } else {
            if (drawdowns?.length > 0) {
                setDrawdowns([...drawdowns, editRow]);
                setEditRow({ date: '', currency: '', amount: '', totalNoOfDrawals: '', totalNoOfDrawalsInCalendarYear: '' })
            } else {
                setDrawdowns([editRow]);
                setEditRow({ date: '', currency: '', amount: '', totalNoOfDrawals: '', totalNoOfDrawalsInCalendarYear: '' })
            }

        }
        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                        setFormErrors({})
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...editRow,
                loanId: getAgreementDetails?.loanId,
                isDraft: isDraft
            };
            dispatch(Actions.createDrawdown(params, callback));
        }
    };
    const handleSavePrincipalEdit = () => {
        const newErrors = {};
        let hasErrors = false;
        if (!editRowPrincipal?.date) {
            newErrors.date = true;
            hasErrors = true;
        } if (!editRowPrincipal?.currency) {
            newErrors.currency = true;
            hasErrors = true;
        } if (!editRowPrincipal?.amount) {
            newErrors.amount = true;
            hasErrors = true;
        } if (!editRowPrincipal?.totalNoOfPayment) {
            newErrors.totalNoOfPayment = true;
            hasErrors = true;
        } if (!editRowPrincipal?.totalNoOfPaymentInCalendarYear) {
            newErrors.totalNoOfPaymentInCalendarYear = true;
            hasErrors = true;
        }

        // If there are errors, set the formErrors state and return
        if (hasErrors) {
            setFormPrincipalErrors(newErrors);
            return;
        }
        if (isEditPrincipal) {
            setDraw(prevArray => {
                const newArray = [...prevArray];
                newArray[editPrincipalRowIndex] = editRowPrincipal;
                return newArray;
            });
            setEditRowPrincipal({ date: '', currency: '', amount: '', totalNoOfPayment: '', totalNoOfPaymentInCalendarYear: '' })
            setEditPrincipalRowIndex('');
            setIsEditPrincipal(false)
            setFormPrincipalErrors({})


        } else {
            if (draw?.length > 0) {
                setDraw([...draw, editRowPrincipal]);
                setEditRowPrincipal({ date: '', currency: '', amount: '', totalNoOfPayment: '', totalNoOfPaymentInCalendarYear: '' })
            } else {
                setDraw([editRowPrincipal]);
                setEditRowPrincipal({ date: '', currency: '', amount: '', totalNoOfPayment: '', totalNoOfPaymentInCalendarYear: '' })
            }

        }
        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                        setFormPrincipalErrors({})
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...editRowPrincipal,
                loanId: getAgreementDetails?.loanId,
                isDraft: isDraft
            };
            dispatch(Actions.createRepayment(params, callback));


        }
    };
    const handleSaveEditBorrowingPurpose = () => {
        const newErrors = {};
        let hasErrors = false;
        if (!editRowBorrowingPurpose?.borrowingPurposeCodeId) {
            newErrors.borrowingPurposeCodeId = true;
            hasErrors = true;
        } if (!editRowBorrowingPurpose?.borrowingPurposeCurrency) {
            newErrors.borrowingPurposeCurrency = true;
            hasErrors = true;
        } if (!editRowBorrowingPurpose?.borrowingPurposeAmount) {
            newErrors.borrowingPurposeAmount = true;
            hasErrors = true;
        } if (!editRowBorrowingPurpose?.borrowingPurposePercentage) {
            newErrors.borrowingPurposePercentage = true;
            hasErrors = true;
        }

        // If there are errors, set the formErrors state and return
        if (hasErrors) {
            setFormBorrowingPurposeErrors(newErrors);
            return;
        }
        if (isEditBorrowingPurpose) {
            setborrowingPurpose(prevArray => {
                const newArray = [...prevArray];
                newArray[editBorrowingPurposeRowIndex] = editRowBorrowingPurpose;
                return newArray;
            });
            setEditRowBorrowingPurpose({ borrowingPurposeCodeId: '', borrowingPurposeCurrency: '', borrowingPurposeAmount: '', borrowingPurposePercentage: '' })
            setEditBorrowingPurposeRowIndex('');
            setIsEditBorrowingPurpose(false)
            setFormBorrowingPurposeErrors({})


        } else {
            if (borrowingPurpose?.length > 0) {
                setborrowingPurpose([...borrowingPurpose, editRowBorrowingPurpose]);
                setEditRowBorrowingPurpose({ borrowingPurposeCodeId: '', borrowingPurposeCurrency: '', borrowingPurposeAmount: '', borrowingPurposePercentage: '', })
            } else {
                setborrowingPurpose([editRowBorrowingPurpose]);
                setEditRowBorrowingPurpose({ borrowingPurposeCodeId: '', borrowingPurposeCurrency: '', borrowingPurposeAmount: '', borrowingPurposePercentage: '' })
            }

        }
        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                        setFormBorrowingPurposeErrors({})
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...editRowBorrowingPurpose,
                loanId: getAgreementDetails?.loanId,
                isDraft: isDraft
            };
            dispatch(Actions.createBorrowingPurpose(params, callback));

        }

    };
    const handlePrincipalEditRow = (index, row) => {
        setEditPrincipalRowIndex(index);
        setEditRowPrincipal(row);
        setIsEditPrincipal(true)
        setFormPrincipalErrors({})
    };
    const handleEditRow = (index, row) => {
        setEditRowIndex(index);
        setEditRow(row);
        setIsEdit(true)
        setFormErrors({})
    };
    const handleBorrowingPurposeEditRow = (index, row) => {
        setEditBorrowingPurposeRowIndex(index);
        setEditRowBorrowingPurpose(row)
        setIsEditBorrowingPurpose(true)
        setFormBorrowingPurposeErrors({})
    };
    const handleDelete = (index, drawdown) => {

        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...drawdown,
                loanId: getAgreementDetails?.loanId,
                isActive: 0,
                isDraft: isDraft
            };
            dispatch(Actions.createDrawdown(params, callback));


        } else {
            const updatedDraw = [...drawdowns];
            updatedDraw.splice(index, 1);
            setDrawdowns(updatedDraw);
        }
    };
    const handlePrincipalDelete = (index, drawdown) => {
        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...drawdown,
                loanId: getAgreementDetails?.loanId,
                isActive: 0,
                isDraft: isDraft
            };
            dispatch(Actions.createRepayment(params, callback));


        } else {
            const updatedDraw = [...draw];
            updatedDraw.splice(index, 1);
            setDraw(updatedDraw);
        }

    };


    const handleDeleteBorrowingPurpose = (index, borrowingPurpose) => {

        if (agreementDetails?.ecbApplicationType?.toString() === '2') {
            setLoading(true);
            const callback = (res) => {
                const loanCallback = (res) => {
                    setLoading(false);
                    if (!res.error) {
                        setLoanDetails(res?.response)
                        setDrawdowns(res?.response?.DraftDrawdownSchedules);
                        setDraw(res?.response?.DraftPrincipalSchedules);
                        setborrowingPurpose(res?.response?.DraftNatureDetailsSecurities);
                    } else if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                const params = {
                    loanId: getAgreementDetails?.loanId,
                    isDraft: isDraft
                }
                dispatch(Actions.GetLoanDetails(params, loanCallback))
            };
            let params = {
                ...borrowingPurpose,
                loanId: getAgreementDetails?.loanId,
                isActive: 0,
                isDraft: isDraft
            };
            dispatch(Actions.createBorrowingPurpose(params, callback));


        } else {
            const updatedBorrowingPurpose = [...borrowingPurpose];
            updatedBorrowingPurpose.splice(index, 1);
            setborrowingPurpose(updatedBorrowingPurpose);
        }
    };

    const handleFieldChange = (field, value) => {
        switch (field) {
            case 'guarantorName':
                value = value.toUpperCase();
                break;
            case 'guarantorDesignation':
                value = value.toUpperCase();
                break;
            default:
                break;
        }
        if (field === 'financialHedge' || field === 'naturalHedge') {
            const newValue = parseInt(value);
            const updatedDetails = {
                ...loanDetails,
                [field]: newValue,
                loanTotalHedge: field === 'financialHedge' ? newValue + loanDetails.naturalHedge : loanDetails.financialHedge + newValue
            };
            setLoanDetails(updatedDetails);
        } else {
            setLoanDetails((prevData) => ({
                ...prevData,
                [field]: value,
            }));
            setFieldChangedStatus((prevStatus) => ({
                ...prevStatus,
                [field]: true,
            }));
        }
    };

    // useEffect(() => {
    //     if (loanDetailsData) {
    //         setLoanDetails(loanDetailsData)
    //         setDrawdowns(loanDetailsData?.repayment);
    //         setDraw(loanDetailsData?.drawdown);
    //         setborrowingPurpose(loanDetailsData.natureOfCharge);
    //     }
    // }, [dispatch, loanDetailsData])

    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        if ((agreementDetails?.ecbApplicationType?.toString() === '2' || agreementDetailsList?.loanId) || (isBack && (Array.isArray(loanDetailsData) ? false : true))) {
            const callback = (res) => {
                setLoading(false)
                if (!res.error) {
                    setLoanDetails(res?.response)
                    setDrawdowns(res?.response?.DrawdownSchedules || res?.response?.DraftDrawdownSchedules);
                    setDraw(res?.response?.PrincipalSchedules || res?.response?.DraftPrincipalSchedules);
                    setborrowingPurpose(res?.response?.NatureDetailsSecurities || res?.response?.DraftNatureDetailsSecurities);
                } else if (res?.error) {
                    const { data } = res.error?.response || {}
                    toast.error(`${data?.response_message}`)
                } else {
                    toast.error("Sorry somethiing went wrong")
                }
            }

            const params = {
                loanId: isBack ? loanId?.id : getAgreementDetails?.loanId ?? agreementDetailsList?.loanId,
                isDraft: isDraft
            }
            setLoading(true)
            dispatch(Actions.GetLoanDetails(params, callback))
        }
        getGuaranteeStatusCode();
        getBorrowingPurposeCode();
    }, [dispatch])

    const getGuaranteeStatusCode = () => {
        const callback = (res) => {
            if (!res.error) {
                setGuaranteeStatusCode(res?.response)
            }
        }
        dispatch(Actions.getGuaranteeStatusCode('', callback))
    }

    const getBorrowingPurposeCode = () => {
        const callback = (res) => {
            if (!res.error) {
                setBorrowingPurposeCode(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        }
        dispatch(Actions.getBorrowingPurposeCode('', callback))
    }
    // const handleInterestFieldChange = (fieldName, value) => {
    //     setLoanDetails((prevData) => ({
    //       ...prevData,
    //       [fieldName]: value,
    //     }));

    //     // Validate the field onChange
    //     if (fieldName === 'firstPaymentDate' || fieldName === 'numberOfPayments' || fieldName === 'fixedRate' ||
    //         fieldName === 'baseCurrency' || fieldName === 'margin' || fieldName === 'capRate' || fieldName === 'floorRate') {
    //             setFieldChangedStatus((prevErrors) => ({
    //         ...prevErrors,
    //         [fieldName]: value === '',
    //       }));
    //     }
    //   };
    const handleOriginalNext = () => {
        onNext("loanDetails")
    }
    const handleOriginalBack = () => {
        onNext('agreementDetails')
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        if (loanDetails?.ecbType != 9) {
            delete loanDetails?.oldEcbLrn;
            delete loanDetails?.oldEcbApprovalNumber;
            delete loanDetails?.oldEcbApprovalDate;
            delete loanDetails?.oldEcbApprovalRefinanced;
            delete loanDetails?.oldEcbReason;
        }
        for (const field in loanDetails) {
            if (field === 'oldEcbOthers') {
                // Skip validation for 'oldEcbOthers' field
                newErrors[field] = false;
                continue;
            }
            if (loanDetails[field] === '' || loanDetails[field] === null) {
                if (field === 'oldEcbOthers' || field === 'userId' || field === 'updatedDate') {
                    // Skip validation for 'oldEcbOthers' field
                    newErrors[field] = false;
                    continue;
                }
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            console.log('no error')
            const callback = (res) => {
                if (res?.response_code === 0) {
                    onNext('loanDetails')
                } else {
                    toast.error(res?.response?.data?.response_message)
                }
                setLoading(false);
            };
            if (agreementDetails?.ecbApplicationType?.toString() === "2") {
                const params = {
                    ...loanDetails,
                    isDraft: isDraft
                }
                dispatch(Actions.FormLoanDetailsList(params, callback));

            } else {

                let params = {
                    ...loanDetails,
                    agreementDetails,
                    drawdown: drawdowns,
                    repayment: draw,
                    natureOfCharge: borrowingPurpose,
                    isDraft: isDraft
                }
                dispatch(Actions.loanDetailsData(params))
                dispatch(Actions.FormLoanDetailsList(params, callback));
            }
            // Perform form submission logic here
        }
    };
    return (
        <React.Fragment>
            <div className="py-3">
                {
                    isloading && <Loader />
                }
                <div className="row mt-4">
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Loan Agreement Date:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    placeholder="Date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={moment(loanDetails?.loanAgreementDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('loanAgreementDate', e.target.value)}
                                />
                                {fieldChangedStatus?.loanAgreementDate && LoanDetailsValidateField('loanAgreementDate', loanDetails.loanAgreementDate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('loanAgreementDate', loanDetails.loanAgreementDate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Effective Date of the Loan:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    placeholder="Date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={moment(loanDetails?.effectiveDateOfLoan).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('effectiveDateOfLoan', e.target.value)}
                                />
                                {fieldChangedStatus?.effectiveDateOfLoan && LoanDetailsValidateField('effectiveDateOfLoan', loanDetails.effectiveDateOfLoan) && (
                                    <span className="text-danger">{LoanDetailsValidateField('effectiveDateOfLoan', loanDetails.effectiveDateOfLoan)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Last Date of Disbursement:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    placeholder="Date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={moment(loanDetails?.lastDateOfDispersement).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('lastDateOfDispersement', e.target.value)}
                                />
                                {fieldChangedStatus?.lastDateOfDispersement && LoanDetailsValidateField('lastDateOfDispersement', loanDetails.lastDateOfDispersement) && (
                                    <span className="text-danger">{LoanDetailsValidateField('lastDateOfDispersement', loanDetails.lastDateOfDispersement)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Maturity Date: <span className="note_info">(Last payment date)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    placeholder="Date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={moment(loanDetails?.maturityDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('maturityDate', e.target.value)}
                                />
                                {fieldChangedStatus?.maturityDate && LoanDetailsValidateField('maturityDate', loanDetails.maturityDate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('maturityDate', loanDetails.maturityDate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Grace period Years:<span className="note_info">(if in agreement)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Years"
                                    value={loanDetails.gracePeriodInYear}
                                    onChange={(e) => handleFieldChange('gracePeriodInYear', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.gracePeriodInYear && LoanDetailsValidateField('gracePeriodInYear', loanDetails.gracePeriodInYear) && (
                                    <span className="text-danger">{LoanDetailsValidateField('gracePeriodInYear', loanDetails.gracePeriodInYear)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Grace period Months: <span className="note_info">(if in agreement)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Months"
                                    value={loanDetails.gracePeriodInMonth}
                                    onChange={(e) => handleFieldChange('gracePeriodInMonth', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.gracePeriodInMonth && LoanDetailsValidateField('gracePeriodInMonth', loanDetails.gracePeriodInMonth) && (
                                    <span className="text-danger">{LoanDetailsValidateField('gracePeriodInMonth', loanDetails.gracePeriodInMonth)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Currency Name:</label>
                            <div className="col-md-8">
                                <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} value={loanDetails.currency}
                                    onChange={(e) => handleFieldChange('currency', e.target.value)}
                                >
                                    <option hidden>Select Currency</option>
                                    <option value="AUD">AUD</option>
                                    <option value="EUR">EUR</option>
                                    <option value="INR">INR</option>
                                    <option value="LKR">LKR</option>
                                    <option value="MAD">MAD</option>
                                    <option value="MXN">MXN</option>
                                    <option value="SGD">SGD</option>
                                    <option value="USD">USD</option>
                                </select>
                                {fieldChangedStatus.currency && LoanDetailsValidateField('currency', loanDetails.currency) && (
                                    <span className="text-danger">{LoanDetailsValidateField('currency', loanDetails.currency)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Amount: <span className="note_info">(in Foreign Currency)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Amount"
                                    value={loanDetails.amount}
                                    onChange={(e) => handleFieldChange('amount', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.amount && LoanDetailsValidateField('amount', loanDetails.amount) && (
                                    <span className="text-danger">{LoanDetailsValidateField('amount', loanDetails.amount)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Equivalent Amount: <span className="note_info">(in US Dollars)(as on date of this form)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Amount"
                                    value={loanDetails.equivalentAmount}
                                    onChange={(e) => handleFieldChange('equivalentAmount', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.equivalentAmount && LoanDetailsValidateField('equivalentAmount', loanDetails.equivalentAmount) && (
                                    <span className="text-danger">{LoanDetailsValidateField('equivalentAmount', loanDetails.equivalentAmount)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">
                    <h5 className="pb-4">Proposed Bifurcation of the amount (in loan currency)</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Foreign Currency Expenditure:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Expenditure"
                                    value={loanDetails?.foreignCurrencyExpenditure}
                                    onChange={(e) => handleFieldChange('foreignCurrencyExpenditure', e?.target?.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus?.foreignCurrencyExpenditure && LoanDetailsValidateField('foreignCurrencyExpenditure', loanDetails?.foreignCurrencyExpenditure) && (
                                    <span className="text-danger">{LoanDetailsValidateField('foreignCurrencyExpenditure', loanDetails?.foreignCurrencyExpenditure)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Rupee Expenditure:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Expenditure"
                                    value={loanDetails?.rupeeExpenditure}
                                    onChange={(e) => handleFieldChange('rupeeExpenditure', e?.target?.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus?.rupeeExpenditure && LoanDetailsValidateField('rupeeExpenditure', loanDetails?.rupeeExpenditure) && (
                                    <span className="text-danger">{LoanDetailsValidateField('rupeeExpenditure', loanDetails?.rupeeExpenditure)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Hedging details:</label>
                            <div className="col-md-8">
                                <div className="mt-2 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={1}
                                        checked={loanDetails?.hedgingDetailsType === 1}
                                        onChange={() => handleFieldChange('hedgingDetailsType', 1)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Currency Swap</label>
                                </div>
                                <div className="mt-2 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={2}
                                        checked={loanDetails?.hedgingDetailsType === 2}
                                        onChange={() => handleFieldChange('hedgingDetailsType', 2)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Interest Rate Swap</label>

                                </div>
                                <div className="mt-2 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={3}
                                        checked={loanDetails?.hedgingDetailsType === 3}
                                        onChange={() => handleFieldChange('hedgingDetailsType', 3)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Others</label>
                                </div>
                                <div className="mt-2 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={4}
                                        checked={loanDetails?.hedgingDetailsType === 4}
                                        onChange={() => handleFieldChange('hedgingDetailsType', 4)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Unhedged</label>
                                </div>
                                {fieldChangedStatus?.hedgingDetailsType && LoanDetailsValidateField('hedgingDetailsType', loanDetails.hedgingDetailsType) && (
                                    <span className="text-danger">{LoanDetailsValidateField('hedgingDetailsType', loanDetails.hedgingDetailsType)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="pb-4">Hedging percentage (proposed)</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Financial Hedge:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.financialHedge}
                                    onChange={(e) => handleFieldChange('financialHedge', e.target.value)}
                                    placeholder="Financial Hedge"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.financialHedge && LoanDetailsValidateField('financialHedge', loanDetails.financialHedge) && (
                                    <span className="text-danger">{LoanDetailsValidateField('financialHedge', loanDetails?.financialHedge)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Natural Hedge:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.naturalHedge}
                                    onChange={(e) => handleFieldChange('naturalHedge', e.target.value)}
                                    placeholder="Natural Hedge"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.naturalHedge && LoanDetailsValidateField('naturalHedge', loanDetails.naturalHedge) && (
                                    <span className="text-danger">{LoanDetailsValidateField('naturalHedge', loanDetails?.naturalHedge)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Total Hedge:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled
                                    value={loanDetails?.loanTotalHedge ?? (loanDetails?.naturalHedge + loanDetails?.financialHedge)}
                                    onChange={(e) => handleFieldChange('loanTotalHedge', e.target.value)}
                                    placeholder="Total Hedge"

                                />
                                {fieldChangedStatus.loanTotalHedge && LoanDetailsValidateField('loanTotalHedge', loanDetails.loanTotalHedge) && (
                                    <span className="text-danger">{LoanDetailsValidateField('loanTotalHedge', loanDetails?.loanTotalHedge)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">In case options are provided in the loan agreement:</label>
                            <div className="d-flex col-md-8">
                                <div className="col-md-6 col-4 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={1}
                                        checked={loanDetails?.isOptionForLoanAgreement === 1}
                                        onChange={() => handleFieldChange('isOptionForLoanAgreement', 1)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Call Option</label>
                                </div>
                                <div className="col-md-6 col-4 form-check">
                                    <input
                                        className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        type="radio"
                                        value={2}
                                        checked={loanDetails?.isOptionForLoanAgreement === 2}
                                        onChange={() => handleFieldChange('isOptionForLoanAgreement', 2)}
                                    />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Put Option</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head"> Percent of Debt:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.percentOfDebt}
                                    onChange={(e) => handleFieldChange('percentOfDebt', e.target.value)}
                                    placeholder="Percentage"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.percentOfDebt && LoanDetailsValidateField('percentOfDebt', loanDetails.percentOfDebt) && (
                                    <span className="text-danger">{LoanDetailsValidateField('percentOfDebt', loanDetails?.percentOfDebt)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Can be executed after date:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={moment(loanDetails?.executedAfterDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('executedAfterDate', e.target.value)}
                                    placeholder="date"
                                />
                                {fieldChangedStatus.executedAfterDate && LoanDetailsValidateField('executedAfterDate', loanDetails.executedAfterDate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('executedAfterDate', loanDetails?.executedAfterDate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="pb-4">Name and address of the Guarantor (in Block letters)</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Contact Official’s Name:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorName}
                                    onChange={(e) => handleFieldChange('guarantorName', e.target.value)}
                                    placeholder="Enter Name"
                                    maxLength={50}
                                />
                                {fieldChangedStatus.guarantorName && LoanDetailsValidateField('guarantorName', loanDetails.guarantorName) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorName', loanDetails?.guarantorName)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Designation:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorDesignation}
                                    onChange={(e) => handleFieldChange('guarantorDesignation', e.target.value)}
                                    placeholder="Enter Designation"
                                    maxLength={50}
                                />
                                {fieldChangedStatus.guarantorDesignation && LoanDetailsValidateField('guarantorDesignation', loanDetails.guarantorDesignation) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorDesignation', loanDetails?.guarantorDesignation)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Phone No:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorPhoneNumber}
                                    onChange={(e) => handleFieldChange('guarantorPhoneNumber', e.target.value)}
                                    placeholder="Enter Phone No"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.guarantorPhoneNumber && LoanDetailsValidateField('guarantorPhoneNumber', loanDetails.guarantorPhoneNumber) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorPhoneNumber', loanDetails?.guarantorPhoneNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Fax No:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorFaxNumber}
                                    onChange={(e) => handleFieldChange('guarantorFaxNumber', e.target.value)}
                                    placeholder="Enter Fax No"
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.guarantorFaxNumber && LoanDetailsValidateField('guarantorFaxNumber', loanDetails.guarantorFaxNumber) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorFaxNumber', loanDetails?.guarantorFaxNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">E-mail id:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorEmailId}
                                    onChange={(e) => handleFieldChange('guarantorEmailId', e.target.value)}
                                    placeholder="Enter E-mail id"
                                />
                                {fieldChangedStatus.guarantorEmailId && LoanDetailsValidateField('guarantorEmailId', loanDetails.guarantorEmailId) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorEmailId', loanDetails?.guarantorEmailId)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Guarantee Status Code:</label>
                            <div className="col-md-8">
                                <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails?.guarantorStatusCode} onChange={(e) => handleFieldChange('guarantorStatusCode', e.target.value)}
                                    placeholder="Enter status code">
                                    <option hidden>Select Guarantee Status Code</option>
                                    {guaranteeStatusCode?.map((val) => {
                                        return <option value={val.id}>{val.code}</option>
                                    })}
                                </select>
                                {fieldChangedStatus.guarantorStatusCode && LoanDetailsValidateField('guarantorStatusCode', loanDetails.guarantorStatusCode) && (
                                    <span className="text-danger">{LoanDetailsValidateField('guarantorStatusCode', loanDetails?.guarantorStatusCode)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Nature and details of security:</label>
                            <div className="col-md-8">
                                <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    value={loanDetails.isNatureAndDetailsOfSecurity}
                                    onChange={(e) => handleFieldChange('isNatureAndDetailsOfSecurity', e.target.value)}
                                >
                                    <option hidden>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>
                                </select>
                                {fieldChangedStatus.isNatureAndDetailsOfSecurity && LoanDetailsValidateField('isNatureAndDetailsOfSecurity', loanDetails.isNatureAndDetailsOfSecurity) && (
                                    <span className="text-danger">{LoanDetailsValidateField('isNatureAndDetailsOfSecurity', loanDetails?.isNatureAndDetailsOfSecurity)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="pb-4">End-use (% share if more than one end-use)</h5>
                    <div className="row">
                        <div className='col-md-12 d-flex justify-content-between align-items-center'>
                            <label className="pb-3 form-label label_head">Borrowing Purpose:</label>
                            {isDisabled == false && <button className="next_btn" onClick={handleSaveEditBorrowingPurpose}>
                                Add
                            </button>}</div>
                        {isDisabled == false &&
                            <div className="row">
                                <div className="col-md-3">
                                    <label className="col-md-3 pb-1 form-label label_head">Code:</label>
                                    <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        value={editRowBorrowingPurpose?.borrowingPurposeCodeId}
                                        onChange={(e) => handleBorrowingPurposeChange(editBorrowingPurposeRowIndex, 'borrowingPurposeCodeId', e.target.value)}
                                        placeholder="Enter status code">
                                        <option hidden>Select</option>
                                        {borrowingPurposeCode?.map((val) => {
                                            return <option value={val.id}>{val.code}</option>
                                        })}
                                    </select>
                                    {formBorrowingPurposeErrors?.borrowingPurposeCodeId && <span className="text-danger">Code is required.</span>}
                                </div>
                                <div className="col-md-3">
                                    <label className="col-md-12 pb-1 form-label label_head">Currency:</label>
                                    <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        value={editRowBorrowingPurpose?.borrowingPurposeCurrency}
                                        onChange={(e) => handleBorrowingPurposeChange(editBorrowingPurposeRowIndex, 'borrowingPurposeCurrency', e.target.value)}>
                                        <option hidden>Select Currency</option>
                                        <option value="AUD">AUD</option>
                                        <option value="EUR">EUR</option>
                                        <option value="INR">INR</option>
                                        <option value="LKR">LKR</option>
                                        <option value="MAD">MAD</option>
                                        <option value="MXN">MXN</option>
                                        <option value="SGD">SGD</option>
                                        <option value="USD">USD</option>
                                    </select>

                                    {formBorrowingPurposeErrors?.borrowingPurposeCurrency && <span className="text-danger">Currency is required.</span>}
                                </div>
                                <div className="col-md-3">
                                    <label className="col-md-12 pb-1 form-label label_head">Amount:</label>
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        value={editRowBorrowingPurpose?.borrowingPurposeAmount}
                                        onChange={(e) => handleBorrowingPurposeChange(editBorrowingPurposeRowIndex, 'borrowingPurposeAmount', e.target.value)}
                                        placeholder="Enter amount"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 7)
                                        }}
                                    />
                                    {formBorrowingPurposeErrors?.borrowingPurposeAmount && <span className="text-danger">Amount is required.</span>}

                                </div>
                                <div className="col-md-3">
                                    <label className="col-md-12 pb-1 form-label label_head">Percentage:</label>
                                    <input
                                        type="number"
                                        className={`form-control ${isDisabled ? 'disable' : ''}`}
                                        disabled={isDisabled}
                                        value={editRowBorrowingPurpose?.borrowingPurposePercentage}
                                        onChange={(e) => handleBorrowingPurposeChange(editBorrowingPurposeRowIndex, 'borrowingPurposePercentage', e.target.value)}
                                        placeholder="Enter percentage"
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                        }}
                                    />
                                    {formBorrowingPurposeErrors?.borrowingPurposePercentage && <span className="text-danger">Percentage is required.</span>}

                                </div>
                            </div>}
                    </div>
                </div>
                {borrowingPurpose?.length > 0 && <div className="row mt-4">
                    <table>
                        <thead>
                            <tr>
                                <th className="col-1">S. No.</th>
                                <th className="col-2">Code </th>
                                <th className="col-2">Currency</th>
                                <th className="col-2">Amount</th>
                                <th className="col-2">Percentage</th>
                                {isDisabled == false && <th className="col-6" colSpan="2">Action</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {borrowingPurpose?.map((borrowingPurpose, index) => {
                                let code = borrowingPurposeCode.find((e) => e.id == borrowingPurpose.borrowingPurposeCodeId)
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {code?.code}
                                    </td>
                                    <td>
                                        {borrowingPurpose.borrowingPurposeCurrency}
                                    </td>
                                    <td>
                                        {borrowingPurpose.borrowingPurposeAmount}
                                    </td>
                                    <td>
                                        {borrowingPurpose.borrowingPurposePercentage}
                                    </td>
                                    {isDisabled == false && <td><button className="btn btn-danger" onClick={() => handleBorrowingPurposeEditRow(index, borrowingPurpose)}>
                                        edit
                                    </button></td>}
                                    {isDisabled == false && <td><button className="btn btn-danger" onClick={() => handleDeleteBorrowingPurpose(index, borrowingPurpose)}>
                                        delete
                                    </button> </td>}
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
                {borrowingPurpose?.length === 0 && <div className="row mt-4">
                    <table>
                        <thead>
                            <tr>
                                <th className="col-1">S No.</th>
                                <th className="col-2">Code </th>
                                <th className="col-2">Currency</th>
                                <th className="col-2">Amount</th>
                                <th className="col-2">Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td colSpan="6">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
                <div className="row mt-4">
                    <h5 className="pb-4">Project Details (Name, Location and Cost):</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Name:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Name"
                                    value={loanDetails?.projectName}
                                    onChange={(e) => handleFieldChange('projectName', e.target.value)}
                                />
                                {fieldChangedStatus.projectName && LoanDetailsValidateField('projectName', loanDetails.projectName) && (
                                    <span className="text-danger">{LoanDetailsValidateField('projectName', loanDetails?.projectName)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Location:</label>
                            <div className="col-md-8">
                                <input type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Location"
                                    value={loanDetails?.location}
                                    onChange={(e) => handleFieldChange('location', e.target.value)}
                                />
                                {fieldChangedStatus.location && LoanDetailsValidateField('location', loanDetails.location) && (
                                    <span className="text-danger">{LoanDetailsValidateField('location', loanDetails?.location)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Cost:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter cost"
                                    value={loanDetails?.projectCost}
                                    onChange={(e) => handleFieldChange('projectCost', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.projectCost && LoanDetailsValidateField('projectCost', loanDetails.projectCost) && (
                                    <span className="text-danger">{LoanDetailsValidateField('projectCost', loanDetails?.projectCost)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">If import, specify the Country of import:<span className="note_info"> (if more than one country, attach details as Annex)</span> </label>
                            <div className="col-md-8">
                                {/* <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.moreThenOneCountry}
                                    onChange={(e) => handleFieldChange('moreThenOneCountry', e.target.value)}
                                /> */}
                                <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} value={loanDetails?.moreThenOneCountry}
                                    onChange={(e) => handleFieldChange('moreThenOneCountry', e.target.value)}>
                                    <option hidden>Select</option>
                                    <option value="1">Yes</option>
                                    <option value="2">No</option>

                                </select>
                                {fieldChangedStatus.moreThenOneCountry && LoanDetailsValidateField('moreThenOneCountry', loanDetails.moreThenOneCountry) && (
                                    <span className="text-danger">{LoanDetailsValidateField('moreThenOneCountry', loanDetails?.moreThenOneCountry)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Industry Code: <span className="note_info">(as per NIC-2008)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.projectIndustryCode}
                                    onChange={(e) => handleFieldChange('projectIndustryCode', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                />
                                {fieldChangedStatus.projectIndustryCode && LoanDetailsValidateField('projectIndustryCode', loanDetails.projectIndustryCode) && (
                                    <span className="text-danger">{LoanDetailsValidateField('projectIndustryCode', loanDetails?.projectIndustryCode)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-3">
                        <div className="row">
                            <label className="col-md-2 form-label label_head mt-4">Type of ECB: <span className="note_info"></span></label>
                            <div className="col-md-10 mt-4">
                                <div className="row">
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={1}
                                            checked={loanDetails?.ecbType === 1}
                                            onChange={() => handleFieldChange('ecbType', 1)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Buyers’ Credit</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={2}
                                            checked={loanDetails?.ecbType === 2}
                                            onChange={() => handleFieldChange('ecbType', 2)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Commercial Loan / Syndicated Loan (attach sheet for percentage distribution among lenders)</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={3}
                                            checked={loanDetails?.ecbType === 3}
                                            onChange={() => handleFieldChange('ecbType', 3)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Suppliers’ Credit</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={4}
                                            checked={loanDetails?.ecbType === 4}
                                            onChange={() => handleFieldChange('ecbType', 4)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Export Credit from Bilateral Sources</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={5}
                                            checked={loanDetails?.ecbType === 5}
                                            onChange={() => handleFieldChange('ecbType', 5)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Line of Credit</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={6}
                                            checked={loanDetails?.ecbType === 6}
                                            onChange={() => handleFieldChange('ecbType', 6)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Securitized Instruments (Bonds, CP, FRN, etc.)</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={7}
                                            checked={loanDetails?.ecbType === 7}
                                            onChange={() => handleFieldChange('ecbType', 7)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Financial Lease</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={8}
                                            checked={loanDetails?.ecbType === 8}
                                            onChange={() => handleFieldChange('ecbType', 8)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>FCCB, FCEB, Non-Convertible Preference Shares, Optionally Convertible Preference Shares, Partially Convertible Preference Shares</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={9}
                                            checked={loanDetails?.ecbType === 9}
                                            onChange={() => handleFieldChange('ecbType', 9)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Refinancing of old ECBs</label>
                                    </div>
                                    <div className="col-md-5 form-check mt-2">
                                        <input
                                            className={`form-check-input  ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            type="radio"
                                            value={10}
                                            checked={loanDetails?.ecbType === 10}
                                            onChange={() => handleFieldChange('ecbType', 10)}
                                        />
                                        <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Others:</label>

                                        {loanDetails.ecbType == 10 &&
                                            <div className="col-md-12">
                                                <textarea
                                                    type="text"
                                                    className={`text_area_height form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Type here"
                                                    value={loanDetails?.oldEcbOthers}
                                                    onChange={(e) => handleFieldChange('oldEcbOthers', e.target.value)}
                                                />
                                                {fieldChangedStatus.oldEcbOthers && LoanDetailsValidateField('oldEcbOthers', loanDetails.oldEcbOthers) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbOthers', loanDetails?.oldEcbOthers)}</span>
                                                )}
                                            </div>}</div>
                                    {fieldChangedStatus?.ecbType && LoanDetailsValidateField('ecbType', loanDetails.ecbType) && (
                                        <span className="text-danger">{LoanDetailsValidateField('ecbType', loanDetails.ecbType)}</span>
                                    )}
                                </div>
                            </div>


                            {loanDetails.ecbType == 9 &&
                                <div className="row mt-4">
                                    {/* <h5 className="pb-4">Refinancing of old ECBs</h5> */}
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">LRN of the old ECB:</label>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Enter old LRN"
                                                    value={loanDetails?.oldEcbLrn}
                                                    onChange={(e) => handleFieldChange('oldEcbLrn', e.target.value)}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 9)
                                                    }}
                                                />
                                                {fieldChangedStatus.oldEcbLrn && LoanDetailsValidateField('oldEcbLrn', loanDetails.oldEcbLrn) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbLrn', loanDetails?.oldEcbLrn)}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Approval No:</label>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Enter Approval No"
                                                    value={loanDetails?.oldEcbApprovalNumber}
                                                    onChange={(e) => handleFieldChange('oldEcbApprovalNumber', e.target.value)}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }}
                                                />
                                                {fieldChangedStatus.oldEcbApprovalNumber && LoanDetailsValidateField('oldEcbApprovalNumber', loanDetails.oldEcbApprovalNumber) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbApprovalNumber', loanDetails?.oldEcbApprovalNumber)}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Date of Approval:</label>
                                            <div className="col-md-8">
                                                <input
                                                    type="date"
                                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Type here"
                                                    value={moment(loanDetails?.oldEcbApprovalDate).format('YYYY-MM-DD')}
                                                    onChange={(e) => handleFieldChange('oldEcbApprovalDate', e.target.value)}
                                                />
                                                {fieldChangedStatus.oldEcbApprovalDate && LoanDetailsValidateField('oldEcbApprovalDate', loanDetails.oldEcbApprovalDate) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbApprovalDate', loanDetails?.oldEcbApprovalDate)}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Amount refinanced:</label>
                                            <div className="col-md-8">
                                                <input
                                                    type="number"
                                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Enter Amount"
                                                    value={loanDetails?.oldEcbApprovalRefinanced}
                                                    onChange={(e) => handleFieldChange('oldEcbApprovalRefinanced', e.target.value)}
                                                    onInput={(e) => {
                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                    }}
                                                />
                                                {fieldChangedStatus.oldEcbApprovalRefinanced && LoanDetailsValidateField('oldEcbApprovalRefinanced', loanDetails.oldEcbApprovalRefinanced) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbApprovalRefinanced', loanDetails?.oldEcbApprovalRefinanced)}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <div className="row">
                                            <label className="col-md-4 form-label label_head">Reason:</label>
                                            <div className="col-md-8">
                                                <textarea
                                                    type="text"
                                                    className={`text_area_height form-control ${isDisabled ? 'disable' : ''}`}
                                                    disabled={isDisabled}
                                                    placeholder="Type here"
                                                    value={loanDetails?.oldEcbReason}
                                                    onChange={(e) => handleFieldChange('oldEcbReason', e.target.value)}
                                                />
                                                {fieldChangedStatus.oldEcbReason && LoanDetailsValidateField('oldEcbReason', loanDetails.oldEcbReason) && (
                                                    <span className="text-danger">{LoanDetailsValidateField('oldEcbReason', loanDetails?.oldEcbReason)}</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="pb-4">Interest Payment Schedule – Interest Free loan</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">First Payment Date:</label>
                            <div className="col-md-8">
                                <input
                                    type="date"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={moment(loanDetails?.firstPaymentDate).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('firstPaymentDate', e.target.value)}
                                />
                                {fieldChangedStatus.firstPaymentDate && LoanDetailsValidateField('firstPaymentDate', loanDetails.firstPaymentDate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('firstPaymentDate', loanDetails?.firstPaymentDate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">No.of payments/year:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter no of payments"
                                    value={loanDetails?.numberOfPaymentsPerYear}
                                    onChange={(e) => handleFieldChange('numberOfPaymentsPerYear', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2)
                                    }}
                                />
                                {fieldChangedStatus.numberOfPaymentsPerYear && LoanDetailsValidateField('numberOfPaymentsPerYear', loanDetails.numberOfPaymentsPerYear) && (
                                    <span className="text-danger">{LoanDetailsValidateField('numberOfPaymentsPerYear', loanDetails?.numberOfPaymentsPerYear)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head mt-3">Fixed Rate:</label>
                            <div className="col-md-8 mt-3">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter fixed rate"
                                    value={loanDetails?.fixedRate}
                                    onChange={(e) => handleFieldChange('fixedRate', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                    }}
                                />
                                {fieldChangedStatus.fixedRate && LoanDetailsValidateField('fixedRate', loanDetails.fixedRate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('fixedRate', loanDetails?.fixedRate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <h5 className="pb-4">Floating Rate</h5>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Base ith currency:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.baseIthCurrency}
                                    onChange={(e) => handleFieldChange('baseIthCurrency', e.target.value)}
                                />
                                {fieldChangedStatus.baseIthCurrency && LoanDetailsValidateField('baseIthCurrency', loanDetails.baseIthCurrency) && (
                                    <span className="text-danger">{LoanDetailsValidateField('baseIthCurrency', loanDetails?.baseIthCurrency)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Margin:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.marginCurrency}
                                    onChange={(e) => handleFieldChange('marginCurrency', e.target.value)}
                                />
                                {fieldChangedStatus.marginCurrency && LoanDetailsValidateField('marginCurrency', loanDetails.marginCurrency) && (
                                    <span className="text-danger">{LoanDetailsValidateField('marginCurrency', loanDetails?.marginCurrency)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Cap Rate:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.capRate}
                                    onChange={(e) => handleFieldChange('capRate', e.target.value)}
                                />
                                {fieldChangedStatus.capRate && LoanDetailsValidateField('capRate', loanDetails.capRate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('capRate', loanDetails?.capRate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Floor Rate:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Type here"
                                    value={loanDetails?.floorRate}
                                    onChange={(e) => handleFieldChange('floorRate', e.target.value)}
                                />
                                {fieldChangedStatus.floorRate && LoanDetailsValidateField('floorRate', loanDetails.floorRate) && (
                                    <span className="text-danger">{LoanDetailsValidateField('floorRate', loanDetails?.floorRate)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="row mt-4">
                        <div className="d-flex justify-content-between align-items-center pb-4">
                            <h5 className="mb-0">Drawdown Schedule</h5>
                            {isDisabled == false && <button className="next_btn" onClick={handleSaveEdit}>
                                Add
                            </button>}
                        </div>
                        {isDisabled == false &&
                            <React.Fragment >
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Date:</label>
                                        <div className="col-md-8">
                                            <input
                                                type="date"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={moment(editRow?.date).format('YYYY-MM-DD')}
                                                onChange={(e) => handleDrawdownChange(editRowIndex, 'date', e.target.value)}
                                            />
                                            {formErrors?.date && <span className="text-danger">Date is required.</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Currency:</label>
                                        <div className="col-md-8">
                                            <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={editRow?.currency}
                                                onChange={(e) => handleDrawdownChange(editRowIndex, 'currency', e.target.value)}>
                                                <option hidden>Select Currency</option>
                                                <option value="AUD">AUD</option>
                                                <option value="EUR">EUR</option>
                                                <option value="INR">INR</option>
                                                <option value="LKR">LKR</option>
                                                <option value="MAD">MAD</option>
                                                <option value="MXN">MXN</option>
                                                <option value="SGD">SGD</option>
                                                <option value="USD">USD</option>
                                            </select>

                                            {formErrors?.currency && <span className="text-danger">Currency is required.</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Amount:</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={editRow?.amount}
                                                onChange={(e) => handleDrawdownChange(editRowIndex, 'amount', e.target.value)}
                                            />
                                            {formErrors?.amount && <span className="text-danger">Amount is required.</span>}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Total No. of drawals:</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={editRow?.totalNoOfDrawals}
                                                onChange={(e) => handleDrawdownChange(editRowIndex, 'totalNoOfDrawals', e.target.value)}
                                            />
                                            {formErrors?.totalNoOfDrawals && <span className="text-danger">total No.Of Drawal is required.</span>}

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">No. of drawals in a calendar year:</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={editRow?.totalNoOfDrawalsInCalendarYear}
                                                onChange={(e) => handleDrawdownChange(editRowIndex, 'totalNoOfDrawalsInCalendarYear', e.target.value)}
                                            />
                                            {formErrors?.totalNoOfDrawalsInCalendarYear && <span className="text-danger">total No.Of Drawals In Calendar Year is required.</span>}

                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6 mt-3">
                                    <button className="btn btn-danger" onClick={() => handleDeleteDrawdown(index)}>
                                        Delete
                                    </button>
                                </div> */}
                            </React.Fragment>}
                    </div>
                    {/* ...other form fields */}
                    {drawdowns?.length > 0 && <div className="row mt-4">
                        <table>
                            <thead>
                                <tr>
                                    <th className="col-1">Tranche No.</th>
                                    <th className="col-2">Date <span className="note_info">(YYYY-MM-DD)</span></th>
                                    <th className="col-1">Currency</th>
                                    <th className="col-2">Amount</th>
                                    <th className="col-6" colSpan="2">If more than one installment</th>
                                    {isDisabled == false && <th className="col-6" colSpan="2">Action</th>}
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Total No.of drawals</th>
                                    <th>No. of drawals in a calendar year</th>
                                    {isDisabled == false && <th></th>}
                                    {isDisabled == false && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {drawdowns?.map((drawdown, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                            {moment(drawdown?.date).format('YYYY-MM-DD')}
                                        </td>
                                        <td>
                                            {drawdown.currency}
                                        </td>
                                        <td>
                                            {drawdown.amount}
                                        </td>
                                        <td>
                                            {drawdown.totalNoOfDrawals}
                                        </td>
                                        <td>
                                            {drawdown.totalNoOfDrawalsInCalendarYear}
                                        </td>
                                        {isDisabled == false && <td> <button className="btn btn-danger" onClick={() => handleEditRow(index, drawdown)}>
                                            edit
                                        </button>
                                        </td>}
                                        {isDisabled == false && <td> <button className="btn btn-danger" onClick={() => handleDelete(index, drawdown)}>
                                            delete
                                        </button></td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {drawdowns?.length === 0 && <div className="row mt-4">
                        <table>
                            <thead>
                                <tr>
                                    <th className="col-1">Tranche No.</th>
                                    <th className="col-2">Date <span className="note_info">(YYYY-MM-DD)</span></th>
                                    <th className="col-1">Currency</th>
                                    <th className="col-2">Amount</th>
                                    <th className="col-6" colSpan="2">If more than one installment</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Total No.of drawals</th>
                                    <th>No. of drawals in a calendar year</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr >
                                    <td colSpan="6">
                                        No data found
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>}
                </div>
                <div className="mt-4 d-flex">
                    <div className="text-danger">
                        <span className="info_red me-2">*</span>
                    </div>
                    <div className="text-danger">
                        <p className="note_info">1. In case of import of goods or services, date of import is to be furnished against date of drawdown.</p>
                        <p className="note_info">2. In case of financial lease, date of acquisition (import) of the goods is to be mentioned as date of drawdown.</p>
                        <p className="note_info">3. In case securitised instruments, date of issue has to be shown as date of drawdown.</p>
                        <p className="note_info">4. In case of more than one equal drawdown transactions are shown in a row, the first date of transaction should be mentioned.</p>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="d-flex justify-content-between align-items-center pb-4">
                        <h5 className="mb-0">Principal Repayment Schedule</h5>
                        {isDisabled == false && <button className="next_btn" onClick={handleSavePrincipalEdit}>
                            Add
                        </button>}
                    </div>
                    {isDisabled == false &&
                        <React.Fragment >
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Date:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="date"
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            value={moment(editRowPrincipal?.date).format('YYYY-MM-DD')}
                                            onChange={(e) => handleDrawChange(editPrincipalRowIndex, 'date', e.target.value)}
                                        />
                                        {formPrincipalErrors?.date && <span className="text-danger">Date is required.</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Currency:</label>
                                    <div className="col-md-8">
                                        <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            value={editRowPrincipal?.currency}
                                            onChange={(e) => handleDrawChange(editPrincipalRowIndex, 'currency', e.target.value)}>
                                            <option hidden>Select Currency</option>
                                            <option value="AUD">AUD</option>
                                            <option value="EUR">EUR</option>
                                            <option value="INR">INR</option>
                                            <option value="LKR">LKR</option>
                                            <option value="MAD">MAD</option>
                                            <option value="MXN">MXN</option>
                                            <option value="SGD">SGD</option>
                                            <option value="USD">USD</option>
                                        </select>

                                        {formPrincipalErrors?.currency && <span className="text-danger">Currency is required.</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Amount:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="number"
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            value={editRowPrincipal?.amount}
                                            onChange={(e) => handleDrawChange(editPrincipalRowIndex, 'amount', e.target.value)}
                                        />
                                        {formPrincipalErrors?.amount && <span className="text-danger">Amount is required.</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Total No. of drawals:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="number"
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            value={editRowPrincipal?.totalNoOfPayment}
                                            onChange={(e) => handleDrawChange(editPrincipalRowIndex, 'totalNoOfPayment', e.target.value)}
                                        />
                                        {formPrincipalErrors?.totalNoOfPayment && <span className="text-danger">total No.Of Drawal is required.</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">No. of drawals in a calendar year:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="number"
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            disabled={isDisabled}
                                            value={editRowPrincipal?.totalNoOfPaymentInCalendarYear}
                                            onChange={(e) => handleDrawChange(editPrincipalRowIndex, 'totalNoOfPaymentInCalendarYear', e.target.value)}
                                        />
                                        {formPrincipalErrors?.totalNoOfPaymentInCalendarYear && <span className="text-danger">total No.Of Drawals In Calendar Year is required.</span>}
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                </div>
                {/* ...other form fields */}
                {draw?.length > 0 && <div className="row mt-4">
                    <table>
                        <thead>
                            <tr>
                                <th className="col-1">Tranche No.</th>
                                <th className="col-2">Date <span className="note_info">(YYYY-MM-DD)</span></th>
                                <th className="col-1">Currency</th>
                                <th className="col-2">Amount</th>
                                <th className="col-6" colSpan="2">If more than one installment</th>
                                {isDisabled == false && <th className="col-6" colSpan="2">Action</th>}
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Total No.of drawals</th>
                                <th>No. of drawals in a calendar year</th>
                                {isDisabled == false && <th></th>}
                                {isDisabled == false && <th></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {draw?.map((drawdown, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {moment(drawdown?.date).format('YYYY-MM-DD')}
                                    </td>
                                    <td>
                                        {drawdown.currency}
                                    </td>
                                    <td>
                                        {drawdown.amount}
                                    </td>
                                    <td>
                                        {drawdown.totalNoOfPayment}
                                    </td>
                                    <td>
                                        {drawdown.totalNoOfPaymentInCalendarYear}
                                    </td>
                                    {isDisabled == false && <td>
                                        <button className="btn btn-danger" onClick={() => handlePrincipalEditRow(index, drawdown)}>
                                            edit
                                        </button>
                                    </td>}
                                    {isDisabled == false && <td>
                                        <button className="btn btn-danger" onClick={() => handlePrincipalDelete(index, drawdown)}>
                                            delete
                                        </button>
                                    </td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>}
                {draw?.length === 0 && <div className="row mt-4">
                    <table>
                        <thead>
                            <tr>
                                <th className="col-1">Tranche No.</th>
                                <th className="col-2">Date <span className="note_info">(YYYY-MM-DD)</span></th>
                                <th className="col-1">Currency</th>
                                <th className="col-2">Amount</th>
                                <th className="col-6" colSpan="2">If more than one installment</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Total No.of drawals</th>
                                <th>No. of drawals in a calendar year</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td colSpan="6">
                                    No data found
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>}
            </div>
            {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="text-danger">
                    <button className="btn back_btn">Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleSubmit} className="btn next_btn">Next</button>
                </div>
            </div>}
            {isDraft == false && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="text-danger">
                    <button onClick={handleOriginalBack} className="btn back_btn">Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleOriginalNext} className="btn next_btn">Next</button>
                </div>
            </div>}
        </React.Fragment>
    );
}

export default LoanDetails;
