import React from "react"

const changeSideBar = () => {
    let sideBar = document.getElementsByClassName('sidebar_align')
    sideBar[0].classList.add("sidebar_align_show")
}

const Dashboard = () => {
    return (
        <React.Fragment>
            <div className="form_content">
                <div className="d-flex justify-content-center align-items-start">
                    <div onClick={() => changeSideBar()}><i className="bi bi-list menu_icon fa-2xl"></i></div>
                    <h3 className="heading">KDM & CO LLP Chartered Accountant</h3>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Dashboard