import React from "react"
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import logo from "../../asset/img/KDM_logo.svg"

const closeSideBar = () => {
    let sideBar = document.getElementsByClassName('sidebar_align')
    sideBar[0].classList.remove("sidebar_align_show")
}


const Sidebar = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const logout = () => {
        localStorage.removeItem('persist:root')
        window.location.href = '/'
    }
    return (
        <React.Fragment>
            <div className="sidebar_align shadow-lg">
                <div className="text-end mt-3 close_icon"><i onClick={() => closeSideBar()} className="bi bi-x fa-2xl"></i></div>
                <div className="d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100 py-md-4 py-2">
                    <div className="my-3 w-100">
                        <img className="d-block mx-auto" src={logo} alt="logo" width="70%" />
                    </div>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 w-100 align-items-center text-start align-items-sm-start mt-md-3" id="menu">
                        <li className="nav-item w-100 py-2">
                            <NavLink className={"nav-link sidebar_links"} to={"/dashboard"}><i className="fa-solid fa-gauge fa-xl"></i><span className="ms-2 ">Dashboard</span></NavLink>
                        </li>
                        <li className="nav-item w-100 py-2">
                            <NavLink className={`nav-link sidebar_links `} to={"/home"}><i className="fa-solid fa-bars fa-xl"></i><span className="ms-2 ">Form</span></NavLink>
                        </li>
                        <li className="nav-item w-100 py-2">
                            <NavLink className={"nav-link sidebar_links"} to={"/secondForm"}><i className="fa-solid fa-list-check fa-xl"></i><span className="ms-2 ">ECB 2</span></NavLink>
                        </li>
                        <li className="nav-item w-100 py-2">
                            <button className="nav-link sidebar_links" onClick={() => logout()}><i className="fa fa-sign-out" aria-hidden="true"></i><span className="ms-2 ">Logout</span></button>
                        </li>

                    </ul>
                    <div className="mx-2 pr-4 pb-4">
                        <NavLink className={"nav-link"} to={"/home"}><i className="fa-solid fa-right-from-bracket fa-xl"></i><span className="ms-2 d-none d-sm-inline">Sign Out</span></NavLink>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Sidebar