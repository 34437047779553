import { doAction, doResponseAction } from ".";


const CREATE_BORROWING_PURPOSE = 'CREATE_BORROWING_PURPOSE';
const CREATE_BORROWING_PURPOSE_SUCCESS = 'CREATE_BORROWING_PURPOSE_SUCCESS';
const CREATE_BORROWING_PURPOSE_FAILURE = 'CREATE_BORROWING_PURPOSE_FAILURE';

const UPDATE_BORROWING_PURPOSE = 'UPDATE_BORROWING_PURPOSE';
const UPDATE_BORROWING_PURPOSE_SUCCESS = 'UPDATE_BORROWING_PURPOSE_SUCCESS';
const UPDATE_BORROWING_PURPOSE_FAILURE = 'UPDATE_BORROWING_PURPOSE_FAILURE';

const DELETE_BORROWING_PURPOSE = 'DELETE_BORROWING_PURPOSE';
const DELETE_BORROWING_PURPOSE_SUCCESS = 'DELETE_BORROWING_PURPOSE_SUCCESS';
const DELETE_BORROWING_PURPOSE_FAILURE = 'DELETE_BORROWING_PURPOSE_FAILURE';

export {

    CREATE_BORROWING_PURPOSE,
    CREATE_BORROWING_PURPOSE_SUCCESS,
    CREATE_BORROWING_PURPOSE_FAILURE,

    UPDATE_BORROWING_PURPOSE,
    UPDATE_BORROWING_PURPOSE_SUCCESS,
    UPDATE_BORROWING_PURPOSE_FAILURE,

    DELETE_BORROWING_PURPOSE,
    DELETE_BORROWING_PURPOSE_SUCCESS,
    DELETE_BORROWING_PURPOSE_FAILURE,
}


export const createBorrowingPurpose = (params, callback) => doAction(CREATE_BORROWING_PURPOSE, params, callback);
export const createBorrowingPurposeSuccess = (data) => doResponseAction(CREATE_BORROWING_PURPOSE_SUCCESS, data);
export const createBorrowingPurposeFailure = (data) => doResponseAction(CREATE_BORROWING_PURPOSE_FAILURE, data);

export const updateBorrowingPurpose = (params, callback) => doAction(UPDATE_BORROWING_PURPOSE, params, callback);
export const updateBorrowingPurposeSuccess = (data) => doResponseAction(UPDATE_BORROWING_PURPOSE_SUCCESS, data);
export const updateBorrowingPurposeFailure = (data) => doResponseAction(UPDATE_BORROWING_PURPOSE_FAILURE, data);

export const deleteBorrowingPurpose = (params, callback) => doAction(DELETE_BORROWING_PURPOSE, params, callback);
export const deleteBorrowingPurposeSuccess = (data) => doResponseAction(DELETE_BORROWING_PURPOSE_SUCCESS, data);
export const deleteBorrowingPurposeFailure = (data) => doResponseAction(DELETE_BORROWING_PURPOSE_FAILURE, data);