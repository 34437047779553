import React, { useCallback, useEffect, useState } from 'react';
import { lenderDetailsValidateField } from '../utils/Validation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { getAgreementDetailsList, getFormLoanDetailslist, getagreementDetails, lenderDetailsDatas } from "../redux/reducer/FormListReducers";
import Loader from './HOC/Loader';
import { toast } from 'react-toastify';

const LenderDetails = ({ onNext, isBack, setBack, onBack }) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDisabled, setIsDisabled] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const getAgreementDetails = useSelector(getagreementDetails)
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const loanList = useSelector(getFormLoanDetailslist)
    const lenderDetailsData = useSelector(lenderDetailsDatas)
    const [isloading, setLoading] = useState(false)
    const [lenderDetails, setLenderDetails] = useState({

        name: '',
        address: '',
        country: '',
        emailId: '',
        lenderCategoryType: '',
        equityOfTheBorrower: '',
        amountPaidUpCapital: '',
        ecbLiabilityRatio: '',
    });

    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        address: false,
        country: false,
        emailId: false,
        lenderCategoryType: false,
        equityOfTheBorrower: false,
        amountPaidUpCapital: false,
        ecbLiabilityRatio: false,
    });


    // useEffect(() => {
    //     if (lenderDetailsData) {
    //         setLenderDetails(lenderDetailsData)
    //         setLoading(false);
    //     }
    // }, [dispatch, lenderDetailsData])
    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        const callback = (res) => {
            setLoading(false);
            if (!res.error) {
                setLenderDetails(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        };
        if ((getAgreementDetails?.ecbApplicationType?.toString() === '2' || agreementDetailsList?.loanId) || (isBack && (Array.isArray(lenderDetailsData) ? false : true))) {
            let params = {
                loanId: isBack ? loanList?.id : getAgreementDetails?.loanId ?? agreementDetailsList?.loanId,
                isDraft: isDraft
            };
            setLoading(true);
            dispatch(Actions.getLenderDetailsList(params, callback))
        }
    }, [dispatch, getAgreementDetails?.ecbApplicationType, getAgreementDetails?.loanId, isBack, loanList?.id])
    // const DepartmentSearch = useCallback(() => {
    //     setLoading(true);
    //     const callback = (res) => {
    //         setLoading(false);
    //     };
    //     let params = {
    //        ...lenderDetails,
    //        loanId: loanList.id
    //        isDraft: isDraft
    //     };
    //     dispatch(Actions.FormLenderDetailsList(params, callback));
    // }, [dispatch]);

    const handleFieldChange = (field, value) => {
        switch (field) {
            case 'name':
                value = value.toUpperCase();
                break;
            case 'address':
                value = value.toUpperCase();
                break;
            default:
                break;
        }
        setLenderDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleOriginalNext = () => {
        onNext("lenderDetails")
    }
    const handleOriginalBack = () => {
        onNext('loanDetailsNext')
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in lenderDetails) {
            if (lenderDetails[field] === '' || lenderDetails[field] === null) {
                if (field === 'lenderOtherCategory' || field === 'updatedDate') {
                    newErrors[field] = false;
                    continue;
                }
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);

        if (!hasErrors) {
            setLoading(true);
            const callback = (res) => {
                setLoading(false);
                if (!res.error) {
                    onNext('lenderDetails')
                } else if (res?.error) {
                    const { data } = res.error?.response || {}
                    toast.error(`${data?.response_message}`)
                } else {
                    toast.error("Sorry somethiing went wrong")
                }
            };
            if (getAgreementDetails?.ecbApplicationType?.toString() === 2) {
                dispatch(Actions.FormLenderDetailsList({ lenderDetails, isDraft: isDraft }, callback));
            } else {
                let params = {
                    ...lenderDetails,
                    loanId: loanList?.id,
                    isDraft: isDraft

                };
                dispatch(Actions.lenderDetailsData(params))
                dispatch(Actions.FormLenderDetailsList(params, callback));
                // Perform form submission logic here
            }
        }
    };

    const back = () => {
        setBack(true)
        onBack("loanDetails")
    }

    return (
        <React.Fragment>
            <div className="py-3">
                <div className="row mt-4">
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Name: <span className="note_info">(Note: Should be in capital)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    placeholder="Name"
                                    disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    value={lenderDetails?.name}
                                    onChange={(e) => handleFieldChange('name', e.target.value)}
                                />
                                {fieldChangedStatus?.name && lenderDetailsValidateField('name', lenderDetails.name) && (
                                    <span className="text-danger">{lenderDetailsValidateField('name', lenderDetails.name)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Address: <span className="note_info">(Note: Should be in capital)</span></label>
                            <div className="col-md-8">
                                <textarea
                                    type="text"
                                    placeholder="Address"
                                    disabled={isDisabled}
                                    className={`form-control text_area_height ${isDisabled ? 'disable' : ''}`}
                                    value={lenderDetails?.address}
                                    onChange={(e) => handleFieldChange('address', e.target.value)}
                                />
                                {fieldChangedStatus?.address && lenderDetailsValidateField('address', lenderDetails.address) && (
                                    <span className="text-danger">{lenderDetailsValidateField('address', lenderDetails.address)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Country:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    placeholder="Country Name"
                                    disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    value={lenderDetails?.country}
                                    onChange={(e) => handleFieldChange('country', e.target.value)}
                                />
                                {fieldChangedStatus?.country && lenderDetailsValidateField('country', lenderDetails.country) && (
                                    <span className="text-danger">{lenderDetailsValidateField('country', lenderDetails.country)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">E-mail ID:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    placeholder="Email ID"
                                    disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    value={lenderDetails?.emailId}
                                    onChange={(e) => handleFieldChange('emailId', e.target.value)}
                                />
                                {fieldChangedStatus?.emailId && lenderDetailsValidateField('emailId', lenderDetails.emailId) && (
                                    <span className="text-danger">{lenderDetailsValidateField('emailId', lenderDetails.emailId)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <label className="col-md-2 form-label label_head">Lender Category:</label>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={1}
                                    checked={lenderDetails?.lenderCategoryType === 1}
                                    onChange={() => handleFieldChange('lenderCategoryType', 1)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Multilateral Financial Institution</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={2}
                                    checked={lenderDetails?.lenderCategoryType === 2}
                                    onChange={() => handleFieldChange('lenderCategoryType', 2)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Foreign Government (Bilateral Agency)</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={3}
                                    checked={lenderDetails?.lenderCategoryType === 3}
                                    onChange={() => handleFieldChange('lenderCategoryType', 3)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Export Credit Agency</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={4}
                                    checked={lenderDetails?.lenderCategoryType === 4}
                                    onChange={() => handleFieldChange('lenderCategoryType', 4)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Indian Commercial Bank branch abroad</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={5}
                                    checked={lenderDetails?.lenderCategoryType === 5}
                                    onChange={() => handleFieldChange('lenderCategoryType', 5)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Other Commercial Bank</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={6}
                                    checked={lenderDetails?.lenderCategoryType === 6}
                                    onChange={() => handleFieldChange('lenderCategoryType', 6)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Supplier of Equipment</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={7}
                                    checked={lenderDetails?.lenderCategoryType === 7}
                                    onChange={() => handleFieldChange('lenderCategoryType', 7)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Leasing Company</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={8}
                                    checked={lenderDetails?.lenderCategoryType === 8}
                                    onChange={() => handleFieldChange('lenderCategoryType', 8)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Foreign Collaborator /Foreign Equity Holder</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={9}
                                    checked={lenderDetails?.lenderCategoryType === 9}
                                    onChange={() => handleFieldChange('lenderCategoryType', 9)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>International Capital Market</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={10}
                                    checked={lenderDetails?.lenderCategoryType === 10}
                                    onChange={() => handleFieldChange('lenderCategoryType', 10)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Regional Financial Institution</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={11}
                                    checked={lenderDetails?.lenderCategoryType === 11}
                                    onChange={() => handleFieldChange('lenderCategoryType', 11)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Government Owned Development Financial Institution</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={12}
                                    checked={lenderDetails?.lenderCategoryType === 12}
                                    onChange={() => handleFieldChange('lenderCategoryType', 12)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Private placement (RDBs)</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={13}
                                    checked={lenderDetails?.lenderCategoryType === 13}
                                    onChange={() => handleFieldChange('lenderCategoryType', 13)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Public Offer (RDBs)</label>
                            </div>
                            <div className="col-md-5 form-check mt-3">
                                <input
                                    disabled={isDisabled} className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value={14}
                                    checked={lenderDetails?.lenderCategoryType === 14}
                                    onChange={() => handleFieldChange('lenderCategoryType', 14)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Others</label>
                            </div>
                        </div>
                        {fieldChangedStatus?.equityOfTheBorrower && lenderDetailsValidateField('lenderCategoryType', lenderDetails.lenderCategoryType) && (
                            <span className="text-danger">{lenderDetailsValidateField('lenderCategoryType', lenderDetails.lenderCategoryType)}</span>
                        )}
                    </div>
                </div>
                <div className="mt-5">
                    <h5 className="">Details of foreign equity holding of the lender in the borrower company:</h5>
                    <div className="row mt-4">
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Share in paid-up equity of the borrower:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        placeholder="Percentage"
                                        disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                        value={lenderDetails?.equityOfTheBorrower}
                                        onChange={(e) => handleFieldChange('equityOfTheBorrower', e.target.value)}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                        }}
                                    />
                                    {fieldChangedStatus?.equityOfTheBorrower && lenderDetailsValidateField('equityOfTheBorrower', lenderDetails.equityOfTheBorrower) && (
                                        <span className="text-danger">{lenderDetailsValidateField('equityOfTheBorrower', lenderDetails.equityOfTheBorrower)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">Amount of paid-up capital:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        placeholder="Amount of paid-up capital"
                                        disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                        value={lenderDetails?.amountPaidUpCapital}
                                        onChange={(e) => handleFieldChange('amountPaidUpCapital', e.target.value)}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                        }}
                                    />
                                    {fieldChangedStatus?.amountPaidUpCapital && lenderDetailsValidateField('amountPaidUpCapital', lenderDetails.amountPaidUpCapital) && (
                                        <span className="text-danger">{lenderDetailsValidateField('amountPaidUpCapital', lenderDetails.amountPaidUpCapital)}</span>
                                    )}

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 form-label label_head">ECB-Liability: <span className="note_info">(equity ratio in case of borrowings above USD5million from foreign equity holder)</span></label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        placeholder="Ratio e.g 189:1"
                                        disabled={isDisabled} className={`form-control ${isDisabled ? 'disable' : ''}`}
                                        value={lenderDetails?.ecbLiabilityRatio}
                                        onChange={(e) => handleFieldChange('ecbLiabilityRatio', e.target.value)}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                                        }}
                                    />
                                    {fieldChangedStatus?.ecbLiabilityRatio && lenderDetailsValidateField('ecbLiabilityRatio', lenderDetails.ecbLiabilityRatio) && (
                                        <span className="text-danger">{lenderDetailsValidateField('ecbLiabilityRatio', lenderDetails.ecbLiabilityRatio)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="">
                    <button className="btn back_btn" onClick={() => back()}>Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleSubmit} className="btn next_btn">Next</button>
                </div>
            </div>}
            {isDraft == false && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="">
                    <button className="btn back_btn" onClick={handleOriginalBack}>Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleOriginalNext} className="btn next_btn">Next</button>
                </div>
            </div>}
            {
                isloading && <Loader />
            }
        </React.Fragment>
    )
}

export default LenderDetails;
