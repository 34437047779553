import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { CREATE_BORROWING_PURPOSE, UPDATE_BORROWING_PURPOSE,DELETE_BORROWING_PURPOSE } from '../action/borrowingPurpose';

export function* createBorrowingPurpose(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.CREATE_BORROWING_PURPOSE, params)
        yield put(Actions.createBorrowingPurposeSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.createBorrowingPurposeFailure(error))
    }
}
export function* updateBorrowingpurpose(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.UPDATE_BORROWING_PURPOSE, params)
        yield put(Actions.updateBorrowingPurposeSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.updateBorrowingPurposeFailure(error))
    }
}
export function* deleteBorrowingpurpose(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.DELETE_BORROWING_PURPOSE, params)
        yield put(Actions.deleteBorrowingPurposeSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.deleteBorrowingPurposeFailure(error))
    }
}

export default function* borrowingPurposeWatcher() {
    yield all([
        takeLatest(CREATE_BORROWING_PURPOSE, createBorrowingPurpose),
        takeLatest(UPDATE_BORROWING_PURPOSE, updateBorrowingpurpose),
        takeLatest(DELETE_BORROWING_PURPOSE, deleteBorrowingpurpose),
    ]);
}
