const hostname = window.location.hostname || 'localhost';
let API_BASE_URL = "/"

const END_POINTS = {
    API_BASE_URL: API_BASE_URL,
    LOGIN: "api/oauth/login",
    FORM_LIST: 'api/loan/list',
    FORM_LOAN_DETAILS_LIST: 'api/loan/create',
    DRAFT_FORM_LIST: 'api/loan/draft/list',
    FORM_LENDER_DETAILS_CREATE: 'api/loan/lender/create',
    FORM_BORROWER_DETAILS_CREATE: 'api/loan/borrower/create',
    FORM_SUMMARY_DETAILS_LIST: 'api/loan/summary/create',
    GET_LENDER_DETAILS_CREATE: 'api/loan/lender/details',
    GET_BORROWER_DETAILS_CREATE: 'api/loan/borrower/details',
    AGREEMENT_DETAILS: 'api/loan/agreement/create',
    GET_AGREEMENT_DETAILS: 'api/loan/agreement/details',
    GET_LOAN_DETAILS: 'api/loan/details',
    GET_OTHER_CHARGES: 'api/loan/otherchargesAndDeclaration/details',
    ECB_DETAILS_CREATE: 'api/loan/otherchargesAndDeclaration/create',
    GET_SUMMARY_DETAILS_LIST: 'api/loan/summary/details',
    GET_PDF_LIST: 'api/loan/download/pdf',
    GET_GUARANTEE_STATUS_CODE: 'api/masterTable/guaranteeStatusCode/list',
    GET_BORROWING_PURPOSE_CODE: 'api/masterTable/borrowingPurposeCode/list',
    CREATE_BORROWING_PURPOSE: 'api/loan/natureofSecurity/create',
    UPDATE_BORROWING_PURPOSE: '',
    DELETE_BORROWING_PURPOSE: '',
    CREATE_DRAWDOWN: 'api/loan/drawdown/schedule/create',
    UPDATE_DRAWDOWN: '',
    DELETE_DRAWDOWN: '',
    CREATE_REPAYMENT: 'api/loan/principal/repaymentSchedule/create',
    UPDATE_REPAYMENT: '',
    DELETE_REPAYMENT: '',
}
export default END_POINTS


