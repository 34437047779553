import { doAction, doResponseAction } from ".";

const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
const LOGIN_CHECK_DATA = 'LOGIN_CHECK_DATA';

export {
    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
    LOGIN_CHECK_DATA
}

// LOGIN
export const loginUser = (params, callback) => doAction(LOGIN_USER, params, callback);
export const loginUserSuccess = (data) => doResponseAction(LOGIN_USER_SUCCESS, data);
export const loginUserFailure = (data) => doResponseAction(LOGIN_USER_FAILURE, data);