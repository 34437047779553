import { doAction, doResponseAction } from ".";

const GET_OTHER_CHARGES = 'GET_OTHER_CHARGES';
const GET_OTHER_CHARGES_SUCCESS = 'GET_OTHER_CHARGES_SUCCESS';
const GET_OTHER_CHARGES_FAILURE = 'GET_OTHER_CHARGES_FAILURE';

export {
    GET_OTHER_CHARGES,
    GET_OTHER_CHARGES_FAILURE,
    GET_OTHER_CHARGES_SUCCESS,
 
}

// LOGIN
export const GetOther = (params, callback) => doAction(GET_OTHER_CHARGES, params, callback);
export const GetOtherSuccess = (data) => doResponseAction(GET_OTHER_CHARGES_SUCCESS, data);
export const GetOtherFailure = (data) => doResponseAction(GET_OTHER_CHARGES_FAILURE, data);