import { Actions } from '../action';

const initialState = {
    isLoading: false,
    drawdown: [],
    error: false,
}
export const drawdownReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CREATE_DRAWDOWN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_DRAWDOWN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                drawdown: action?.data,
                error: false
            };
        }
        case Actions.CREATE_DRAWDOWN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_DRAWDOWN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_DRAWDOWN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                drawdown: action?.data,
                error: false
            };
        }
        case Actions.UPDATE_DRAWDOWN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DELETE_DRAWDOWN: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_DRAWDOWN_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                drawdown: action?.data,
                error: false
            };
        }
        case Actions.DELETE_DRAWDOWN_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};



export const drawdown = (state) => state?.DRAWDOWN?.drawdown;