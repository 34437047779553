import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { Outlet } from "react-router-dom"
const Layout = () => {
    return (
        <div className="container-fluid">
            <div className="row flex-nowrap">
                <Sidebar />
                <div className="body_content py-3">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout