import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Actions } from "../redux/action"
import { getLoginData } from "../redux/reducer/loginReducer"
import { validateName } from "../utils/Validation"
import MESSAGE from "../constant/String"
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [password, setpassword] = useState("");
    const [email, setemail] = useState("")
    const [passwordType, setPasswordType] = useState("password");
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ password: "", name: "" });


    const togglePassword = (event) => {
        event.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }



    const OnSubmit = () => {
        if (!email) {
            setError({ name: "please enter the user name." })
        } else if (!password) {
            setError({ password: 'Please enter the password.' })
        } else {
            const callback = (res) => {
                if (res?.response_code === 0) {
        navigate("/dashboard")
            } else {
                toast.error(res?.response_message)

            }
        }
        const bodyParams = {
            "grant_type": "password",
            "email": email,
            "password": password,
            "scope": "super_admin"

        }
        dispatch(Actions.loginUser(bodyParams, callback))
        }
    }

    return (
        <React.Fragment>
            <section className="h-100">
                <div className="container h-100">
                    <div className="row justify-content-sm-center h-100">
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9x">
                            <div className="text-center my-5">
                                <img src={require('../asset/img/KDM_logo.png')} alt="logo" width="200" />
                            </div>
                            <div className="card shadow-lg">
                                <div className="card-body p-5">
                                    <h1 className="fs-4 card-title fw-bold mb-4">Login</h1>

                                    <div className="mb-3 text-start">
                                        <label className="mb-2 text-muted" for="email">User Name</label>
                                        <input id="email" type="email" value={email} className="form-control" name="email" onChange={(e) => setemail(e?.target?.value)} />
                                        {error?.name && <p className="text-danger">{error?.name}</p>}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <div className="mb-2 w-100">
                                            <label className="text-muted" for="password">Password</label>
                                        </div>
                                        <div className="input-password">
                                            <input id="password" type={passwordType} value={password} className="form-control" name="password" onChange={(e) => setpassword(e?.target?.value)} />
                                            {error?.password && <p className="text-danger">{error?.password}</p>}

                                            <div className="input-group-append hidden-icon">
                                                <span onClick={togglePassword} className="cursor-pointer">
                                                    <i className={`${passwordType === "password" ? " bi bi-eye-slash-fill" : "bi bi-eye-fill"}`} aria-hidden="true"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="align-items-center">
                                        <button className="common_btn" onClick={() => OnSubmit()}>
                                            Login
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Login