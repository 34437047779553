import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { SummaryValidateField } from '../utils/Validation';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { getAgreementDetailsList, getFormLoanDetailslist, getagreementDetails } from "../redux/reducer/FormListReducers";
import { getFormSummaryDetails } from "../redux/reducer/FormSummaryReducers";
import { toast } from 'react-toastify';
import FORM1PDF from './form1pdf';
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router';

const Summary = () => {
    const dispatch = useDispatch();
    const componentRef = useRef();
    const navigate = useNavigate()
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const [isDisabled, setIsDisabled] = useState(false)
    const getAgreementDetails = useSelector(getagreementDetails)
    const loanList = useSelector(getFormLoanDetailslist)
    const summaryDetailsList = useSelector(getFormSummaryDetails)
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const [isloading, setLoading] = useState(false)
    const [declarationDetails, setDeclarationDetails] = useState(false)
    const [loanDetails, setLoanDetails] = useState(false)
    const [borrowingPurposeCode, setBorrowingPurposeCode] = useState([])
    const [natureDetails, setNatureDeails] = useState(false)
    const [summaryDetails, setSummaryDetails] = useState({
        // place: '',
        // date: '',
        signature: '',
        name: '',
        designation: '',
        // registrationNumber: '',
        emailId: '',
        nameOfBankandBranch: '',
        ADCode: '',
        telNumber: '',
        faxNo: ''
    });

    const [fieldChangedStatus, setFieldChangedStatus] = useState({

        // place: false,
        // date: false,
        signature: false,
        name: false,
        designation: false,
        // registrationNumber: false,
        emailId: false,
        nameOfBankandBranch: false,
        ADCode: false,
        telNumber: false,
        faxNo: false,

    });
    const handleFieldChange = (field, value) => {
        setSummaryDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;

        for (const field in summaryDetails) {
            if ((field !== 'signature' && summaryDetails[field] === '') || summaryDetails[field] === null) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            setLoading(true)
            const callback = (res) => {
                setLoading(false);
                if (res?.response_code === 0) {
                    toast.success("Form Successfully Submited")
                    navigate('/dashboard')
                } else if (res?.error) {
                    const { data } = res.error?.response || {}
                    toast.error(`${data?.response_message}`)
                } else {
                    toast.error("Sorry somethiing went wrong")
                }
            };
            const params = {
                ...summaryDetails,
                loanId: loanList?.id,
                isDraft: isDraft
            }
            dispatch(Actions.FormSummayDetailsList(params, callback));
        }
    };
    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        const callback = (res) => {
            setLoading(false);
            if (!res.error) {
                setSummaryDetails(res?.response?.findSummary);
                setLoanDetails(res?.response?.findLoanDetails);
                setDeclarationDetails(res?.response?.findDeclaration);
                const findNatureDetails = 'NatureDetailsSecurities' in res?.response?.findLoanDetails
                setNatureDeails(findNatureDetails)
            }
        }
        const params = {
            loanId: getAgreementDetails?.ecbApplicationType?.toString() === '2' ? getAgreementDetails?.loanId : loanList?.id ?? agreementDetailsList?.loanId,
            isDraft: isDraft
        }
        setLoading(true);
        getBorrowingPurposeCode();
        dispatch(Actions.getSummayDetailsList(params, callback))
    }, [dispatch])
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const getBorrowingPurposeCode = () => {
        const callback = (res) => {
            if (!res.error) {
                setBorrowingPurposeCode(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        }
        dispatch(Actions.getBorrowingPurposeCode('', callback))
    }
    let count = 0;
    let nature = natureDetails ? loanDetails?.NatureDetailsSecurities : loanDetails?.DraftNatureDetailsSecurities
    return (
        <React.Fragment>
            {/* <div className="d-flex align-items-center justify-content-end py-4">
                <p className="m-0">To view the instruction of the policy: </p>
                <button className="common_btn">View</button>
            </div> */}
            <div className="py-md-4 py-3">
                <h5 className="mt-4">We have scrutinized the related documents and confirm the following:</h5>
                <div className="row mt-4">
                    <p className="col-md-3 label_head">End-use (%share if more than one end-use):</p>
                    <div className="col-md-9 d-flex">
                        <div className="row">
                            <div className="col-md-3">
                                {nature?.length > 0 ? nature?.map((ele) => {
                                    count = count + 1;
                                    let code = borrowingPurposeCode.find((e) => e?.id === ele?.borrowingPurposeCodeId)
                                    return <React.Fragment>
                                        <p>{count}. {code.code} - {ele?.borrowingPurposePercentage ?? 0}%</p>
                                    </React.Fragment>
                                }) : "No end use"}
                            </div>
                            <div className="col-md-9">
                                <div className="form-check">
                                    <input className={`form-check-input ${isDisabled ? 'disable' : ''}`} disabled={isDisabled} type="radio" value="automicRoute" checked />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`} >Permissible
                                        under
                                        Automatic
                                        Route</label>
                                </div>
                                <div className="form-check mt-2">
                                    <input className={`form-check-input ${isDisabled ? 'disable' : ''}`} disabled={isDisabled} type="radio" value="approvalRoute" />
                                    <label className={`form-check-label ${isDisabled ? 'disable' : ''}`} >Approved by Foreign
                                        Exchange Department,
                                        RBI under Approval
                                        Route</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <p className="col-md-3 label_head">Average Maturity:</p>
                    <div className="col-md-9 d-flex">
                        <div className="row w-100">
                            <div className="col-md-4">
                                <div className="row">
                                    <p className="col-7 label_head mb-0">Years:</p>
                                    <p className="col-5 mb-0">{loanDetails?.gracePeriodInYear ?? 0}</p>
                                </div>
                                <div className="row">
                                    <p className="col-7 label_head mb-0">Months:</p>
                                    <p className="col-5 mb-0">{loanDetails?.gracePeriodInMonth ?? 0}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <p className="col-md-3 label_head">Cost Factors (%):</p>
                    <div className="col-md-9">
                        <div className="">
                            <h5 className="">a) Interest Rate</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Fixed Rate Loan:</p>
                                        <p className="col-5">{loanDetails?.fixedRate ?? 'NIL'}<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                            </div>
                            <h5 className="">Floating Rate Loan</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Margin (spread) over base:</p>
                                        <p className="col-5">NIL<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Base:</p>
                                        <p className="col-5">NIL<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <h5 className="">b) All-in-cost</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Fixed Rate Loan:</p>
                                        <p className="col-5">NIL<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                            </div>
                            <h5 className="">Floating Rate Loan</h5>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Margin (spread) over base:</p>
                                        <p className="col-5">NIL<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="row">
                                        <p className="col-7 label_head">Base:</p>
                                        <p className="col-5">NIL<span className="note_info">(interest free loan)</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <p className="col-md-3 label_head">In case of loan from ‘Foreign Equity Holder’, it is confirmed that ECB liability:
                        {/* <span className="note_info">Equity ratio
                (7:1) criteria is satisfied. Further, in case of working capital/general corporate purpose/
                repayment of rupee loans end-use, it is confirmed that equity holding of lender is at
                least 25 percent (direct)/51 percent (indirect) of the paid-up equity or the lender is a
                group company with common overseas parent</span>: */}
                    </p>
                    <div className="col-md-9">
                        <p>No</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Borrower has given written undertaking to AD to the effect that it
                        has been submitting ECB-2 Returns regularly to RBI in respectof past
                        (ECB/FCCB loans)
                        :</p>
                    <div className="col-md-9">
                        <p>Yes</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Security provided, if any:</p>
                    <div className="col-md-9">
                        <p>None</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Other important facts
                        relevant for the allotment
                        of LRN:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <p>None</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row mt-3">
                    <p className="col-md-3 label_head">Place :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Type here" value={summaryDetails?.place}
                                    onChange={(e) => handleFieldChange('place', e.target.value)}
                                />
                                {fieldChangedStatus.place && SummaryValidateField('place', summaryDetails?.place) && (
                                    <span className="text-danger">{SummaryValidateField('place', summaryDetails?.place)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row mt-3">
                    <p className="col-md-3 label_head">Date :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="date" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Type here"
                                    value={moment(summaryDetails?.date).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('date', e.target.value)}
                                />
                                {fieldChangedStatus.date && SummaryValidateField('date', summaryDetails?.date) && (
                                    <span className="text-danger">{SummaryValidateField('date', summaryDetails?.date)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Name :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <p>{declarationDetails?.name ? declarationDetails?.name : "-"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Registration No :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <p>{declarationDetails?.registrationNumber ? declarationDetails?.registrationNumber : "-"}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="note_info my-3">We certify that the borrower is our customer and the particulars given in this Form are true and correct
                    to the best of our knowledge and belief. We have scrutinized the application and the original letter of
                    offer from the lender/supplier and documents relating to proposed borrowing and found the same to be
                    in order. This application complies with the extant ECB guidelines and we recommend it for allotment of
                    Loan Registration Number (LRN) by RBI.</p>
                {/* <div className="row mt-3">
                    <p className="col-md-3 label_head">Place :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Type here" value={summaryDetails?.place}
                                    onChange={(e) => handleFieldChange('place', e.target.value)}
                                />
                                {fieldChangedStatus.place && SummaryValidateField('place', summaryDetails?.place) && (
                                    <span className="text-danger">{SummaryValidateField('place', summaryDetails?.place)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row mt-3">
                    <p className="col-md-3 label_head">Date :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="date" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Type here"
                                    value={moment(summaryDetails?.date).format('YYYY-MM-DD')}
                                    onChange={(e) => handleFieldChange('date', e.target.value)}
                                />
                                {fieldChangedStatus.date && SummaryValidateField('date', summaryDetails?.date) && (
                                    <span className="text-danger">{SummaryValidateField('date', summaryDetails?.date)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row mt-3">
                    <p className="col-md-3 label_head">Signature of the Authorised Official with stamp :</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="file" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Upload" value={summaryDetails?.signature}
                                    onChange={(e) => handleFieldChange('signature', e.target.value)}
                                />
                                {fieldChangedStatus.signature && SummaryValidateField('signature', summaryDetails?.signature) && (
                                    <span className="text-danger">{SummaryValidateField('signature', summaryDetails?.signature)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Name:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Name" value={summaryDetails?.name}
                                    onChange={(e) => handleFieldChange('name', e.target.value)} maxLength={50}
                                />
                                {fieldChangedStatus.name && SummaryValidateField('name', summaryDetails?.name) && (
                                    <span className="text-danger">{SummaryValidateField('name', summaryDetails?.name)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Designation:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Designation" value={summaryDetails?.designation}
                                    onChange={(e) => handleFieldChange('designation', e.target.value)} maxLength={50}
                                />
                                {fieldChangedStatus.designation && SummaryValidateField('designation', summaryDetails?.designation) && (
                                    <span className="text-danger">{SummaryValidateField('designation', summaryDetails?.designation)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Name of the bank/ branch:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Bank / Branch" value={summaryDetails?.nameOfBankandBranch}
                                    onChange={(e) => handleFieldChange('nameOfBankandBranch', e.target.value)} maxLength={50}
                                />
                                {fieldChangedStatus.nameOfBankandBranch && SummaryValidateField('nameOfBankandBranch', summaryDetails?.nameOfBankandBranch) && (
                                    <span className="text-danger">{SummaryValidateField('nameOfBankandBranch', summaryDetails?.nameOfBankandBranch)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">AD Code (Part I and PartII):</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="text" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter AD code" value={summaryDetails?.ADCode}
                                    onChange={(e) => handleFieldChange('ADCode', e.target.value)} maxLength={50}
                                />
                                {fieldChangedStatus.ADCode && SummaryValidateField('ADCode', summaryDetails?.ADCode) && (
                                    <span className="text-danger">{SummaryValidateField('ADCode', summaryDetails?.ADCode)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Tel No:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="number" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Telephone No" value={summaryDetails?.telNumber}
                                    onChange={(e) => handleFieldChange('telNumber', e.target.value)} onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.telNumber && SummaryValidateField('telNumber', summaryDetails?.telNumber) && (
                                    <span className="text-danger">{SummaryValidateField('telNumber', summaryDetails?.telNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">Fax No:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="number" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Fax No" value={summaryDetails?.faxNo}
                                    onChange={(e) => handleFieldChange('faxNo', e.target.value)} onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                /> {fieldChangedStatus.faxNo && SummaryValidateField('faxNo', summaryDetails?.faxNo) && (
                                    <span className="text-danger">{SummaryValidateField('faxNo', summaryDetails?.faxNo)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <p className="col-md-3 label_head">E-mail:</p>
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-5">
                                <input type="email" className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled} placeholder="Enter Email" value={summaryDetails?.emailId}
                                    onChange={(e) => handleFieldChange('emailId', e.target.value)}
                                />
                                {fieldChangedStatus.emailId && SummaryValidateField('emailId', summaryDetails?.emailId) && (
                                    <span className="text-danger">{SummaryValidateField('emailId', summaryDetails?.emailId)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-3">
                    <div className="ms-3">

                        <button className="btn next_btn" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>}
            </div>
        </React.Fragment>
    )
}
export default Summary;