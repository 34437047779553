import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, doPost } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { FORM_SUMMARY_DETAILS_LIST,GET_PDF_LIST,GET_SUMMARY_DETAILS_LIST } from '../action/summary';

export function* FormSummaryDetailsList(param) {
    const { params, callback } = param

    try {
        const response = yield doPost(END_POINTS.FORM_SUMMARY_DETAILS_LIST, params)
        yield put(Actions.FormSummaryDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.FormSummaryDetailsListFailure(error))
    }
}
export function* getSummaryDetailsList(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_SUMMARY_DETAILS_LIST, params)
        yield put(Actions.getSummaryDetailsListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getSummaryDetailsListFailure(error))
    }
}
export function* getPdfDetailsList(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_PDF_LIST, params)
        yield put(Actions.getPdfListSuccess(response?.response))
        callback && callback(response)

    } catch (error) {
        callback && callback({ error })
        yield put(Actions.getPdfListFailure(error))
    }
}
export default function* formSummaryWatcher() {
    yield all([
        takeLatest(FORM_SUMMARY_DETAILS_LIST, FormSummaryDetailsList),
        takeLatest(GET_SUMMARY_DETAILS_LIST, getSummaryDetailsList),
        takeLatest(GET_PDF_LIST, getPdfDetailsList),
    ]);
}
