import { takeLatest, all, put } from 'redux-saga/effects';
import { doGet, login } from '../../service';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { GET_OTHER_CHARGES } from '../action/OtherChargesAction';

export function* OtherCharges(param) {
    const { params, callback } = param

    try {
        const response = yield doGet(END_POINTS.GET_OTHER_CHARGES, params)
        yield put(Actions.GetOtherSuccess(response?.response))
        callback(response)

    } catch (error) {
        callback({ error })
        yield put(Actions.GetOtherFailure(error))
    }
}


export default function* otherChargesWatcher() {
    yield all([
        takeLatest(GET_OTHER_CHARGES, OtherCharges),
    ]);
}
