import React, { useEffect, useState } from 'react';
import { BorrowDetailsValidateField } from '../utils/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { getFormLoanDetailslist, getagreementDetails, borrowerDetailsDatas, getAgreementDetailsList } from '../redux/reducer/FormListReducers';
import { useLocation, useNavigate } from 'react-router-dom';
import { Actions } from '../redux/action';
import Loader from './HOC/Loader';
import { toast } from 'react-toastify';

const BorrowerDetails = ({ onNext, setBack, onBack, isBack }) => {
    const location = useLocation()
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const getAgreementDetails = useSelector(getagreementDetails)
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const navigate = useNavigate()
    const [isloading, setLoading] = useState(false)
    const loanList = useSelector(getFormLoanDetailslist)
    const borrowerDetailsData = useSelector(borrowerDetailsDatas)
    const [borrowerDetails, setBorrowerDetails] = useState({
        name: '',
        address: '',
        registerNumber: '',
        panNumber: '',
        businessActivity: '',
        contactOfficialName: '',
        designation: '',
        phoneNumber: '',
        faxNumber: '',
        emailId: '',

        borrowerCategoryType: '',
        detailedCategoryType: '',
        subSector: '',
        detailCategoryOther: '',
        isFinancialInstitution: '',
        nbfcIfcAfcRegNumber: '',
        nbfcMfcRegNumber: '',
        nbfcOthersRegNumber: '',
        isNgo: '',
        isMicroFinanceInstitution: '',
        bankOthers: '',

    });

    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        name: false,
        address: false,
        registerNumber: false,
        panNumber: false,
        businessActivity: false,
        contactOfficialName: false,
        designation: false,
        phoneNumber: false,
        faxNumber: false,
        emailId: false,

        borrowerCategoryType: false,
        detailedCategoryType: false,
        isFinancialInstitution: false,
        nbfcIfcAfcRegNumber: false,
        nbfcMfcRegNumber: false,
        nbfcOthersRegNumber: false,
        isNgo: false,
        isMicroFinanceInstitution: false,
        bankOthers: false,
        subSector: false,
        detailCategoryOther: false
    });


    // useEffect(() => {
    //     if (borrowerDetailsData) {
    //         setBorrowerDetails(borrowerDetailsData)
    //     }
    // }, [dispatch, borrowerDetailsData])
    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        const callback = (res) => {
            setLoading(false);
            if (res?.response_code === 0) {
                setBorrowerDetails(res?.response)
            } else if (res?.error) {
                const { data } = res.error?.response || {}
                toast.error(`${data?.response_message}`)
            } else {
                toast.error("Sorry somethiing went wrong")
            }
        };
        if ((getAgreementDetails?.ecbApplicationType?.toString() === '2' || agreementDetailsList?.loanId) || (isBack && (Array.isArray(borrowerDetailsData) ? false : true))) {
            const params = {
                loanId: isBack ? loanList?.id : getAgreementDetails?.loanId ?? agreementDetailsList?.loanId,
                isDraft: isDraft
            };
            setLoading(true);
            dispatch(Actions.getBorrowerDetailsList(params, callback))
        }
    }, [dispatch, getAgreementDetails?.ecbApplicationType, getAgreementDetails?.loanId, isBack])
    const handleFieldChange = (field, value) => {
        switch (field) {
            case 'name':
                value = value.toUpperCase();
                break;
            case 'address':
                value = value.toUpperCase();
                break;
            default:
                break;
        }
        setBorrowerDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleOriginalNext = () => {
        onNext("borrowerDetails")
    }
    const handleOriginalBack = () => {
        onNext('loanDetails')
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        for (const field in borrowerDetails) {
            if (field === 'subSector' || field === 'detailCategoryOther' || field === 'updatedDate') {
                newErrors[field] = false;
                continue;
            }
            if (borrowerDetails[field] === '' || borrowerDetails[field] === null) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }
        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            setLoading(true);
            const callback = (res) => {
                setLoading(false);
                if (!res.error) {
                    onNext('borrowerDetails')
                } else if (res?.error) {
                    const { data } = res.error?.response || {}
                    toast.error(`${data?.response_message}`)
                } else {
                    toast.error("Sorry somethiing went wrong")
                }
            };

            let params = {
                ...borrowerDetails,
                loanId: loanList?.id,
                isDraft: isDraft
            };
            dispatch(Actions.borrowerDetailsData(params))
            dispatch(Actions.FormBorrowerDetailsList(params, callback));
            // Perform form submission logic here
        }
    };

    const Back = () => {
        setBack(true)
        onBack("lenderDetails")
    }
    return (
        <React.Fragment>
            <div className="py-3">
                {/* <h6 className="sub_heading">Borrower details</h6> */}
                <div className="row mt-4">
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Name: <span className="note_info">(Note: Should be in capital)</span></label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Name of authority"
                                    value={borrowerDetails?.name}
                                    onChange={(e) => handleFieldChange('name', e?.target?.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus?.name && BorrowDetailsValidateField('name', borrowerDetails?.name) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('name', borrowerDetails?.name)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Address: <span className="note_info">(Note: Should be in capital)</span></label>
                            <div className="col-md-8">
                                <textarea
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Address"
                                    value={borrowerDetails?.address}
                                    onChange={(e) => handleFieldChange('address', e?.target?.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus?.address && BorrowDetailsValidateField('address', borrowerDetails?.address) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('address', borrowerDetails?.address)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Registration Number given by the Registrar of Companies:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Enter Registration Number"
                                    value={borrowerDetails?.registerNumber}
                                    onChange={(e) => handleFieldChange('registerNumber', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.registerNumber && BorrowDetailsValidateField('registerNumber', borrowerDetails?.registerNumber) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('registerNumber', borrowerDetails?.registerNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">PAN Number of Company:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="PAN Number of Company"
                                    value={borrowerDetails?.panNumber}
                                    onChange={(e) => handleFieldChange('panNumber', e.target.value)}
                                    maxLength={10}
                                />
                                {fieldChangedStatus.panNumber && BorrowDetailsValidateField('panNumber', borrowerDetails?.panNumber) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('panNumber', borrowerDetails?.panNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Business Activity:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Business Activity"
                                    value={borrowerDetails?.businessActivity}
                                    onChange={(e) => handleFieldChange('businessActivity', e.target.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus.businessActivity && BorrowDetailsValidateField('businessActivity', borrowerDetails?.businessActivity) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('businessActivity', borrowerDetails?.businessActivity)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Contact Official’s Name:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Contact Official's Name"
                                    value={borrowerDetails?.contactOfficialName}
                                    onChange={(e) => handleFieldChange('contactOfficialName', e.target.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus.contactOfficialName && BorrowDetailsValidateField('contactOfficialName', borrowerDetails?.contactOfficialName) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('contactOfficialName', borrowerDetails?.contactOfficialName)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Designation:</label>
                            <div className="col-md-8">
                                <input
                                    type="text"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Designation"
                                    value={borrowerDetails?.designation}
                                    onChange={(e) => handleFieldChange('designation', e.target.value)}
                                    maxLength={50}
                                />
                                {fieldChangedStatus.designation && BorrowDetailsValidateField('designation', borrowerDetails?.designation) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('designation', borrowerDetails?.designation)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Phone No:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Phone No"
                                    value={borrowerDetails?.phoneNumber}
                                    onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />

                                {fieldChangedStatus.phoneNumber && BorrowDetailsValidateField('phoneNumber', borrowerDetails?.phoneNumber) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('phoneNumber', borrowerDetails?.phoneNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Fax No:</label>
                            <div className="col-md-8">
                                <input
                                    type="number"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Fax No"
                                    value={borrowerDetails?.faxNumber}
                                    onChange={(e) => handleFieldChange('faxNumber', e.target.value)}
                                    onInput={(e) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                    }}
                                />
                                {fieldChangedStatus.faxNumber && BorrowDetailsValidateField('faxNumber', borrowerDetails?.faxNumber) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('faxNumber', borrowerDetails?.faxNumber)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-3">
                        <div className="row">
                            <label className="col-md-4 form-label label_head">Email ID:</label>
                            <div className="col-md-8">
                                <input
                                    type="email"
                                    className={`form-control ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    placeholder="Email ID"
                                    value={borrowerDetails?.emailId}
                                    onChange={(e) => handleFieldChange('emailId', e.target.value)}
                                />
                                {fieldChangedStatus.emailId && BorrowDetailsValidateField('emailId', borrowerDetails?.emailId) && (
                                    <span className="text-danger">{BorrowDetailsValidateField('emailId', borrowerDetails?.emailId)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <h5 className="text-danger">Category</h5>
                    <div className="row mt-3">
                        <p className="col-md-2 label_head m-0">Borrower Category:</p>
                        <div className="col-md-10 d-flex">
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={1}
                                    checked={borrowerDetails?.borrowerCategoryType === 1}
                                    onChange={() => handleFieldChange('borrowerCategoryType', 1)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Public Sector</label>
                            </div>
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={2}
                                    checked={borrowerDetails?.borrowerCategoryType === 2}
                                    onChange={() => handleFieldChange('borrowerCategoryType', 2)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Private Sector</label>
                            </div>
                        </div>
                        {fieldChangedStatus.emailId && BorrowDetailsValidateField('borrowerCategoryType', borrowerDetails?.borrowerCategoryType) && (
                            <span className="text-danger">{BorrowDetailsValidateField('borrowerCategoryType', borrowerDetails?.borrowerCategoryType)}</span>
                        )}
                    </div>
                    <div className="row mt-3">
                        <p className="col-md-2 label_head m-0">Detailed Category:</p>
                        <div className="col-md-10 d-md-flex">
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={1}
                                    checked={borrowerDetails?.detailedCategoryType === 1}
                                    onChange={() => handleFieldChange('detailedCategoryType', 1)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Corporate - Manufacturing</label>
                            </div>
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={2}
                                    checked={borrowerDetails?.detailedCategoryType === 2}
                                    onChange={() => handleFieldChange('detailedCategoryType', 2)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Corporate –Infrastructure</label>
                            </div>
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={3}
                                    checked={borrowerDetails?.detailedCategoryType === 3}
                                    onChange={() => handleFieldChange('detailedCategoryType', 3)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Corporate –Service Sector</label>
                            </div>
                            <div className="col-md-3 col-4 form-check">
                                <input
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    disabled={isDisabled}
                                    type="radio"
                                    value={4}
                                    checked={borrowerDetails?.detailedCategoryType === 4}
                                    onChange={() => handleFieldChange('detailedCategoryType', 4)}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Others</label>
                            </div>
                        </div>
                        {fieldChangedStatus.emailId && BorrowDetailsValidateField('detailedCategoryType', borrowerDetails?.detailedCategoryType) && (
                            <span className="text-danger">{BorrowDetailsValidateField('detailedCategoryType', borrowerDetails?.detailedCategoryType)}</span>
                        )}
                        {borrowerDetails?.detailedCategoryType === 2 && <div className="row mt-3">
                            <div className="col-auto">
                                <p>a) Transport </p>
                            </div>
                            <div className="col-auto">
                                <p>b) Energy </p>
                            </div>
                            <div className="col-auto">
                                <p>c) Water and Sanitation </p>
                            </div>
                            <div className="col-auto">
                                <p>d) Communication </p>
                            </div>
                            <div className="col-auto">
                                <p>e) Social and Commercial Infrastructure </p>
                            </div>
                            <div className="col-auto">
                                <p>f) Exploration, Mining and Refinery </p>
                            </div>
                            <div className="col-auto">
                                <p>g) Others </p>
                            </div>
                            <div className="col-md-4">
                                <p className="d-flex">Sub-Sector:<input className="sub_sector" type="text"
                                    value={borrowerDetails?.subSector}
                                    onChange={(e) => handleFieldChange('subSector', e.target.value)}
                                /> </p>
                            </div>

                        </div>}
                        {borrowerDetails?.detailedCategoryType === 4 && <div className="row mt-3">
                            <div className="col-auto">
                                <p>a) Units in SEZ </p>
                            </div>
                            <div className="col-auto">
                                <p>b) SIDBI </p>
                            </div>
                            <div className="col-auto">
                                <p>c) EXIM Bank </p>
                            </div>
                            <div className="col-auto">
                                <p>d) Micro-finance entities </p>
                            </div>

                            <div className="col-md-4">
                                <p className="d-flex">e)Others:<input className="sub_sector" type="text"
                                    value={borrowerDetails?.detailCategoryOther}
                                    disabled={isDisabled}
                                    onChange={(e) => handleFieldChange('detailCategoryOther', e.target.value)}
                                /> </p>
                            </div>

                        </div>}
                        <div className="mt-4">
                            <h5 className="text-danger">Bank</h5>
                            <div className="row mt-4">
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Financial Institution (other than NBFC)</label>
                                        <div className="col-md-8">
                                            <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                                value={borrowerDetails?.isFinancialInstitution}
                                                disabled={isDisabled}
                                                onChange={(e) => handleFieldChange('isFinancialInstitution', e.target.value)}
                                            >
                                                <option hidden>Select</option>
                                                <option value={1}>Yes</option>
                                                <option value={2}>No</option>
                                            </select>
                                            {fieldChangedStatus.isFinancialInstitution && BorrowDetailsValidateField('isFinancialInstitution', borrowerDetails?.isFinancialInstitution) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('isFinancialInstitution', borrowerDetails?.isFinancialInstitution)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">NBFC- IFC/AFC Reg.No.</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                placeholder="NBFC- IFC/AFC Reg.No."
                                                disabled={isDisabled}
                                                value={borrowerDetails?.nbfcIfcAfcRegNumber}
                                                onChange={(e) => handleFieldChange('nbfcIfcAfcRegNumber', e.target.value)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                            />
                                            {
                                                fieldChangedStatus.nbfcIfcAfcRegNumber && BorrowDetailsValidateField('nbfcIfcAfcRegNumber', borrowerDetails?.nbfcIfcAfcRegNumber) && (
                                                    <span className="text-danger">{BorrowDetailsValidateField('nbfcIfcAfcRegNumber', borrowerDetails?.nbfcIfcAfcRegNumber)}</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">NBFC- MFI Reg.No.</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                placeholder="NBFC- MFI Reg.No."
                                                disabled={isDisabled}
                                                value={borrowerDetails?.nbfcMfcRegNumber}
                                                onChange={(e) => handleFieldChange('nbfcMfcRegNumber', e.target.value)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                            />
                                            {fieldChangedStatus.nbfcMfcRegNumber && BorrowDetailsValidateField('nbfcMfcRegNumber', borrowerDetails?.nbfcMfcRegNumber) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('nbfcMfcRegNumber', borrowerDetails?.nbfcMfcRegNumber)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">NBFC- Others Reg.No.</label>
                                        <div className="col-md-8">
                                            <input
                                                type="number"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                placeholder="NBFC- Others Reg.No."
                                                disabled={isDisabled}
                                                value={borrowerDetails?.nbfcOthersRegNumber}
                                                onChange={(e) => handleFieldChange('nbfcOthersRegNumber', e.target.value)}
                                                onInput={(e) => {
                                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                                }}
                                            />
                                            {fieldChangedStatus.nbfcOthersRegNumber && BorrowDetailsValidateField('nbfcOthersRegNumber', borrowerDetails?.nbfcOthersRegNumber) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('nbfcOthersRegNumber', borrowerDetails?.nbfcOthersRegNumber)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Non-Government Organization (NGO)</label>
                                        <div className="col-md-8">
                                            <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                                value={borrowerDetails?.isNgo}
                                                disabled={isDisabled}
                                                onChange={(e) => handleFieldChange('isNgo', e.target.value)}
                                            >
                                                <option hidden>Select</option>
                                                <option value={1}>Yes</option>
                                                <option value={2}>No</option>
                                            </select>
                                            {fieldChangedStatus.isNgo && BorrowDetailsValidateField('isNgo', borrowerDetails.isNgo) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('isNgo', borrowerDetails.isNgo)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">MicroFinance Institution (MFI)</label>
                                        <div className="col-md-8">
                                            <select className={`form-select ${isDisabled ? 'disable' : ''}`}
                                                disabled={isDisabled}
                                                value={borrowerDetails?.isMicroFinanceInstitution}
                                                onChange={(e) => handleFieldChange('isMicroFinanceInstitution', e.target.value)}
                                            >
                                                <option hidden>Select</option>
                                                <option value={1}>Yes</option>
                                                <option value={2}>No</option>
                                            </select>
                                            {fieldChangedStatus.isMicroFinanceInstitution && BorrowDetailsValidateField('isMicroFinanceInstitution', borrowerDetails.isMicroFinanceInstitution) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('isMicroFinanceInstitution', borrowerDetails.isMicroFinanceInstitution)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-3">
                                    <div className="row">
                                        <label className="col-md-4 form-label label_head">Others</label>
                                        <div className="col-md-8">
                                            <input
                                                type="text"
                                                className={`form-control ${isDisabled ? 'disable' : ''}`}
                                                placeholder="If any other comments, specify here"
                                                value={borrowerDetails?.bankOthers}
                                                disabled={isDisabled}
                                                onChange={(e) => handleFieldChange('bankOthers', e.target.value)}
                                            />
                                            {fieldChangedStatus.bankOthers && BorrowDetailsValidateField('bankOthers', borrowerDetails?.bankOthers) && (
                                                <span className="text-danger">{BorrowDetailsValidateField('bankOthers', borrowerDetails?.bankOthers)}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="text-danger">
                    <button className="btn back_btn" onClick={() => Back()}>Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleSubmit} className="btn next_btn">Next</button>
                </div>
            </div>}
            {isDraft == false && <div className="d-flex justify-content-end align-items-center mt-4">
                <div className="text-danger">
                    <button className="btn back_btn" onClick={handleOriginalBack}>Back</button>
                </div>
                <div className="ms-3">
                    <button onClick={handleOriginalNext} className="btn next_btn">Next</button>
                </div>
            </div>}
            {
                isloading && <Loader />
            }
        </React.Fragment>
    );
}

export default BorrowerDetails;
