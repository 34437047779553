import { Actions } from '../action';

const initialState = {
    isLoading: false,
    repayment: [],
    error: false,
}
export const repaymentReducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.CREATE_REPAYMENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.CREATE_REPAYMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                repayment: action?.data,
                error: false
            };
        }
        case Actions.CREATE_REPAYMENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.UPDATE_REPAYMENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.UPDATE_REPAYMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                repayment: action?.data,
                error: false
            };
        }
        case Actions.UPDATE_REPAYMENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.DELETE_REPAYMENT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.DELETE_REPAYMENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                repayment: action?.data,
                error: false
            };
        }
        case Actions.DELETE_REPAYMENT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
};



export const repayment = (state) => state?.REPAYMENT?.repayment;