import * as FormList from './form';
import * as login from './loginAction';
import * as summary from './summary';
import * as OtherChargesAction from './OtherChargesAction';
import * as masterTable from './masterTable';
import * as borrowingPurpose from './borrowingPurpose';
import * as drawdown from './drawDown';
import * as repayment from './repayment';

export const Actions = {
    ...login,
    ...FormList,
    ...summary,
    ...OtherChargesAction,
    ...masterTable,
    ...borrowingPurpose,
    ...drawdown,
    ...repayment
}
export const doAction = (type, params = {}, callback) => ({ type, params, callback });
export const doResponseAction = (type, data = {}) => ({ type, data });