import React, { useEffect, useState } from 'react';
import { agreementDetailsValidateField } from '../utils/Validation';
import { useDispatch, useSelector } from 'react-redux';
import { Actions } from '../redux/action';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import Loader from './HOC/Loader';

const AgreementDetails = ({ onNext, editAgreementDetails, isFormPage }) => {

    const dispatch = useDispatch()
    const [isloading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const [agreementDetails, setAgreementDetails] = useState({
        ecbApplicationType: '',
        formType: '',
        lrn: '',
        ecbUnderRoute: '',
        isRequireClearance: '',
        clearanceAuthorityName: '',
        clearanceNumber: '',
        clearanceDate: '',
        comments: '',

    });
    const [fieldChangedStatus, setFieldChangedStatus] = useState({
        ecbApplicationType: false,
        formType: false,
        lrn: false,
        ecbUnderRoute: false,
        isRequireClearance: false,
        clearanceAuthorityName: false,
        clearanceNumber: false,
        clearanceDate: false,
        comments: false,
    });

    const handleFieldChange = (field, value) => {
        setAgreementDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleRadioButtonClick = (field, value) => {
        setAgreementDetails((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        setFieldChangedStatus((prevStatus) => ({
            ...prevStatus,
            [field]: true,
        }));
    };
    const handleOriginalNext = () => {
        onNext("agreementDetailsNext")
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if ((agreementDetails?.ecbApplicationType?.toString() === "1")) {
            if (agreementDetails?.ecbApplicationType?.toString() === '') {
                setFieldChangedStatus((prevStatus) => ({
                    ...prevStatus,
                    'ecbApplicationType': true,
                }));
            }
            else if (agreementDetails?.formType?.toString() === '') {
                setFieldChangedStatus((prevStatus) => ({
                    ...prevStatus,
                    'formType': true,
                }));
            } else {
                onNext(agreementDetails);
            }
            return
        }
        // Validate all fields before submission
        const newErrors = {};
        let hasErrors = false;
        if (agreementDetails?.isRequireClearance?.toString() === '2') {
            delete agreementDetails?.clearanceAuthorityName;
            delete agreementDetails?.clearanceNumber;
            delete agreementDetails?.clearanceDate;
            delete agreementDetails?.comments;
        }
        for (const field in agreementDetails) {
            if (agreementDetailsValidateField(field, agreementDetails[field])) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        }

        setFieldChangedStatus(newErrors);
        if (!hasErrors) {
            // Perform form submission logic here

            if (agreementDetails?.ecbApplicationType?.toString() === "2") {
                const callback = (res) => {
                    setLoading(false)
                    if (res?.error) {
                        const { data } = res.error?.response || {}
                        toast.error(`${data?.response_message}`)
                    } else if (res?.response_code === 0) {
                        onNext(agreementDetails);
                    } else {
                        toast.error("Sorry somethiing went wrong")
                    }
                }
                agreementDetails.isDraft = isDraft
                setLoading(true)
                dispatch(Actions.AgreementDetails(agreementDetails, callback))
            }
        }
    };
    useEffect(() => {
        if (isDraft === false) {
            setIsDisabled(true);
        }
        if (isFormPage && editAgreementDetails?.id) {
            const callback = (res) => {
                setLoading(false)
                const { DraftLoanDetail, ...otherProperties } = res?.response;
                otherProperties.ecbApplicationType = 2;
                otherProperties.lrn = editAgreementDetails.lrn
                if (!res.error) {
                    setAgreementDetails(otherProperties)
                }
            }
            const params = {
                loanId: editAgreementDetails?.id,
                isDraft: isDraft
            }
            setLoading(true)
            dispatch(Actions.getAgreementDetails(params, callback))
        }
    }, [dispatch])
    return (
        <React.Fragment>
            <div className="py-3">
                {/* <h6 className="sub_heading mt-3">Agreement Details (To be filled by borrowers of External Commercial Borrowings)</h6> */}
                <div className="row mt-4">
                    <p className="col-md-2 m-0 label_head">ECB Application:</p>
                    <div className="col-md-5 d-flex">
                        <div className="col-md-5 col-3 form-check mb-0">
                            <input
                                disabled={isDisabled}
                                className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                type="radio"
                                value="1"
                                checked={agreementDetails?.ecbApplicationType?.toString() === '1'}
                                onChange={() => handleRadioButtonClick('ecbApplicationType', '1')}
                            />
                            <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Original</label>
                        </div>
                        <div className="col-md-5 col-3 form-check ms-md-0 ms-3 mb-0">
                            <input
                                disabled={isDisabled}
                                className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                type="radio"
                                value="2"
                                checked={agreementDetails?.ecbApplicationType?.toString() === '2'}
                                onChange={() => handleRadioButtonClick('ecbApplicationType', '2')}
                            />
                            <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Revised</label>
                        </div>
                    </div>
                    {fieldChangedStatus.ecbApplicationType && agreementDetailsValidateField('ecbApplicationType', agreementDetails.ecbApplicationType) && (
                        <span className="text-danger">{agreementDetailsValidateField('ecbApplicationType', agreementDetails.ecbApplicationType)}</span>
                    )}
                </div>
                <div className="row mt-3">
                    <p className="col-md-2 m-0 label_head">Form:</p>
                    <div className="col-md-5 d-flex">
                        <div className="col-md-5 col-3 form-check mb-0">
                            <input
                                className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                type="radio"
                                value="1"
                                disabled={isDisabled}
                                checked={agreementDetails?.formType.toString() === '1'}
                                onChange={() => handleRadioButtonClick('formType', '1')}
                            />
                            <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>FCY ECB</label>
                        </div>
                        <div className="col-md-5 col-3 form-check ms-md-0 ms-3 mb-0">
                            <input
                                className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                type="radio"
                                value="2"
                                disabled={isDisabled}
                                checked={agreementDetails?.formType.toString() === '2'}
                                onChange={() => handleRadioButtonClick('formType', '2')}
                            />
                            <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>INR ECB</label>
                        </div>
                    </div>
                    {fieldChangedStatus.formType && agreementDetailsValidateField('formType', agreementDetails.formType) && (
                        <span className="text-danger">{agreementDetailsValidateField('formType', agreementDetails.formType)}</span>
                    )}
                </div>
                {(agreementDetails?.ecbApplicationType?.toString() === "2" || agreementDetails?.ecbApplicationType?.toString() === "") && <> <h5 className="mt-4 mb-4">In case of Revised</h5>
                    <div className="row mt-3">
                        <div className="col-md-6 mt-3">
                            <div className="row">
                                <label className="col-md-4 label_head">Loan Registration Number allotted:</label>
                                <div className="col-md-8">
                                    <input
                                        type="number"
                                        disabled={isDisabled || isDraft}
                                        className={`form-control ${isDisabled || isDraft ? 'disable' : ''}`}
                                        placeholder="Registration Number"
                                        value={agreementDetails.lrn}
                                        onInput={(e) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                        }}
                                        onChange={(e) => handleFieldChange('lrn', e.target.value)}
                                    />
                                    {fieldChangedStatus.lrn && agreementDetailsValidateField('lrn', agreementDetails.lrn) && (
                                        <span className="text-danger">{agreementDetailsValidateField('lrn', agreementDetails.lrn)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <p className="col-md-2 m-0 label_head">ECB Under:</p>
                        <div className="col-md-5 d-flex">
                            <div className="col-md-5 col-3 form-check">
                                <input
                                    disabled={isDisabled}
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    type="radio"
                                    value="approvalRoute"
                                    checked={agreementDetails?.ecbUnderRoute === 'approvalRoute'}
                                    onChange={() => handleRadioButtonClick('ecbUnderRoute', 'approvalRoute')}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}
                                >Approval route</label>
                            </div>
                            <div className="col-md-5 col-3 ms-md-0 ms-3 form-check">
                                <input
                                    type="radio"
                                    value="automaticRoute"
                                    disabled={isDisabled}
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    checked={agreementDetails?.ecbUnderRoute === 'automaticRoute'}
                                    onChange={() => handleRadioButtonClick('ecbUnderRoute', 'automaticRoute')}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}
                                >Automatic route</label>
                            </div>
                        </div>
                        {fieldChangedStatus.ecbUnderRoute && agreementDetailsValidateField('ecbUnderRoute', agreementDetails.ecbUnderRoute) && (
                            <span className="text-danger">{agreementDetailsValidateField('ecbUnderRoute', agreementDetails.ecbUnderRoute)}</span>
                        )}
                    </div>
                    <div className="row mt-3">
                        <p className="col-md-2 m-0 label_head">Whether requires clearance from any statutory authority?</p>
                        <div className="col-md-5 d-flex">
                            <div className="col-md-5 col-3 form-check">
                                <input
                                    type="radio"
                                    value="yes"
                                    disabled={isDisabled}
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    checked={agreementDetails?.isRequireClearance.toString() === '1'}
                                    onChange={() => handleRadioButtonClick('isRequireClearance', "1")}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>Yes</label>
                            </div>
                            <div className="col-md-5 col-3 ms-md-0 ms-3 form-check">
                                <input
                                    type="radio"
                                    value="no"
                                    disabled={isDisabled}
                                    className={`form-check-input ${isDisabled ? 'disable' : ''}`}
                                    checked={agreementDetails?.isRequireClearance.toString() === '2'}
                                    onChange={() => handleRadioButtonClick('isRequireClearance', '2')}
                                />
                                <label className={`form-check-label ${isDisabled ? 'disable' : ''}`}>No</label>
                            </div>
                        </div>
                        {fieldChangedStatus.isRequireClearance && agreementDetailsValidateField('isRequireClearance', agreementDetails.isRequireClearance) && (
                            <span className="text-danger">{agreementDetailsValidateField('isRequireClearance', agreementDetails.isRequireClearance)}</span>
                        )}
                    </div>
                    {(agreementDetails?.isRequireClearance?.toString() === '1' || agreementDetails?.isRequireClearance?.toString() === '') &&
                        <div className="row mt-4">
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Name of authority:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="text"
                                            placeholder="Name of authority"
                                            disabled={isDisabled}
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            value={agreementDetails.clearanceAuthorityName}
                                            onChange={(e) => handleFieldChange('clearanceAuthorityName', e.target.value)}
                                            maxLength={'50'}
                                        />
                                        {fieldChangedStatus.clearanceAuthorityName && agreementDetailsValidateField('clearanceAuthorityName', agreementDetails.clearanceAuthorityName) && (
                                            <span className="text-danger">{agreementDetailsValidateField('clearanceAuthorityName', agreementDetails.clearanceAuthorityName)}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Clearance No:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="number"
                                            placeholder="Clearance no"
                                            disabled={isDisabled}
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            value={agreementDetails.clearanceNumber}
                                            onChange={(e) => handleFieldChange('clearanceNumber', e.target.value)}
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 15)
                                            }}
                                        />
                                        {fieldChangedStatus.clearanceNumber && agreementDetailsValidateField('clearanceNumber', agreementDetails.clearanceNumber) && (
                                            <span className="text-danger">{agreementDetailsValidateField('clearanceNumber', agreementDetails.clearanceNumber)}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Date:</label>
                                    <div className="col-md-8">
                                        <input
                                            type="date"
                                            className={`form-control ${isDisabled ? 'disable' : ''}`}
                                            placeholder="Date"
                                            disabled={isDisabled}
                                            value={moment(agreementDetails?.clearanceDate).format('YYYY-MM-DD')}
                                            onChange={(e) => handleFieldChange('clearanceDate', e.target.value)}
                                        />
                                        {fieldChangedStatus.clearanceDate && agreementDetailsValidateField('clearanceDate', agreementDetails.clearanceDate) && (
                                            <span className="text-danger">{agreementDetailsValidateField('clearanceDate', agreementDetails.clearanceDate)}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="row">
                                    <label className="col-md-4 form-label label_head">Comments/recommendation of AD bank:</label>
                                    <div className="col-md-8">
                                        <textarea
                                            className={`form-control text_area_height ${isDisabled ? 'disable' : ''}`}
                                            placeholder="Comments"
                                            disabled={isDisabled}
                                            value={agreementDetails.comments}
                                            onChange={(e) => handleFieldChange('comments', e.target.value)}
                                        />
                                        {fieldChangedStatus.comments && agreementDetailsValidateField('comments', agreementDetails.comments) && (
                                            <span className="text-danger">{agreementDetailsValidateField('comments', agreementDetails.comments)}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>}
            </div>
            {isDraft == true && <div className="d-flex justify-content-end align-items-center mt-4">
                <button onClick={handleSubmit} className="btn next_btn">Next</button>
            </div>}
            {isDraft == false && <div className="d-flex justify-content-end align-items-center mt-4">
                <button onClick={handleOriginalNext} className="btn next_btn">Next</button>
            </div>}
            {
                isloading && <Loader />
            }
        </React.Fragment>
    );
}

export default AgreementDetails;
