import { Actions } from '../action';

const initialState = {
    isDraft:true,
    isLoading: false,
    formlist: [],
    formLoanDetailslist: [],
    originallist:[],
    formLenderDetailslist:[],
    agreementDetails:[],
    agreementDetailsList:[],
    getLoanDetails:undefined,
    formBorrowerDetailslist:[],
    getLenderDetailslist:[],
    getBorrowerDetailslist:[],
    ecbDetailslist:[],
    user: null,
    error: false,
    loanDetailsData:[],
    lenderDetailsData:[],
    borrowerDetailsData:[],
    ecbDetailsData:[],
}
export const formlistreducers = (state = initialState, action) => {
    switch (action.type) {
        case Actions.FORM_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formlist: action?.data?.draftLoanList,
                error: false
            };
        }
        case Actions.FORM_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ISDRAFT: {

            return {
                ...state,
                isDraft:action?.data,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.FORM_LOAN_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_LOAN_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formLoanDetailslist: action?.data,
                error: false
            };
        }
        case Actions.FORM_LOAN_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
      case Actions.ORIGINAL_LOAN_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ORIGINAL_LOAN_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                originallist: action?.data?.loanList,
                error: false
            };
        }
        case Actions.ORIGINAL_LOAN_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.FORM_LENDER_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_LENDER_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formLenderDetailslist: action?.data,
                error: false
            };
        }
        case Actions.FORM_LENDER_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.AGREEMENT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.AGREEMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agreementDetails: action?.data,
                error: false
            };
        }   
        case Actions.AGREEMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_AGREEMENT_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_AGREEMENT_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                agreementDetailsList: action?.data,
                error: false
            };
        }   
        case Actions.GET_AGREEMENT_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_BORROWER_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.FORM_BORROWER_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                formBorrowerDetailslist: action?.data,
                error: false
            };
        }
       
       
        case Actions.FORM_BORROWER_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }

        case Actions.GET_LOAN_DETAILS: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_LOAN_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getLoanDetails: action?.data,
                error: false
            };
        }
        case Actions.GET_LOAN_DETAILS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_LENDER_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_LENDER_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getLenderDetailslist: action?.data,
                error: false
            };
        }
        case Actions.GET_LENDER_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.GET_BORROWER_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.GET_BORROWER_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                getBorrowerDetailslist: action?.data,
                error: false
            };
        }
        case Actions.GET_BORROWER_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.ECB_DETAILS_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ECB_DETAILS_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                ecbDetailslist: action?.data,
                error: false
            };
        }
        case Actions.ECB_DETAILS_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        }
        case Actions.LOAN_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                loanDetailsData: action?.data,
                error: false
            };
        }
        case Actions.LENDER_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                lenderDetailsData: action?.data,
                error: false
            };
        }
        case Actions.BORROWER_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                borrowerDetailsData: action?.data,
                error: false
            };
        }
        case Actions.ECB_DETAILS_DATA_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                ecbDetailsData: action?.data,
                error: false
            };
        }
       default:
            return state;
    }
};



export const getFormList = (state) => state?.FORM.formlist;
export const getFormLoanDetailslist = (state) => state?.FORM.formLoanDetailslist;
export const getOriginalList = (state) => state?.FORM.originallist;
export const getFormLenderDetailslist = (state) => state?.FORM.formLenderDetailslist;
export const getLoanDetailsSelector = (state) => state?.FORM.getLoanDetails;
export const getagreementDetails = (state) => state?.FORM.agreementDetails;
export const getAgreementDetailsList = (state) => state?.FORM.agreementDetailsList;
export const getFormBorrowerDetailslist = (state) => state?.FORM.formBorrowerDetailslist;
export const getLenderDetailslist = (state) => state?.FORM.getLenderDetailslist;
export const getBorrowerDetailslist = (state) => state?.FORM.getBorrowerDetailslist;
export const getEcbDetailslist = (state) => state?.FORM.ecbDetailslist;



export const loanDetailsDatas = (state) => state?.FORM.loanDetailsData;
export const lenderDetailsDatas = (state) => state?.FORM.lenderDetailsData;
export const borrowerDetailsDatas = (state) => state?.FORM.borrowerDetailsData;
export const ecbDetailsDatas = (state) => state?.FORM.ecbDetailsData;