import { MESSAGE } from "../constant/String";

export const isValidEmail = (email) => {
    const validEmailRegex = RegExp(/^[a-zA-Z0-9+.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/)
    return validEmailRegex.test(email);
}
export const isCharacterValid = (character) => {
    const onlyCharactersRegex = new RegExp(`^[a-zA-Z ]+$`);
    return onlyCharactersRegex.test(character)
}

export const isFieldEmpty = (field) => {
    return (!field || field?.trim().length === 0)
}
export const isSelectEmpty = (field) => {
    return (Object?.keys(field)?.length === 0)
}

export const validatePriceValue = (name) => {
    let re = /^\d{0,8}(\.\d{1,2})?$/;
    return re.test(name);
}

export const skuRegex = /^[A-Z]{3}-[0-9]{5}$/;
export const uppercaseRegExp = /(?=.*?[A-Z])/;
export const lowercaseRegExp = /(?=.*?[a-z])/;
export const digitsRegExp = /(?=.*?\d)/;
export const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
export const minLengthRegExp = /.{8,}/;
export const whiteSpaceValidation = (str) => {
    const validEmailRegex = RegExp('^[a-zA-Z0-9_]*$')
    return validEmailRegex.test(str);
}

export const validateName = (name) => {
    let re = /^[a-zA-Z ]{3,30}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxName = (name) => {
    let re = /^[a-zA-Z ]{4,150}$/;
    return re.test(String(name).toLowerCase());
}
export const validateMaxProduct = (name) => {
    let re = /^[a-zA-Z ]{4,50}$/;
    return re.test(String(name).toLowerCase());
}
export const validateSku = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,50}$/;
    return re.test(name);
}
export const validateMaxAddress = (name) => {
    let re = /^[a-zA-Z0-9\s,.'-]{4,200}$/;
    return re.test(name);
}
export const validateMobileNumber = (number) => {
    let re = /^(\+91[\-\s]?)?[0]?(91)?[789654]\d{9}$/;
    return re.test(number);
}
export const validateuppercaseRegExp = (name) => {
    let re = /(?=.*?[A-Z])/;
    return re.test(String(name));
}
export const validatelowercaseRegExp = (name) => {
    let re = /(?=.*?[a-z])/;
    return re.test(String(name));
}
export const validatedigitsRegExp = (name) => {
    let re = /(?=.*?\d)/;
    return re.test(name);
}
export const validatespecialCharRegExp = (name) => {
    let re = /(?=.*?[#?!@$%^&*-])/;
    return re.test(name);
}
export const validateminLengthRegExp = (name) => {
    let re = /.{5,}/;
    return re.test(name);
}
export const validateminLength = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validateminserverLengthRegExp = (name) => {
    let re = /.{3,}/;
    return re.test(name);
}
export const validateminLengthReg = (name) => {
    let re = /.{4,}/;
    return re.test(name);
}
export const validatemaxLengthZipReg = (name) => {
    let re = /^.{50,}$/;
    return re.test(name);
}
export const validatemaxLength = (name) => {
    let re = /^.{200,}$/;
    return re.test(name);
}
export const validatemaxLengthZipRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const validatemaxLengthNumeberRegExp = (name) => {
    let re = /^.{10,}$/;
    return re.test(name);
}
export const checkPercentage = (field) => {
    return (Math.ceil(field) > 100)
}
export const validatePanCard = (num) => {
    let pan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return pan.test(num);
}

export const agreementDetailsValidateField = (field, value) => {
    switch (field) {
        case 'ecbApplicationType':
            if (!value) {
                return 'Please select the ECB application type.';
            }
            break;
        case 'formType':
            if (!value) {
                return 'Please select the form type.';
            }
            break;
        case 'lrn':
            if (!value) {
                return 'Please enter the loan registration number.';
            } else if (value.trim().length < 9) {
                return 'Loan registration Number must be 9 - 10 digit.'
            }
            break;
        case 'ecbUnderRoute':
            if (!value) {
                return 'Please select the ECB under route.';
            }
            break;
        case 'isRequireClearance':
            if (!value) {
                return 'Please select whether the clearance is required or not.';
            }
            break;
        case 'clearanceAuthorityName':
            if (!value) {
                return 'Please enter the clearance authority name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Clearance authority name minimum 3 character.'
            }

            break;
        case 'clearanceNumber':
            if (!value) {
                return 'Please enter the clearance number.';
            }
            break;
        case 'clearanceDate':
            if (!value) {
                return 'Please select the clearance date.';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};


export const LoanDetailsValidateField = (field, value) => {
    switch (field) {
        case 'loanAgreementDate':
            if (!value) {
                return 'Please select the loan agreement date.';
            }
            break;
        case 'effectiveDateOfLoan':
            if (!value) {
                return 'Please select the effective date of loan.';
            }
            break;
        case 'lastDateOfDispersement':
            if (!value) {
                return 'Please select the last date of dispersement.';
            }
            break;
        case 'maturityDate':
            if (!value) {
                return 'Please select the maturity date.';
            }
            break;
        case 'gracePeriodInYear':
            if (!value) {
                return 'Please enter the grace period year.';
            }
            break;
        case 'gracePeriodInMonth':
            if (!value) {
                return 'Please enter the grace period month.';
            }
            break;
        case 'currency':
            if (!value) {
                return 'Please select the currency name.';
            }
            break;
        // case 'currencyCode':
        //     if (!value) {
        //         return 'Please enter the currency code.';
        //     }
        //     break;
        case 'amount':
            if (!value) {
                return 'Please enter the amount.';
            }
            break;
        case 'equivalentAmount':
            if (!value) {
                return 'Please enter the equivalent amount.';
            }
            break;
        case 'foreignCurrencyExpenditure':
            if (!value) {
                return 'Please enter the foreign currency expenditure.';
            }
            break;
        case 'rupeeExpenditure':
            if (!value) {
                return 'Please enter the rupee expenditure.';
            }
            break;
        case 'hedgingDetailsType':
            if (!value) {
                return 'Please select the hedging details type.';
            }
            break;
        case 'financialHedge':
            if (!value) {
                return 'Please enter the financial hedge.';
            }
            break;
        case 'naturalHedge':
            if (!value) {
                return 'Please enter the natural hedge.';
            }
            break;
        // case 'loanTotalHedge':
        //     if (!value) {
        //         return 'Please enter the total hedge.';
        //     }
        //     break;
        case 'isOptionForLoanAgreements':
            if (!value) {
                return 'Please select the loan agreement options.';
            }
            break;
        case 'percentOfDebt':
            if (!value) {
                return 'Please enter the percent of debt.';
            }
            break;
        case 'executedAfterDate':
            if (!value) {
                return 'Please select the executed after date.';
            }
            break;
        case 'guarantorName':
            if (!value) {
                return 'Please enter the guarantor name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Guarantor name minimum 3 character.'
            }
            break;
        case 'guarantorDesignation':
            if (!value) {
                return 'Please enter the guarantor designation.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'guarantorPhoneNumber':
            if (!value) {
                return 'Please enter the guarantor phone number';
            }
            break;
        case 'guarantorFaxNumber':
            if (!value) {
                return 'Please enter the guarantor fax number.';
            }
            break;
        case 'guarantorEmailId':
            if (!value) {
                return 'Please enter email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        case 'guarantorStatusCode':
            if (!value) {
                return 'Please enter the guarantor status code.';
            }
            break;
        case 'isNatureAndDetailsOfSecurity':
            if (!value) {
                return 'Please select the nature and details of security.';
            }
            break;
        // case 'borrowingPurposeCodeId':
        //     if (!value) {
        //         return 'Please select the borrowing purpose.';
        //     }
        //     break;
        // case 'borrowingPurposeCurrency':
        //     if (!value) {
        //         return 'Please select the borrowing purpose currency.';
        //     }
        //     break;
        // case 'borrowingPurposeAmount':
        //     if (!value) {
        //         return 'Please enter the borrowing purpose amount .';
        //     }
        //     break;
        // case 'borrowingPurposePercentage':
        //     if (!value) {
        //         return 'Please enter the borrowing purpose percentage.';
        //     }
        //     break;
        case 'projectName':
            if (!value) {
                return 'Please enter the project name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Enter the project name minimum 3 character.'
            }
            break;
        case 'location':
            if (!value) {
                return 'Please enter the project location.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'projectCost':
            if (!value) {
                return 'Please enter the project cost.';
            }
            break;
        case 'moreThanOneCountry':
            if (!value) {
                return 'Please select the country of import.';
            }
            break;
        case 'projectIndustryCode':
            if (!value) {
                return 'Please enter the project industry code.';
            }
            break;
        case 'ecbType':
            if (!value) {
                return 'Please select the ECB type.';
            }
            break;
        case 'oldEcbLrn':
            if (!value) {
                return 'Please enter the lrn of the old ECB.';
            } else if (value.trim().length < 9) {
                return 'Loan registration Number must be 9 - 10 digit.'
            }
            break;
        case 'oldEcbApprovalNumber':
            if (!value) {
                return 'Please enter the Approval number of old ECB.';
            }
            break;
        case 'oldEcbApprovalDate':
            if (!value) {
                return 'Please select the Approval date of old ECB.';
            }
            break;
        case 'oldEcbApprovalRefinanced':
            if (!value) {
                return 'Please enter the amount refinanced of old ECB.';
            }
            break;
        case 'oldEcbReason':
            if (!value) {
                return 'Please enter the reason.';
            }
            break;
        case 'firstPaymentDate':
            if (!value) {
                return 'Please enter the first payment date.';
            }
            break;
        case 'numberOfPaymentsPerYear':
            if (!value) {
                return 'Please enter the number of payments per year.';
            }
            break;
        case 'fixedRate':
            if (!value) {
                return 'Please enter the fixed rate.';
            }
            break;
        case 'baseIthCurrency':
            if (!value) {
                return 'Please enter the base ith currency.';
            }
            break;
        case 'marginCurrency':
            if (!value) {
                return 'Please enter the margin currency.';
            }
            break;
        case 'capRate':
            if (!value) {
                return 'Please enter the cap rate.';
            }
            break;
        case 'floorRate':
            if (!value) {
                return 'Please enter the floor rate.';
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const lenderDetailsValidateField = (field, value) => {
    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Enter the name minimum 3 character.'
            }
            break;
        case 'address':
            if (!value) {
                return 'Please enter the address.';
            } else if (value.trim().length < 3) {
                return 'Enter the address minimum 3 character.'
            }
            break;
        case 'country':
            if (!value) {
                return 'Please enter the country.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'emailId':
            if (!value) {
                return 'Please enter the email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        case 'lenderCategoryType':
            if (!value) {
                return 'Please select lender category type.';
            }
            break;
        case 'equityOfTheBorrower':
            if (!value) {
                return 'Please enter the equity of the borrower.';
            }
            break;
        case 'amountPaidUpCapital':
            if (!value) {
                return 'Please enter the amount of paid up capital.';
            }
            break;
        case 'ecbLiabilityRatio':
            if (!value) {
                return 'Please enter the ECB liability.';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const BorrowDetailsValidateField = (field, value) => {
    switch (field) {
        case 'name':
            if (!value) {
                return 'Please enter the name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Enter the name minimum 3 character.'
            }
            break;
        case 'address':
            if (!value) {
                return 'Please enter the address.';
            } else if (value.trim().length < 3) {
                return 'Enter the address minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'registerNumber':
            if (!value) {
                return 'Please enter the registration number.';
            }
            else if (value.trim().length < 9) {
                return 'Register number must be 10 digits.'
            }
            break;
        case 'panNumber':
            if (!value) {
                return 'Please enter the pan number.';
            } else if (value.trim().length < 3) {
                return 'Enter the pan card minimum 3 character.'
            } else if (value.trim().length < 9) {
                return 'Pan number must be  10 digits.'
            }
            break;
        case 'businessActivity':
            if (!value) {
                return 'Please enter the business activity.';
            } else if (value.trim().length < 3) {
                return 'Enter the business activity minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'contactOfficialName':
            if (!value) {
                return `Please enter the contact official's name.`;
            } else if (value.trim().length < 3) {
                return 'Enter the contact official name minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'designation':
            if (!value) {
                return 'Please enter the designation.';
            } else if (value.trim().length < 3) {
                return 'Enter the designation minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'phoneNumber':
            if (!value) {
                return 'Please enter the phone number.';
            } else if (value.trim().length < 10) {
                return 'Phone Number must be 10 digit.'
            }
            break;
        case 'faxNumber':
            if (!value) {
                return 'Please enter the fax number.';
            } else if (value.trim().length < 10) {
                return 'Fax Number must be 10 digit.'
            }
            break;
        case 'emailId':
            if (!value) {
                return 'Please enter the email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        case 'borrowerCategoryType':
            if (!value) {
                return 'Please select borrower category type.';
            }
            break;
        case 'detailedCategoryType':
            if (!value) {
                return 'Please select detailed category type.';
            }
            break;
        case 'subSector':
            if (!value) {
                return 'Please enter the sub sector.';
            }
            break;
        case 'detailCategoryOther':
            if (!value) {
                return `Please enter the detail category other.`;
            }
            break;
        case 'isFinancialInstitution':
            if (!value) {
                return 'Please enter the financial institution.';
            }
            break;
        case 'nbfcIfcAfcRegNumber':
            if (!value) {
                return 'Please enter the NBFC- IFC/AFC Reg.No.';
            } else if (value.trim().length < 9) {
                return 'NBFC- IFC/AFC Reg.No. must be 9 - 10 digit.'
            }
            break;
        case 'nbfcMfcRegNumber':
            if (!value) {
                return 'Please enter the NBFC- MFI Reg.No.';
            } else if (value.trim().length < 9) {
                return 'NBFC- MFI Reg.No. must be 9 - 10 digit.'
            }
            break;
        case 'nbfcOthersRegNumber':
            if (!value) {
                return 'Please enter the NBFC- Others Reg.No.';
            } else if (value.trim().length < 9) {
                return 'NBFC- Others Reg.No. must be 9 - 10 digit.'
            }
            break;
        case 'isNgo':
            if (!value) {
                return `Please enter the non government organization.`;
            }
            break;
        case 'isMicroFinanceInstitution':
            if (!value) {
                return 'Please enter the Micro finance institution.';
            }
            break;




        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const OtherchargesAndEcbDetailsValidateField = (field, value) => {

    switch (field) {
        case 'natureOfCharge':
            if (!value) {
                return 'Please enter nature of charge.';
            } else if (value.trim().length < 3) {
                return 'Enter the nature of charge minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'expectedDateOfPayment':
            if (!value) {
                return 'Please select the expected date of payment.';
            }
            break;
        case 'currency':
            if (!value) {
                return 'Please Select currency.';
            }
            break;
        case 'amount':
            if (!value) {
                return 'Please enter the amount.';
            }
            break;
        case 'numberOfPaymentsPerYear':
            if (!value) {
                return 'Please enter the number of payments per year.';
            }
            break;
        case 'totalNumberOfPayments':
            if (!value) {
                return 'Please enter the total number of payments.';
            }
            break;
        case 'unfrontFee':
            if (!value) {
                return 'Please enter the upfront fee.';
            }
            break;
        case 'managementFee':
            if (!value) {
                return 'Please enter the management fee.';
            }
            break;
        case 'commitmentFee':
            if (!value) {
                return 'Please enter the commitment fee.';
            }
            break;
        case 'guaranteeFee':
            if (!value) {
                return 'Please enter the guarantee fee.';
            }
            break;

        case 'ecbChargesFee':
            if (!value) {
                return 'Please enter the ECB charges .';
            }
            break;
            // case 'otherFee':
            //     if (!value) {
            //         return 'Please enter the other fee.';
            //     }
            break;
        case 'totalFee':
            if (!value) {
                return 'Please enter the total fee.';
            }
            break;
        case 'penalIntLatePaymentFixed':
            if (!value) {
                return 'Please enter the fixed penal interest for late payment.';
            }
            break;
        case 'penalIntLatePaymentBase':
            if (!value) {
                return 'Please enter the base penal interest for late payment.';
            }
            break;
        case 'penalIntLatePaymentMargin':
            if (!value) {
                return 'Please enter the margin penal interest for late payment.';
            }
            break;
        case 'commitmentChargesPerAnnum':
            if (!value) {
                return 'Please enter the commitment charges per annum.';
            }
            break;
        case 'commitmentChargesPerUndrawnAmount':
            if (!value) {
                return 'Please enter the commitment charges per undrawn amount.';
            }
            break;
        case 'ecbYearDetails':
            if (!value) {
                return 'Please enter the ECB year details.';
            }
            break;
        case 'ecbLoanRegNo':
            if (!value) {
                return 'Please enter the ECB loan register number.';
            } else if (value.trim().length < 9) {
                return 'Loan registration Number must be 9 - 10 digit.'
            }
            break;
        case 'ecbCurrencyDetails':
            if (!value) {
                return 'Please enter the ECB currency details';
            }
            break;
        case 'ecbPrincipalAmount':
            if (!value) {
                return 'Please enter the ECB principal amount.';
            }
            break;
        case 'ecbDisbursedSoFar':
            if (!value) {
                return 'Please enter the ECB disbursed so far.';
            }
            break;
        case 'ecbNetOutstanding':
            if (!value) {
                return 'Please enter the ECB net out standing.';
            }
            break;
        case 'place':
            if (!value) {
                return 'Please enter the place.';
            } else if (value.trim().length < 3) {
                return 'Enter the place minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'date':
            if (!value) {
                return 'Please enter the date.';
            }
            break;
            // case 'signature':
            //     if (!value) {
            //         return 'Please upload the signature of the authorised official.';
            //     }
            break;
        case 'name':
            if (!value) {
                return 'Please enter the name.';
            } else if (value.trim().length < 3) {
                return 'Enter the name minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'designation':
            if (!value) {
                return 'Please enter the designation.';
            } else if (value.trim().length < 3) {
                return 'Enter the designation minimum 3 character.'
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'phoneOrFaxNumber':
            if (!value) {
                return 'Please enter the phone / fax number.';
            } else if (value.trim().length < 10) {
                return 'Phone Number must be 10 digit.'
            }
            break;
        case 'emailId':
            if (!value) {
                return 'Please enter the email id.';
            } else if (!isValidEmail(value)) {
                return 'Please enter valid email id'
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const SummaryValidateField = (field, value) => {

    switch (field) {

        // case 'place':
        //     if (!value) {
        //         return 'Please enter the place.';
        //     }
        //     break;
        // case 'date':
        //     if (!value) {
        //         return 'Please select the edate.';
        //     }
        //     break;
        // case 'signature':
        //     if (!value) {
        //         return 'Please upload the signature of the authorised official.';
        //     }
        //     break;
        case 'name':
            if (!value) {
                return 'Please enter the name.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            } else if (value.trim().length < 3) {
                return 'Name minimum 3 character.'
            }
            break;
        case 'designation':
            if (!value) {
                return 'Please enter the designation.';
            } else if (!isCharacterValid(value)) {
                return 'Special character and numbers are not allowed.'
            }
            break;
        case 'phoneOrFaxNumber':
            if (!value) {
                return 'Please enter the phone / fax number.';
            }
            break;
        case 'registrationNumber':
            if (!value) {
                return 'Please enter the registration number.';
            }
            break;
        case 'nameOfBankandBranch':
            if (!value) {
                return 'Please enter the name Of BankandBranch.';
            }
            break;
        case 'ADCode':
            if (!value) {
                return 'Please enter the ADCode.';
            }
            break;
        case 'telNumber':
            if (!value) {
                return 'Please enter the telNumber.';
            }
            break;
        case 'faxNo':
            if (!value) {
                return 'Please enter the faxNo.';
            }
            break;
        case 'emailId':
            if (!value) {
                return 'Please enter the email id.';
            }
            break;
        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};

export const validateField = (field, value) => {
    switch (field) {
        case 'ecbApplicationType':
            if (!value) {
                return 'Please select the ECB application type.';
            }
            break;
        case 'formType':
            if (!value) {
                return 'Please select the form type.';
            }
            break;
        case 'lrn':
            if (!value) {
                return 'Please enter the loan registration number.';
            } else if (value.trim().length < 9) {
                return 'Loan registration Number must be 9 - 10 digit.'
            }
            break;
        case 'ecbUnderRoute':
            if (!value) {
                return 'Please select the ECB under route.';
            }
            break;
        case 'isRequireClearance':
            if (!value) {
                return 'Please select whether the clearance is required or not.';
            }
            break;
        case 'clearanceAuthorityName':
            if (!value) {
                return 'Please enter the clearance authority name.';
            }
            break;
        case 'clearanceNumber':
            if (!value) {
                return 'Please enter the clearance number.';
            }
            break;
        case 'clearanceDate':
            if (!value) {
                return 'Please select the clearance date.';
            }
            break;

        default:
            break;
    }
    // Add additional validation logic if needed
    return '';
};