import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from 'react-redux';
import AgreementDetails from "../component/AgreementDetails";
import BorrowerDetails from "../component/BorrowerDetails";
import LoanDetails from "../component/LoanDetails";
import LenderDetails from "../component/LenderDetails";
import { getAgreementDetailsList, getFormLoanDetailslist, getagreementDetails } from "../redux/reducer/FormListReducers";
import OtherChargesAndEcbDetails from "../component/OtherChargesAndEcbDetails";
import Summary from "../component/Summary";
import FormList from "./Formlist";
import FORM1PDF from '../component/form1pdf';
import { useReactToPrint } from 'react-to-print';
const Home = () => {
    const [isTab, setTab] = useState(1);
    const [isBack, setBack] = useState('')
    const [isFormPage, setFormPage] = useState(false)
    const isDraft = useSelector((state) => state.FORM.isDraft);
    const [agreementDetails, setAgreementDetails] = useState()
    const [editAgreementDetails, editSetAgreementDetails] = useState(false);
    const componentRef = useRef();
    const getAgreementDetails = useSelector(getagreementDetails)
    const agreementDetailsList = useSelector(getAgreementDetailsList)
    const loanList = useSelector(getFormLoanDetailslist)
    const [isloading, setLoading] = useState(false)
    const changeSideBar = () => {
        let sideBar = document.getElementsByClassName('sidebar_align')
        sideBar[0].classList.add("sidebar_align_show")
    }
    const onNext = (data) => {
        if (data === 'loanDetails') {
            return setTab(3)
        } if (data === 'lenderDetails') {
            return setTab(4)
        } if (data === 'borrowerDetails') {
            return setTab(5)
        } if (data === 'ecbDetails') {
            return setTab(6)
        } if (data === 'agreementDetails') {
            return setTab(1)
        } else {
            setTab(2);
            setAgreementDetails(data);
        }

    }

    const onBack = (data) => {
        if (data === "loanDetails") {
            setTab(2)
        }
        if (data === "lenderDetails") {
            setTab(3)
        }
        if (data === "borrowerDetails") {
            setTab(4)
        }
    }
    const onEditChange = (o) => {
        editSetAgreementDetails(o)
        setFormPage(true)
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <React.Fragment>

            {isFormPage ? <div className="form_content py-3 px-md-4 px-2">
                <div className="d-flex justify-content-evenly align-items-start">
                    <div onClick={() => changeSideBar()}><i className="bi bi-list menu_icon fa-2xl"></i></div>
                    <h3 className="heading">KDM & CO LLP Chartered Accountant</h3>
                </div>
                {/* {isTab === 6 && <div className="d-flex align-items-center justify-content-end py-4">
                    <p className="m-0">To view the instruction of the policy: </p>
                    <button className="common_btn">View</button>
                </div>} */}
                {isDraft == false &&
                    <div className="d-flex justify-content-end align-items-center mt-3">
                        <div className="ms-3">
                            <div className="d-none">
                                <FORM1PDF ref={componentRef} loanId={getAgreementDetails?.ecbApplicationType?.toString() === '2' ? getAgreementDetails?.loanId : loanList?.id ?? agreementDetailsList?.loanId} />
                            </div>
                            Download PDF :  <button className="btn next_btn" onClick={handlePrint}>Download</button> &nbsp;
                        </div>
                    </div>}
                <div className="row tab_content">
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 1 ? "active" : ""}`} onClick={() => setTab(1)}>
                        <p className={`bg_align ${isTab === 1 ? "active" : ""}`}>1</p>
                        <p className={`ms-2 ${isTab === 1 ? "activetext" : ""}`}>Agreement Details</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 2 ? "active" : ""}`} onClick={() => setTab(2)}>
                        <p className={`bg_align ${isTab === 2 ? "active" : ""}`}>2</p>
                        <p className={`ms-2 ${isTab === 2 ? "activetext" : ""}`}>Loan Details</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 3 ? "active" : ""}`} onClick={() => setTab(3)}>
                        <p className={`bg_align ${isTab === 3 ? "active" : ""}`}>3</p>
                        <p className={`ms-2 ${isTab === 3 ? "activetext" : ""}`}>Lender Details</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 4 ? "active" : ""}`} onClick={() => setTab(4)}>
                        <p className={`bg_align ${isTab === 4 ? "active" : ""}`}>4</p>
                        <p className={`ms-4 ${isTab === 4 ? "activetext" : ""}`}>Borrower Details</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 5 ? "active" : ""}`} onClick={() => setTab(5)}>
                        <p className={`bg_align ${isTab === 5 ? "active" : ""}`}>5</p>
                        <p className={`ms-2 ${isTab === 5 ? "activetext" : ""}`}> Other Charges & Details of ECB</p>
                    </div>
                    <div className={`col-md-3 col-6 py-2 d-flex tab_cursor ${isTab === 6 ? "active" : ""}`} onClick={() => setTab(6)}>
                        <p className={`bg_align ${isTab === 6 ? "active" : ""}`}>6</p>
                        <p className={`ms-2 ${isTab === 6 ? "activetext" : ""}`}>Summary</p>
                    </div>
                </div>

                {isTab === 1 &&
                    <AgreementDetails
                        onNext={onNext}
                        isBack={isBack}
                        setBack={setBack}
                        editAgreementDetails={editAgreementDetails}
                        isFormPage={isFormPage}
                    />
                }
                {isTab === 2 &&
                    <LoanDetails
                        isBack={isBack}
                        setBack={setBack}
                        onBack={(e) => onBack(e)}
                        agreementDetails={agreementDetails}
                        isTab={isTab}
                        onNext={onNext} />
                }

                {isTab === 3 &&
                    <LenderDetails
                        isBack={isBack}
                        setBack={setBack}
                        onNext={onNext} onBack={(e) => onBack(e)} />
                }
                {isTab === 4 &&
                    <BorrowerDetails
                        isBack={isBack}
                        setBack={setBack} onNext={onNext}
                        onBack={(e) => onBack(e)} />
                }
                {
                    isTab === 5 &&
                    <OtherChargesAndEcbDetails isBack={isBack}
                        setBack={setBack}
                        onNext={onNext}
                        onBack={(e) => onBack(e)} />
                }
                {
                    isTab === 6 &&
                    <Summary />
                }

            </div > : <FormList setFormPage={setFormPage} onEditForm={(o) => onEditChange(o)} />}
        </React.Fragment >
    )
}

export default Home