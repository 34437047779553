import { all, fork } from 'redux-saga/effects';
import login from './loginSaga';
import formListWatcher from './FormlistSaga';
import formSummaryWatcher from './FormSummarySaga';
import otherChargesWatcher from './OtherChargesSaga';
import getMasterTableWatcher from './MasterTableSaga';
import borrowingPurposeWatcher from './BorrowingPurposeSaga';
import repaymentWatcher from './RepaymentSaga';
import drawdownWatcher from './DrawdownSaga';
export default function* root() {
  yield all([
    fork(login),
    fork(formListWatcher),
    fork(formSummaryWatcher),
    fork(otherChargesWatcher),
    fork(getMasterTableWatcher),
    fork(drawdownWatcher),
    fork(repaymentWatcher),
    fork(borrowingPurposeWatcher),
  ]);
}