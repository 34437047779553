import { doAction, doResponseAction } from ".";


const CREATE_REPAYMENT = 'CREATE_REPAYMENT';
const CREATE_REPAYMENT_SUCCESS = 'CREATE_REPAYMENT_SUCCESS';
const CREATE_REPAYMENT_FAILURE = 'CREATE_REPAYMENT_FAILURE';

const UPDATE_REPAYMENT = 'UPDATE_REPAYMENT';
const UPDATE_REPAYMENT_SUCCESS = 'UPDATE_REPAYMENT_SUCCESS';
const UPDATE_REPAYMENT_FAILURE = 'UPDATE_REPAYMENT_FAILURE';

const DELETE_REPAYMENT = 'DELETE_REPAYMENT';
const DELETE_REPAYMENT_SUCCESS = 'DELETE_REPAYMENT_SUCCESS';
const DELETE_REPAYMENT_FAILURE = 'DELETE_REPAYMENT_FAILURE';

export {

    CREATE_REPAYMENT,
    CREATE_REPAYMENT_SUCCESS,
    CREATE_REPAYMENT_FAILURE,

    UPDATE_REPAYMENT,
    UPDATE_REPAYMENT_SUCCESS,
    UPDATE_REPAYMENT_FAILURE,

    DELETE_REPAYMENT,
    DELETE_REPAYMENT_SUCCESS,
    DELETE_REPAYMENT_FAILURE,
}


export const createRepayment = (params, callback) => doAction(CREATE_REPAYMENT, params, callback);
export const createRepaymentSuccess = (data) => doResponseAction(CREATE_REPAYMENT_SUCCESS, data);
export const createRepaymentFailure = (data) => doResponseAction(CREATE_REPAYMENT_FAILURE, data);

export const updateRepayment = (params, callback) => doAction(UPDATE_REPAYMENT, params, callback);
export const updateRepaymentSuccess = (data) => doResponseAction(UPDATE_REPAYMENT_SUCCESS, data);
export const updateRepaymentFailure = (data) => doResponseAction(UPDATE_REPAYMENT_FAILURE, data);

export const deleteRepayment = (params, callback) => doAction(DELETE_REPAYMENT, params, callback);
export const deleteRepaymentSuccess = (data) => doResponseAction(DELETE_REPAYMENT_SUCCESS, data);
export const deleteRepaymentFailure = (data) => doResponseAction(DELETE_REPAYMENT_FAILURE, data);