import loader from '../../asset/img/loader.gif'

const Loader = () => {
    return (
        <>
            <div>
                <div className="modal d-block appLoader" id="loader" aria-labelledby="loader" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content p-4">
                            <div className="modal-body text-center">
                                <h2 className="font26 fw-normal breathe">Loading</h2>
                                <img src={loader} alt="loading..." />
                            </div>

                        </div>
                    </div> 
                </div>
                <div className="modal-backdrop loader show loaderBackGround apploaderBackground"></div>
            </div>
        </>)
}

export default Loader;