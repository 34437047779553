import { takeLatest, all, put } from 'redux-saga/effects';
import { login } from '../../service';
import { Actions } from '../action/index';
import { LOGIN_USER } from '../action/loginAction';
import END_POINTS from '../../service/EndPoint';

export function* loginUser(param) {
  const { params, callback } = param

  try {
    const response = yield login(END_POINTS.LOGIN, params)
    yield put(Actions.loginUserSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback({ error })
    yield put(Actions.loginUserFailure(error))
  }
}


export default function* loginWatcher() {
  yield all([
    takeLatest(LOGIN_USER, loginUser),
  ]);
}
